/**
 * This file contains utility functions for handling invoice and member details.
 */

// Setup the invoicing flow if it exists.
export const initializeInvoicing = (): void => {
  // Remove the invoice ID from the Session Storage
  removeInvoiceId()
  removeMemberId()

  // Get the params.
  const params = new URLSearchParams(window.location.search)

  // Get and store the invoice ID from the URL.
  const invoiceId = params.get('doc_id')
  if (invoiceId) {
    // Set the invoice ID in the Session Storage
    setInvoiceId(invoiceId)
  }

  // Get and store the member ID from the URL.
  const memberId = params.get('id')
  if (memberId) {
    // Set the member ID in the Session Storage
    setMemberId(memberId)
  }
}

// Ensures input only containes alphanumeric characters.
export function isAlphaNumeric(input: string): boolean {
  return /^[a-zA-Z0-9]+$/.test(input)
}

// Get the MDVIP invoice session storage data, parse it, and provide and object.
export const getStorageObject = (): any => {
  // Get the MDVIP customer session storage object.
  const mdvip_document_data = window.sessionStorage.getItem('mdvip_verification')
  if (mdvip_document_data) {
    return JSON.parse(mdvip_document_data)
  }
  return {}
}

// Sets the MDVIP invoice session storage data, stringifying the object.
export const setStorageObject = (data: object): any => {
  // Get the MDVIP customer session storage object.
  window.sessionStorage.setItem('mdvip_verification', JSON.stringify(data))
}

/*
 * Invoice CRUD, Details From URL.
 */
// Get the invoice ID from the Session Storage.
export const getInvoiceId = (): string | null => {
  // Get the MDVIP customer session storage object.
  const mdvip_document_data = getStorageObject()

  // If we have invoice data in the object, return the invoice ID.
  return mdvip_document_data.mdvip_doc_id || null
}

// Set the invoice ID in the Session Storage.
export const setInvoiceId = (invoiceId: string): void => {
  if (isAlphaNumeric(invoiceId)) {
    // Get the MDVIP customer session storage object and store the value.
    const mdvip_document_data = getStorageObject()
    mdvip_document_data.mdvip_doc_id = invoiceId
    setStorageObject(mdvip_document_data)
  }
}

// Remove the invoice ID from the Session Storage.
export const removeInvoiceId = (): void => {
  // Get the MDVIP customer session storage object.
  const mdvip_document_data = getStorageObject()
  delete mdvip_document_data.mdvip_doc_id
  setStorageObject(mdvip_document_data)
}

// Determines whether the application should enter the invoice flow.
export const isInvoiceFlow = (): boolean => {
  return getInvoiceId() !== null
}

// Adds query parameters to a URL.
function addQueryParams(path: string, params: object) {
  // Get the location and full route.
  const location = window.location.origin
  const fullRoute = location + path

  // Create a URL object and search params.
  const urlObj = new URL(fullRoute)
  const searchParams = new URLSearchParams(urlObj.search)

  // Add the params to the search params.
  for (const [key, value] of Object.entries(params)) {
    searchParams.append(key, value)
  }

  // Set the search params to the URL object.
  urlObj.search = searchParams.toString()

  let urlString = urlObj.toString()

  // Remove fullRoute from urlString
  urlString = urlString.replace(window.location.origin, '')

  return urlString
}

// Generate a URL with the invoice ID and member ID.
export const generateRouteWithInvoice = (route: string, token = ''): string => {
  const params = {
    ['doc_id']: getInvoiceId(),
    ['id']: getMemberId(),
  }
  const newUrl = addQueryParams(route, params)
  return token === '' ? newUrl : `${newUrl}&${token}`
}

/*
 * Member CRUD, Details From URL.
 */
// Get the member ID from the Session Storage.
export const getMemberId = (): string | null => {
  // Get the MDVIP customer session storage object.
  const mdvip_document_data = getStorageObject()

  // If we have invoice data in the object, return the invoice ID.
  return mdvip_document_data.mdvip_id || null
}

// Set the member ID in the Session Storage.
export const setMemberId = (memberId: string): void => {
  if (isAlphaNumeric(memberId)) {
    // Get the MDVIP customer session storage object.
    const mdvip_document_data = getStorageObject()
    mdvip_document_data.mdvip_id = memberId
    setStorageObject(mdvip_document_data)
  }
}

// Remove the member ID from the Session Storage.
export const removeMemberId = (): void => {
  // Get the MDVIP customer session storage object.
  const mdvip_document_data = getStorageObject()
  delete mdvip_document_data.mdvip_id
  setStorageObject(mdvip_document_data)
}

// Tests whether the ID from the URL matches what is passed.
// May be used to prevent users from accessing other members' data.
export const isMemberMatch = (memberId: string): boolean => {
  return memberId === getMemberId()
}

// Tests whether the user is valid for the invoice.
export const isValidUserForInvoice = (contactId: string): boolean => {
  return contactId === getMemberId()
}
