import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AnnouncementTypeScalar: { input: any; output: any; }
  CategoryScalar: { input: any; output: any; }
  RecipeCategory: { input: any; output: any; }
  join__FieldSet: { input: any; output: any; }
  link__Import: { input: any; output: any; }
};

export type Awp = {
  __typename?: 'AWP';
  Account__c?: Maybe<Scalars['String']['output']>;
  Delete_Wellness_Exam__c?: Maybe<Scalars['Boolean']['output']>;
  Exam_Date__c?: Maybe<Scalars['String']['output']>;
  Id: Scalars['ID']['output'];
  Member_Name__c?: Maybe<Scalars['String']['output']>;
  Member_Name__r?: Maybe<AwpMember>;
  PHR_ID__c?: Maybe<Scalars['ID']['output']>;
  Status__c?: Maybe<WellnessExamStatus>;
  Upload_Date__c?: Maybe<Scalars['String']['output']>;
  Upload_Status__c?: Maybe<WellnessExamUploadStatus>;
};

export type AwpByAccountIdInput = {
  AccountID?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['ID']['input']>;
  MemberID?: InputMaybe<Scalars['String']['input']>;
  OnlyApproved?: InputMaybe<Scalars['Boolean']['input']>;
  PhysicianID?: InputMaybe<Scalars['String']['input']>;
};

export type AwpFile = {
  __typename?: 'AWPFile';
  PHR?: Maybe<Scalars['String']['output']>;
};

export type Account = {
  __typename?: 'Account';
  Actual_Website__c?: Maybe<Scalars['String']['output']>;
  Additional_Line_1__c?: Maybe<Scalars['String']['output']>;
  Additional_Line_2__c?: Maybe<Scalars['String']['output']>;
  BillingCity?: Maybe<Scalars['String']['output']>;
  BillingPostalCode?: Maybe<Scalars['String']['output']>;
  BillingState?: Maybe<Scalars['String']['output']>;
  BillingStreet?: Maybe<Scalars['String']['output']>;
  Card_Display_Name__c?: Maybe<Scalars['String']['output']>;
  Card_Phone_1__c?: Maybe<Scalars['String']['output']>;
  Card_Phone_2__c?: Maybe<Scalars['String']['output']>;
  Card_Phone_3__c?: Maybe<Scalars['String']['output']>;
  Card_Phone_4__c?: Maybe<Scalars['String']['output']>;
  Card_Phone_5__c?: Maybe<Scalars['String']['output']>;
  Card_Phone_6__c?: Maybe<Scalars['String']['output']>;
  Card_Text_1__c?: Maybe<Scalars['String']['output']>;
  Card_Text_2__c?: Maybe<Scalars['String']['output']>;
  Card_Text_3__c?: Maybe<Scalars['String']['output']>;
  Card_Text_4__c?: Maybe<Scalars['String']['output']>;
  Card_Text_5__c?: Maybe<Scalars['String']['output']>;
  Card_Text_6__c?: Maybe<Scalars['String']['output']>;
  Co_brand_Name__c?: Maybe<Scalars['String']['output']>;
  ConnectUserId?: Maybe<Scalars['String']['output']>;
  Contact_Email__c?: Maybe<Scalars['String']['output']>;
  Credentials__c?: Maybe<Scalars['String']['output']>;
  First_Name__c?: Maybe<Scalars['String']['output']>;
  Id: Scalars['ID']['output'];
  Last_Name__c?: Maybe<Scalars['String']['output']>;
  Membership_Tier__c?: Maybe<AccountMembershipTier>;
  Middle_Name__c?: Maybe<Scalars['String']['output']>;
  Mobile__c?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  PhysicianID__c?: Maybe<Scalars['String']['output']>;
  Physician_Status_Type__c?: Maybe<Scalars['String']['output']>;
  Portal_Emergency_Contact__c?: Maybe<Scalars['String']['output']>;
};

export type AccountByIdDomainError = AccountByIdNotFoundError;

/** A domain-specific failure to retrieve a member's account by Id. */
export type AccountByIdFailure = {
  __typename?: 'AccountByIdFailure';
  /** All errors associated with required Account. */
  errors: Array<AccountByIdDomainError>;
};

export type AccountByIdNotFoundError = DomainError & {
  __typename?: 'AccountByIdNotFoundError';
  /** Id that was included in the query */
  Id: Scalars['String']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type AccountByIdResult = Account | AccountByIdFailure;

export type AccountInfoType = {
  __typename?: 'AccountInfoType';
  Name?: Maybe<Scalars['String']['output']>;
  PhysicianID__c?: Maybe<Scalars['String']['output']>;
};

export type AccountInput = {
  Id?: InputMaybe<Scalars['String']['input']>;
};

export enum AccountMembershipTier {
  AwpPlus = 'AWP_PLUS',
  Pediatric = 'PEDIATRIC',
  StandardMembership = 'STANDARD_MEMBERSHIP',
  WomensHealth = 'WOMENS_HEALTH'
}

export type AccountType = {
  __typename?: 'AccountType';
  BillingCity?: Maybe<Scalars['String']['output']>;
  BillingPostalCode?: Maybe<Scalars['String']['output']>;
  BillingState?: Maybe<Scalars['String']['output']>;
  BillingStreet?: Maybe<Scalars['String']['output']>;
  Friday_End_Time__c?: Maybe<Scalars['String']['output']>;
  Friday_Start_Time__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Member_Service_State__c?: Maybe<Scalars['String']['output']>;
  Monday_End_Time__c?: Maybe<Scalars['String']['output']>;
  Monday_Start_Time__c?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Opening_Date__c?: Maybe<Scalars['String']['output']>;
  Physician_Image_URL__c?: Maybe<Scalars['String']['output']>;
  Saturday_End_Time__c?: Maybe<Scalars['String']['output']>;
  Saturday_Start_Time__c?: Maybe<Scalars['String']['output']>;
  Sunday_End_Time__c?: Maybe<Scalars['String']['output']>;
  Sunday_Start_Time__c?: Maybe<Scalars['String']['output']>;
  Thursday_End_Time__c?: Maybe<Scalars['String']['output']>;
  Thursday_Start_Time__c?: Maybe<Scalars['String']['output']>;
  Tuesday_End_Time__c?: Maybe<Scalars['String']['output']>;
  Tuesday_Start_Time__c?: Maybe<Scalars['String']['output']>;
  Wednesday_End_Time__c?: Maybe<Scalars['String']['output']>;
  Wednesday_Start_Time__c?: Maybe<Scalars['String']['output']>;
};

export type AccountsFilters = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  includeAllPhysicians?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountsInput = {
  filters?: InputMaybe<AccountsFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountsResult = {
  __typename?: 'AccountsResult';
  edges: Array<Account>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type ActivityReportInput = {
  Id?: InputMaybe<Scalars['String']['input']>;
  Range?: InputMaybe<Scalars['String']['input']>;
};

export type AddressType = {
  __typename?: 'AddressType';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AffiliateContact = {
  __typename?: 'AffiliateContact';
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  RecordTypeId?: Maybe<Scalars['String']['output']>;
};

export type AffiliateType = {
  __typename?: 'AffiliateType';
  AccountId?: Maybe<Scalars['String']['output']>;
  BillingCity?: Maybe<Scalars['String']['output']>;
  BillingLatitude?: Maybe<Scalars['Float']['output']>;
  BillingLongitude?: Maybe<Scalars['Float']['output']>;
  BillingPostalCode?: Maybe<Scalars['String']['output']>;
  BillingState?: Maybe<Scalars['String']['output']>;
  BillingStreet?: Maybe<Scalars['String']['output']>;
  Billing_Address_Description__c?: Maybe<Scalars['String']['output']>;
  ContextID__c?: Maybe<Scalars['String']['output']>;
  DepartmentId__c?: Maybe<Scalars['String']['output']>;
  Display_Name__c?: Maybe<Scalars['String']['output']>;
  Fax?: Maybe<Scalars['String']['output']>;
  First_Name__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['ID']['output']>;
  Last_Name__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  Middle_Name__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  PhysicianID__c?: Maybe<Scalars['String']['output']>;
  Specialty__c?: Maybe<Scalars['String']['output']>;
};

export type AffiliateWithContacts = {
  __typename?: 'AffiliateWithContacts';
  AccountId?: Maybe<Scalars['String']['output']>;
  BillingCity?: Maybe<Scalars['String']['output']>;
  BillingLatitude?: Maybe<Scalars['Float']['output']>;
  BillingLongitude?: Maybe<Scalars['Float']['output']>;
  BillingPostalCode?: Maybe<Scalars['String']['output']>;
  BillingState?: Maybe<Scalars['String']['output']>;
  BillingStreet?: Maybe<Scalars['String']['output']>;
  Billing_Address_Description__c?: Maybe<Scalars['String']['output']>;
  Contacts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ContextID__c?: Maybe<Scalars['String']['output']>;
  DepartmentId__c?: Maybe<Scalars['String']['output']>;
  Display_Name__c?: Maybe<Scalars['String']['output']>;
  Fax?: Maybe<Scalars['String']['output']>;
  First_Name__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['ID']['output']>;
  Last_Name__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  Middle_Name__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  PhysicianID__c?: Maybe<Scalars['String']['output']>;
  Specialty__c?: Maybe<Scalars['String']['output']>;
};

export type AllPracticeReportEdge = {
  __typename?: 'AllPracticeReportEdge';
  Created?: Maybe<Scalars['String']['output']>;
  File_Name?: Maybe<Scalars['String']['output']>;
  File_Type?: Maybe<Scalars['String']['output']>;
  Path?: Maybe<Scalars['String']['output']>;
  Physician_ID?: Maybe<Scalars['String']['output']>;
};

export type AllPracticeReportResult = {
  __typename?: 'AllPracticeReportResult';
  edges: Array<AllPracticeReportEdge>;
};

export type Announcements = {
  __typename?: 'Announcements';
  field_announcements_type?: Maybe<Scalars['AnnouncementTypeScalar']['output']>;
  field_body?: Maybe<Scalars['String']['output']>;
  field_priority?: Maybe<Scalars['String']['output']>;
  field_title?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  createdTimestamp?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AttachmentData = {
  __typename?: 'AttachmentData';
  content: Scalars['String']['output'];
};

export type AttachmentDataByIdDomainError = AttachmentDataByIdInvalidMailboxIdError | AttachmentDataByIdInvalidMessageIdError | AttachmentDataByIdInvalidThreadIdError | AttachmentDataByIdNotFoundError;

/** A domain-specific failure to retrieve a member's account by Id. */
export type AttachmentDataByIdFailure = {
  __typename?: 'AttachmentDataByIdFailure';
  /** All errors associated with required Attachment. */
  errors: Array<AttachmentDataByIdDomainError>;
};

export type AttachmentDataByIdInput = {
  attachmentId: Scalars['ID']['input'];
  mailboxId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  threadId: Scalars['ID']['input'];
};

export type AttachmentDataByIdInvalidMailboxIdError = DomainError & {
  __typename?: 'AttachmentDataByIdInvalidMailboxIdError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type AttachmentDataByIdInvalidMessageIdError = DomainError & {
  __typename?: 'AttachmentDataByIdInvalidMessageIdError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  messageId: Scalars['ID']['output'];
};

export type AttachmentDataByIdInvalidThreadIdError = DomainError & {
  __typename?: 'AttachmentDataByIdInvalidThreadIdError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type AttachmentDataByIdNotFoundError = DomainError & {
  __typename?: 'AttachmentDataByIdNotFoundError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type AttachmentDataByIdResult = AttachmentData | AttachmentDataByIdFailure;

export type AttachmentType = {
  __typename?: 'AttachmentType';
  createdTimestamp?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AttachmentTypeInput = {
  createdTimestamp?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AttachmentsByIdInput = {
  attachmentId?: InputMaybe<Scalars['String']['input']>;
  mailboxId?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  threadId?: InputMaybe<Scalars['String']['input']>;
};

export type AttachmentsByMessageIdInput = {
  attachmentId?: InputMaybe<Scalars['String']['input']>;
  mailboxId?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  threadId?: InputMaybe<Scalars['String']['input']>;
};

export type AttributeType = {
  __typename?: 'AttributeType';
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type AwpByPhrIdDomainError = AwpByPhrIdNotFoundError;

export type AwpByPhrIdFailure = {
  __typename?: 'AwpByPhrIdFailure';
  errors: Array<AwpByPhrIdDomainError>;
};

export type AwpByPhrIdNotFoundError = DomainError & {
  __typename?: 'AwpByPhrIdNotFoundError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  phrId: Scalars['ID']['output'];
};

export type AwpByPhrIdResult = AwpByPhrIdFailure | AwpByPhrIdSuccess;

export type AwpByPhrIdSuccess = {
  __typename?: 'AwpByPhrIdSuccess';
  awp: Awp;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  uploadUrl: Scalars['String']['output'];
};

export type AwpMember = {
  __typename?: 'AwpMember';
  Id: Scalars['ID']['output'];
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
};

export type CampaignMemberType = {
  __typename?: 'CampaignMemberType';
  CampaignId?: Maybe<Scalars['String']['output']>;
  ContactId?: Maybe<Scalars['String']['output']>;
  Guest_Count__c?: Maybe<Scalars['String']['output']>;
  Guest_Relationship__c?: Maybe<Scalars['String']['output']>;
  Guest__c?: Maybe<Scalars['String']['output']>;
  HasResponded?: Maybe<Scalars['Boolean']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Meeting_Attendance__c?: Maybe<Scalars['String']['output']>;
  Membership_Status__c?: Maybe<Scalars['String']['output']>;
  Mtg_Attended__c?: Maybe<Scalars['String']['output']>;
  Registration_Received__c?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
};

export type CampaignType = {
  __typename?: 'CampaignType';
  Attendees?: Maybe<Array<Maybe<CampaignMemberType>>>;
  Event_Begin_Time__c?: Maybe<Scalars['String']['output']>;
  Event_End_Time__c?: Maybe<Scalars['String']['output']>;
  Id: Scalars['String']['output'];
  Name?: Maybe<Scalars['String']['output']>;
  Name_Of_Venue__c?: Maybe<Scalars['String']['output']>;
  Registered?: Maybe<Scalars['Int']['output']>;
  Room_Capacity__c?: Maybe<Scalars['String']['output']>;
  Room_Max_Capacity__c?: Maybe<Scalars['String']['output']>;
  Venue_Address__c?: Maybe<Scalars['String']['output']>;
  formattedEndDate?: Maybe<Scalars['String']['output']>;
  formattedStartDate?: Maybe<Scalars['String']['output']>;
  formattedStartTime?: Maybe<Scalars['String']['output']>;
};

export type CaseContactType = {
  __typename?: 'CaseContactType';
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
};

export type CaseInput = {
  Id?: InputMaybe<Scalars['String']['input']>;
};

export type CaseType = {
  __typename?: 'CaseType';
  Account?: Maybe<AccountType>;
  CaseNumber?: Maybe<Scalars['String']['output']>;
  Contact?: Maybe<CaseContactType>;
  Date_Added__c?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
};

export enum Channel {
  ExpressPay = 'EXPRESS_PAY',
  MobileApp = 'MOBILE_APP',
  Portal = 'PORTAL',
  SalesforceInternal = 'SALESFORCE_INTERNAL'
}

export enum ConnectUserEnum {
  Du = 'DU',
  Ru = 'RU',
  Up = 'UP'
}

export type Contact = {
  /** AccountId from Contact record */
  AccountId: Scalars['String']['output'];
  /**
   * Date_Of_Birth__c from Contact record
   * Format: YYYY-MM-DD
   */
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  /** Disposition_Code__c from Contact record */
  Disposition_Code__c?: Maybe<Scalars['String']['output']>;
  /** Email from Contact record */
  Email?: Maybe<Scalars['String']['output']>;
  /**
   * Emergency_Phone__c from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Emergency_Phone__c?: Maybe<Scalars['String']['output']>;
  /** Estimated_Paid_Through__c from Contact record */
  Estimated_Paid_Through__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fax from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Fax?: Maybe<Scalars['String']['output']>;
  /** FirstName from Contact record */
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Gender__c from Contact record */
  Gender__c?: Maybe<Scalars['String']['output']>;
  /** Id from Contact record */
  Id: Scalars['ID']['output'];
  /** LastName from Contact record */
  LastName?: Maybe<Scalars['String']['output']>;
  /** Last_App_Login__c from Contact record */
  Last_App_Login__c?: Maybe<Scalars['String']['output']>;
  /** MailingCity from Contact record */
  MailingCity?: Maybe<Scalars['String']['output']>;
  /** MailingPostalCode from Contact record */
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  /** MailingState from Contact record */
  MailingState?: Maybe<Scalars['String']['output']>;
  /** MailingStreet from Contact record */
  MailingStreet?: Maybe<Scalars['String']['output']>;
  /**
   * MobilePhone from Contact record
   * Format: (XXX) XXX-XXXX
   */
  MobilePhone?: Maybe<Scalars['String']['output']>;
  /**
   * Phone from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Phone?: Maybe<Scalars['String']['output']>;
  /** User preference to enable push notifications for new secure messages */
  Push_Notifications__c?: Maybe<Scalars['Boolean']['output']>;
  /** User preference to enable email notifications for new secure messages */
  SM_Email_Notification__c?: Maybe<Scalars['Boolean']['output']>;
  /** Salutation from Contact record */
  Salutation?: Maybe<Scalars['String']['output']>;
  /**
   * Localized string of Date_Of_Birth__c
   * Format: MM/DD/YYYY
   */
  USLocalizedDateOfBirth?: Maybe<Scalars['String']['output']>;
};

export type ContactListMember = {
  __typename?: 'ContactListMember';
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  RecordTypeId?: Maybe<Scalars['String']['output']>;
};

export type ContactType = {
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Disposition_Code__c?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Emergency_Phone__c?: Maybe<Scalars['String']['output']>;
  Estimated_Paid_Through__c?: Maybe<Scalars['String']['output']>;
  Fax?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Gender__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['ID']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_App_Login__c?: Maybe<Scalars['String']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingCity */
  Primary_City__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingState */
  Primary_State__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingStreet */
  Primary_Street_Address__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingPostalCode */
  Primary_Zip_Postal_Code__c?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  USLocalizedDateOfBirth?: Maybe<Scalars['String']['output']>;
};

export type CreateAwpInput = {
  Date_of_Physical?: InputMaybe<Scalars['String']['input']>;
  Member_ID?: InputMaybe<Scalars['String']['input']>;
  PHR_Status?: InputMaybe<Scalars['String']['input']>;
  Physician_ID?: InputMaybe<Scalars['String']['input']>;
  User?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAwpResponse = {
  __typename?: 'CreateAWPResponse';
  PHR_ID?: Maybe<Scalars['String']['output']>;
  Wellness_Exam_ID?: Maybe<Scalars['String']['output']>;
};

export type CreateBroadcastMessageThreadFailure = {
  __typename?: 'CreateBroadcastMessageThreadFailure';
  /** All errors associated with required Attachment. */
  errors: Array<CreateMessageThreadDomainError>;
};

export type CreateBroadcastMessageThreadInput = {
  allowReply: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  mailboxId: Scalars['ID']['input'];
  messages: Array<CreateBroadcastMessageThreadInputMessage>;
  name: Scalars['String']['input'];
};

export type CreateBroadcastMessageThreadInputMessage = {
  attachments?: InputMaybe<Array<CreateMessageThreadInputMessageAttachment>>;
  bcc: Array<CreateMessageThreadInputRecipient>;
  body: Scalars['String']['input'];
  cc?: InputMaybe<Array<CreateMessageThreadInputRecipient>>;
  draft: Scalars['Boolean']['input'];
  from: CreateMessageThreadInputSender;
  id?: InputMaybe<Scalars['ID']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  subject: Scalars['String']['input'];
  to?: InputMaybe<Array<CreateMessageThreadInputRecipient>>;
};

export type CreateBroadcastMessageThreadResult = CreateBroadcastMessageThreadFailure | CreateBroadcastMessageThreadSuccess;

export type CreateBroadcastMessageThreadSuccess = {
  __typename?: 'CreateBroadcastMessageThreadSuccess';
  thread: MessageThread;
};

export type CreateCampaignMemberInput = {
  AccountID?: InputMaybe<Scalars['String']['input']>;
  AcquisitionChannel?: InputMaybe<Scalars['String']['input']>;
  AcquisitionSubChannel?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use AcquisitionSubChannel - Typo */
  AcquistionSubChannel?: InputMaybe<Scalars['String']['input']>;
  CampaignID: Scalars['String']['input'];
  ContactID: Scalars['String']['input'];
  Date_Of_Birth__c?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender__c?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LeadID?: InputMaybe<Scalars['String']['input']>;
  LeadSource?: InputMaybe<Scalars['String']['input']>;
  MDVIPCanContact?: InputMaybe<Scalars['Boolean']['input']>;
  MDVIPID?: InputMaybe<Scalars['String']['input']>;
  MailingCity?: InputMaybe<Scalars['String']['input']>;
  MailingPostalCode?: InputMaybe<Scalars['String']['input']>;
  MailingState?: InputMaybe<Scalars['String']['input']>;
  MailingStreet?: InputMaybe<Scalars['String']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Phone?: InputMaybe<Scalars['String']['input']>;
  PhysicianName?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use MailingCity */
  Primary_City__c?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use MailingState */
  Primary_State__c?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use MailingStreet */
  Primary_Street_Address__c?: InputMaybe<Scalars['String']['input']>;
  ProfileName?: InputMaybe<Scalars['String']['input']>;
  Referral_Name__c?: InputMaybe<Scalars['String']['input']>;
  Salutation?: InputMaybe<Scalars['String']['input']>;
  Unknown_Insurance_Company_Name__c?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
  UserNameNotQualified?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use MailingPostalCode */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateF2FCase = {
  AccountID?: InputMaybe<Scalars['String']['input']>;
  ContactID?: InputMaybe<Scalars['String']['input']>;
  DateofFaceofFace?: InputMaybe<Scalars['String']['input']>;
  PhysicianName?: InputMaybe<Scalars['String']['input']>;
  ProfileName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMessageInput = {
  attachments?: InputMaybe<Array<InputMaybe<CreateMessageAttachmentInput>>>;
  bcc?: InputMaybe<Array<InputMaybe<CreateMessageUserInput>>>;
  body?: InputMaybe<Scalars['String']['input']>;
  cc?: InputMaybe<Array<InputMaybe<CreateMessageUserInput>>>;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<CreateMessageUserInput>;
  mailboxId?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  threadId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Array<InputMaybe<CreateMessageUserInput>>>;
};

export type CreateMessageThreadDomainError = CreateMessageThreadMailboxInvalidIdError | CreateMessageThreadMailboxInvalidMailboxIdError | CreateMessageThreadMailboxNotFoundError;

export type CreateMessageThreadInputMessageAttachment = {
  createdTimestamp: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMessageThreadInputRecipient = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateMessageThreadInputSender = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateMessageThreadMailboxInvalidIdError = DomainError & {
  __typename?: 'CreateMessageThreadMailboxInvalidIdError';
  id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type CreateMessageThreadMailboxInvalidMailboxIdError = DomainError & {
  __typename?: 'CreateMessageThreadMailboxInvalidMailboxIdError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type CreateMessageThreadMailboxNotFoundError = DomainError & {
  __typename?: 'CreateMessageThreadMailboxNotFoundError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type CreateMessageUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  unreadCount?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateNewAwpDomainError = CreateNewAwpFailedError;

export type CreateNewAwpFailedError = DomainError & {
  __typename?: 'CreateNewAwpFailedError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type CreateNewAwpFailure = {
  __typename?: 'CreateNewAwpFailure';
  errors: Array<CreateNewAwpDomainError>;
};

export type CreateNewAwpInput = {
  /** Username who creates AWP - i.e. physician full name */
  createdByName: Scalars['String']['input'];
  /** Formatted exam date string - 08/10/2023 or 2023-08-10 */
  examDate: Scalars['String']['input'];
  /** Member MDVIP Id */
  memberMdvipId: Scalars['ID']['input'];
  /** Physician id */
  physicianId: Scalars['ID']['input'];
  /** PHR status or AWP upload status - Pending is the default value when missing */
  uploadStatus?: InputMaybe<WellnessExamUploadStatus>;
};

export type CreateNewAwpResponse = CreateNewAwpFailure | CreateNewAwpSuccess;

export type CreateNewAwpSuccess = {
  __typename?: 'CreateNewAwpSuccess';
  memberMdvipId: Scalars['ID']['output'];
  phrId: Scalars['ID']['output'];
  uploadUrl: Scalars['String']['output'];
  wellnessExamId: Scalars['ID']['output'];
};

export type CreateNewMessageDomainError = CreateNewMessageInvalidMailboxIdError | CreateNewMessageInvalidThreadIdError | CreateNewMessageMailboxThreadNotFoundError;

export type CreateNewMessageFailure = {
  __typename?: 'CreateNewMessageFailure';
  /** All errors associated with required Attachment. */
  errors: Array<CreateNewMessageDomainError>;
};

export type CreateNewMessageInput = {
  attachments: Array<CreateNewMessageInputAttachment>;
  bcc?: InputMaybe<Array<CreateNewMessageInputRecipient>>;
  body: Scalars['String']['input'];
  cc?: InputMaybe<Array<CreateNewMessageInputRecipient>>;
  draft: Scalars['Boolean']['input'];
  from: CreateNewMessageInputSender;
  mailboxId: Scalars['ID']['input'];
  read?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  threadId: Scalars['ID']['input'];
  to: Array<CreateNewMessageInputRecipient>;
};

export type CreateNewMessageInputAttachment = {
  createdTimestamp: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateNewMessageInputRecipient = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateNewMessageInputSender = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateNewMessageInvalidMailboxIdError = DomainError & {
  __typename?: 'CreateNewMessageInvalidMailboxIdError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type CreateNewMessageInvalidThreadIdError = DomainError & {
  __typename?: 'CreateNewMessageInvalidThreadIdError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type CreateNewMessageMailboxThreadNotFoundError = DomainError & {
  __typename?: 'CreateNewMessageMailboxThreadNotFoundError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type CreateNewMessageResult = CreateNewMessageFailure | CreateNewMessageSuccess;

export type CreateNewMessageSuccess = {
  __typename?: 'CreateNewMessageSuccess';
  message: Message;
};

export type CreateNewPaymentProfileAchInput = {
  ACHBankName__c: Scalars['String']['input'];
  /** Last 6 digits of Account number */
  ACHLast6__c: Scalars['String']['input'];
  /** Last 9 digits of Routing number */
  ACHRoutingLast9__c: Scalars['String']['input'];
  ACH_Name_of_Account__c: PaymentProfileAchAccountType;
  Name_on_Card__c: Scalars['String']['input'];
};

export type CreateNewPaymentProfileCreditCardInput = {
  /** Last 4 digits of Credit card number */
  CCLast4__c: Scalars['String']['input'];
  Card_Type__c: PaymentProfileCardType;
  Expiration_Month__c: Scalars['String']['input'];
  Expiration_Year__c: Scalars['String']['input'];
  Name_on_Card__c: Scalars['String']['input'];
  PaymentType__c?: InputMaybe<PaymentProfilePaymentType>;
};

export type CreateNewPaymentProfileDomainError = CreateNewPaymentProfileFailedDomainError | CreateNewPaymentProfileInvalidInputDomainError;

export type CreateNewPaymentProfileFailedDomainError = DomainError & {
  __typename?: 'CreateNewPaymentProfileFailedDomainError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type CreateNewPaymentProfileFailure = {
  __typename?: 'CreateNewPaymentProfileFailure';
  errors: Array<CreateNewPaymentProfileDomainError>;
};

export type CreateNewPaymentProfileInput = {
  /**
   * ACH information to create a new payment profile
   * Requires one of ACHInfo and CreditCardInfo
   */
  ACHInfo?: InputMaybe<CreateNewPaymentProfileAchInput>;
  Active__c: Scalars['Boolean']['input'];
  Address_1__c?: InputMaybe<Scalars['String']['input']>;
  Address_2__c?: InputMaybe<Scalars['String']['input']>;
  Affluence__c?: InputMaybe<Scalars['String']['input']>;
  Auth_Transaction_Id__c: Scalars['String']['input'];
  City__c?: InputMaybe<Scalars['String']['input']>;
  /**
   * Credit Card information to create a new payment profile
   * Requires one of ACHInfo and CreditCardInfo
   */
  CreditCardInfo?: InputMaybe<CreateNewPaymentProfileCreditCardInput>;
  Default__c?: InputMaybe<Scalars['Boolean']['input']>;
  Expired__c?: InputMaybe<Scalars['String']['input']>;
  Gateway_Token_Id__c: Scalars['String']['input'];
  HSACard__c: PaymentProfileHsaCard;
  Issuer_Country__c?: InputMaybe<Scalars['String']['input']>;
  Pre_Paid_Type__c?: InputMaybe<Scalars['String']['input']>;
  /** Limited to 500 characters */
  Source_URL__c: Scalars['String']['input'];
  States__c?: InputMaybe<Scalars['String']['input']>;
  /**
   * Limit to 100 characters
   * Use the first 100 characters of Source_URL__c if missing
   */
  Web_MD_Domain__c?: InputMaybe<Scalars['String']['input']>;
  Zipcode__c?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNewPaymentProfileInvalidInputDomainError = DomainError & {
  __typename?: 'CreateNewPaymentProfileInvalidInputDomainError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type CreateNewPaymentProfileResult = CreateNewPaymentProfileFailure | CreateNewPaymentProfileSuccess;

export type CreateNewPaymentProfileSuccess = {
  __typename?: 'CreateNewPaymentProfileSuccess';
  Id: Scalars['ID']['output'];
};

export type CreateNonBroadcastMessageThreadFailure = {
  __typename?: 'CreateNonBroadcastMessageThreadFailure';
  /** All errors associated with required Attachment. */
  errors: Array<CreateMessageThreadDomainError>;
};

export type CreateNonBroadcastMessageThreadInput = {
  allowReply: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  mailboxId: Scalars['ID']['input'];
  messages: Array<CreateNonBroadcastMessageThreadInputMessage>;
  name: Scalars['String']['input'];
};

export type CreateNonBroadcastMessageThreadInputMessage = {
  attachments?: InputMaybe<Array<CreateMessageThreadInputMessageAttachment>>;
  bcc?: InputMaybe<Array<CreateMessageThreadInputRecipient>>;
  body: Scalars['String']['input'];
  cc?: InputMaybe<Array<CreateMessageThreadInputRecipient>>;
  draft: Scalars['Boolean']['input'];
  from: CreateMessageThreadInputSender;
  id?: InputMaybe<Scalars['ID']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  subject: Scalars['String']['input'];
  to: Array<CreateMessageThreadInputRecipient>;
};

export type CreateNonBroadcastMessageThreadResult = CreateNonBroadcastMessageThreadFailure | CreateNonBroadcastMessageThreadSuccess;

export type CreateNonBroadcastMessageThreadSuccess = {
  __typename?: 'CreateNonBroadcastMessageThreadSuccess';
  thread: MessageThread;
};

export type CreateOneTimePaymentDomainError = CreateOneTimePaymentFailedDomainError;

export type CreateOneTimePaymentFailedDomainError = DomainError & {
  __typename?: 'CreateOneTimePaymentFailedDomainError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type CreateOneTimePaymentFailure = {
  __typename?: 'CreateOneTimePaymentFailure';
  errors: Array<CreateOneTimePaymentDomainError>;
};

export type CreateOneTimePaymentInput = {
  Amount__c: Scalars['Float']['input'];
  Payment_Profile__c: Scalars['String']['input'];
  /** YYYY-MM-DD formatted date */
  Schedule_Date__c: Scalars['String']['input'];
  /** Limited to 500 characters */
  Source_URL__c: Scalars['String']['input'];
  Type__c: PaymentType;
  UTM_Campaign__c?: InputMaybe<Scalars['String']['input']>;
  UTM_Medium__c?: InputMaybe<Scalars['String']['input']>;
  UTM_Source__c?: InputMaybe<Scalars['String']['input']>;
  UTM_Term__c?: InputMaybe<Scalars['String']['input']>;
  /**
   * Limit to 100 characters
   * Use the first 100 characters of Source_URL__c if missing
   */
  Web_MD_Domain__c?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOneTimePaymentResult = CreateOneTimePaymentFailure | CreateOneTimePaymentSuccess;

export type CreateOneTimePaymentSuccess = {
  __typename?: 'CreateOneTimePaymentSuccess';
  Id: Scalars['ID']['output'];
  Name: Scalars['String']['output'];
};

export type CreatePaymentInput = {
  Amount__c: Scalars['String']['input'];
  Contact__c: Scalars['String']['input'];
  Payment_Profile__c: Scalars['String']['input'];
  Schedule_Date__c: Scalars['String']['input'];
  Type__c: Scalars['String']['input'];
};

export type CreatePaymentProfileInput = {
  ACHBankName__c?: InputMaybe<Scalars['String']['input']>;
  ACHLast6__c?: InputMaybe<Scalars['String']['input']>;
  ACHRoutingLast9__c?: InputMaybe<Scalars['String']['input']>;
  ACH_Account_Type__c?: InputMaybe<Scalars['String']['input']>;
  ACH_Name_of_Account__c?: InputMaybe<Scalars['String']['input']>;
  Active__c?: InputMaybe<Scalars['Boolean']['input']>;
  Address_1__c?: InputMaybe<Scalars['String']['input']>;
  Address_2__c?: InputMaybe<Scalars['String']['input']>;
  Auth_Transaction_Id__c?: InputMaybe<Scalars['String']['input']>;
  CCLast4__c?: InputMaybe<Scalars['String']['input']>;
  Card_Type__c?: InputMaybe<Scalars['String']['input']>;
  City__c?: InputMaybe<Scalars['String']['input']>;
  Contact__c?: InputMaybe<Scalars['String']['input']>;
  Default__c?: InputMaybe<Scalars['Boolean']['input']>;
  Expiration_Month__c?: InputMaybe<Scalars['String']['input']>;
  Expiration_Year__c?: InputMaybe<Scalars['String']['input']>;
  Expired__c?: InputMaybe<Scalars['String']['input']>;
  Gateway_Token_Id__c?: InputMaybe<Scalars['String']['input']>;
  HSACard__c?: InputMaybe<Scalars['String']['input']>;
  Issuer_Country__c?: InputMaybe<Scalars['String']['input']>;
  Name_on_Card__c?: InputMaybe<Scalars['String']['input']>;
  PaymentType__c?: InputMaybe<PaymentTypeEnum>;
  States__c?: InputMaybe<Scalars['String']['input']>;
  Zipcode__c?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePhysicianPracticeStaffDomainError = CreatePhysicianPracticeStaffInvalidDataError;

/** A domain-specific failure while creating a new PhysicianPracticestaff */
export type CreatePhysicianPracticeStaffFailure = {
  __typename?: 'CreatePhysicianPracticeStaffFailure';
  /** All errors associated with failure. */
  errors: Array<CreatePhysicianPracticeStaffDomainError>;
};

export type CreatePhysicianPracticeStaffInput = {
  /** Email of the new PhysicianPracticestaff */
  Email: Scalars['String']['input'];
  /** First name of the new PhysicianPracticestaff */
  FirstName: Scalars['String']['input'];
  /** Last name of the new PhysicianPracticestaff */
  LastName: Scalars['String']['input'];
  /** MailingCity of the new PhysicianPracticestaff */
  MailingCity?: InputMaybe<Scalars['String']['input']>;
  /** MailingPostalCode of the new PhysicianPracticestaff */
  MailingPostalCode?: InputMaybe<Scalars['String']['input']>;
  /** MailingState of the new PhysicianPracticestaff */
  MailingState?: InputMaybe<Scalars['String']['input']>;
  /** MailingStreet of the new PhysicianPracticestaff */
  MailingStreet?: InputMaybe<Scalars['String']['input']>;
  /**
   * MobilePhone of the new PhysicianPracticestaff
   * Format: (XXX) XXX-XXXX
   */
  MobilePhone: Scalars['String']['input'];
  /**
   * Phone of the new PhysicianPracticestaff
   * Format: (XXX) XXX-XXXX
   */
  Phone: Scalars['String']['input'];
  /** Salutation of the new PhysicianPracticestaff */
  Salutation: Scalars['String']['input'];
  /** AccountId or ContactId for the new PhysicianPracticestaff */
  UserId: Scalars['String']['input'];
};

/** Invalid Data domain error */
export type CreatePhysicianPracticeStaffInvalidDataError = DomainError & {
  __typename?: 'CreatePhysicianPracticeStaffInvalidDataError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  /** A human-friendly error reason. */
  reason: Scalars['String']['output'];
};

export type CreatePhysicianPracticeStaffResult = CreatePhysicianPracticeStaffFailure | CreatePhysicianPracticeStaffSuccessResult;

export enum CreatePhysicianPracticeStaffSuccesStatus {
  Success = 'SUCCESS'
}

export type CreatePhysicianPracticeStaffSuccessResult = {
  __typename?: 'CreatePhysicianPracticeStaffSuccessResult';
  status: CreatePhysicianPracticeStaffSuccesStatus;
};

export type CreateProspectDomainError = CreateProspectInvalidContactIdError;

export type CreateProspectF2FCaseDomainError = CreateProspectF2FCaseInvalidDataError;

export type CreateProspectF2FCaseFailure = {
  __typename?: 'CreateProspectF2FCaseFailure';
  /** All errors associated while creating a prospect f2f case. */
  errors: Array<CreateProspectF2FCaseDomainError>;
};

export type CreateProspectF2FCaseInput = {
  /** CampaignID of the new prospect face-to-face case */
  AccountID: Scalars['String']['input'];
  /** ContactID of the new prospect face-to-face case */
  ContactID: Scalars['String']['input'];
  /** Date of face to face appointment - Should follow 'MM/DD/YYYY' format */
  DateofFaceofFace?: InputMaybe<Scalars['String']['input']>;
  /** Physician name of the new prospect face-to-face case */
  PhysicianName: Scalars['String']['input'];
  /** ProfileName of the new prospect face-to-face case */
  ProfileName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProspectF2FCaseInvalidDataError = DomainError & {
  __typename?: 'CreateProspectF2FCaseInvalidDataError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type CreateProspectF2FCaseResult = CreateProspectF2FCaseFailure | CreateProspectF2FCaseSuccess;

export type CreateProspectF2FCaseSuccess = {
  __typename?: 'CreateProspectF2FCaseSuccess';
  /** Success status of creating a new prospect f2f case */
  status?: Maybe<CreateProspectF2FCaseSuccessStatus>;
};

export enum CreateProspectF2FCaseSuccessStatus {
  /** Means the Prospect F2F case successfully created */
  Success = 'SUCCESS'
}

/** A domain-specific failure to create a prospect. */
export type CreateProspectFailure = {
  __typename?: 'CreateProspectFailure';
  /** All errors associated while creating a prospect. */
  errors: Array<CreateProspectDomainError>;
};

export type CreateProspectInput = {
  /**
   * AcquisitionChannel of the new prospect
   *
   * Default: 'Field'
   */
  AcquisitionChannel?: InputMaybe<Scalars['String']['input']>;
  /**
   * AcquisitionSubChannel of the new prospect
   *
   * Default: 'Practice'
   */
  AcquisitionSubChannel?: InputMaybe<Scalars['String']['input']>;
  /** CampaignID of the new prospect */
  CampaignID?: InputMaybe<Scalars['String']['input']>;
  /** Contact Id of the new prospect */
  ContactId: Scalars['String']['input'];
  /** DOB of the new prospect - Should be 'MM/DD/YYYY' format or it will be rejected */
  DOB?: InputMaybe<Scalars['String']['input']>;
  /** Email of the new prospect */
  Email?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fax from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Fax?: InputMaybe<Scalars['String']['input']>;
  /** FirstName of the new prospect */
  FirstName?: InputMaybe<Scalars['String']['input']>;
  /** Gender of the new prospect */
  Gender?: InputMaybe<Scalars['String']['input']>;
  /** Insurance of the new prospect */
  Insurance?: InputMaybe<Scalars['String']['input']>;
  /** LastName of the new prospect */
  LastName: Scalars['String']['input'];
  /** LeadSource of the new prospect */
  LeadSource: Scalars['String']['input'];
  /** MDVIPCanContact of the new prospect */
  MDVIPCanContact?: InputMaybe<Scalars['Boolean']['input']>;
  /** MailingCity of the new prospect */
  MailingCity?: InputMaybe<Scalars['String']['input']>;
  /** MailingPostalCode of the new prospect */
  MailingPostalCode?: InputMaybe<Scalars['String']['input']>;
  /** MailingState of the new prospect */
  MailingState?: InputMaybe<Scalars['String']['input']>;
  /** MailingStreet of the new prospect */
  MailingStreet?: InputMaybe<Scalars['String']['input']>;
  /**
   * MobilePhone of the new prospect
   * Format: (XXX) XXX-XXXX
   */
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  /**
   * Phone of the new prospect
   * Format: (XXX) XXX-XXXX
   */
  Phone?: InputMaybe<Scalars['String']['input']>;
  /** ProfileName of the new prospect */
  ProfileName: Scalars['String']['input'];
  /** ReferralName of the new prospect */
  ReferralName?: InputMaybe<Scalars['String']['input']>;
  /** Salutation of the new prospect */
  Salutation?: InputMaybe<Scalars['String']['input']>;
  /** UserName of the new prospect */
  UserName: Scalars['String']['input'];
};

export type CreateProspectInvalidContactIdError = DomainError & {
  __typename?: 'CreateProspectInvalidContactIdError';
  /** ContactId field that was in the input */
  ContactId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type CreateProspectPayload = {
  __typename?: 'CreateProspectPayload';
  LeadId: Scalars['String']['output'];
};

export type CreateProspectResult = CreateProspectFailure | CreateProspectPayload;

export type CreateReciprocityVisitInput = {
  Account__c?: InputMaybe<Scalars['String']['input']>;
  DOB_Provided__c?: InputMaybe<Scalars['String']['input']>;
  MDVIPID_Provided__c?: InputMaybe<Scalars['String']['input']>;
  Patient_Email_ID_Provided__c?: InputMaybe<Scalars['String']['input']>;
  Patient_First_Name__c?: InputMaybe<Scalars['String']['input']>;
  Patient_Home_Phone_Provided__c?: InputMaybe<Scalars['String']['input']>;
  Patient_Last_Name__c?: InputMaybe<Scalars['String']['input']>;
  Patient_Mobile_Phone_Provided__c?: InputMaybe<Scalars['String']['input']>;
  Reason_for_Visit__c?: InputMaybe<Scalars['String']['input']>;
  Referring_Physician__c?: InputMaybe<Scalars['String']['input']>;
  Visit_Date__c?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReferralType = {
  __typename?: 'CreateReferralType';
  ReferralId?: Maybe<Scalars['String']['output']>;
  SpecialistReferralId?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CreateThreadInput = {
  allowReply?: InputMaybe<Scalars['Boolean']['input']>;
  broadcast?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mailBoxId?: InputMaybe<Scalars['String']['input']>;
  messages?: InputMaybe<Array<InputMaybe<MessageThreadInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DeactivatePaymentProfileByIdDomainError = DeactivatePaymentProfileByIdNotFoundDomainError;

export type DeactivatePaymentProfileByIdFailure = {
  __typename?: 'DeactivatePaymentProfileByIdFailure';
  errors: Array<DeactivatePaymentProfileByIdDomainError>;
};

export type DeactivatePaymentProfileByIdNotFoundDomainError = {
  __typename?: 'DeactivatePaymentProfileByIdNotFoundDomainError';
  Id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type DeactivatePaymentProfileByIdResult = DeactivatePaymentProfileByIdFailure | PaymentProfile;

export type DefaultPaymentProfileDomainError = DefaultPaymentProfileNotFoundDomainError;

export type DefaultPaymentProfileFailure = {
  __typename?: 'DefaultPaymentProfileFailure';
  errors: Array<DefaultPaymentProfileDomainError>;
};

export type DefaultPaymentProfileNotFoundDomainError = DomainError & {
  __typename?: 'DefaultPaymentProfileNotFoundDomainError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type DefaultPaymentProfileResult = DefaultPaymentProfileFailure | PaymentProfile;

export type DeleteMessageByIdDomainError = DeleteMessageByIdInvalidIdError | DeleteMessageByIdInvalidMailboxIdError | DeleteMessageByIdInvalidThreadIdError | DeleteMessageByIdNotFoundError;

export type DeleteMessageByIdFailure = {
  __typename?: 'DeleteMessageByIdFailure';
  /** All errors associated with required Attachment. */
  errors: Array<DeleteMessageByIdDomainError>;
};

export type DeleteMessageByIdInput = {
  id: Scalars['ID']['input'];
  mailboxId: Scalars['ID']['input'];
  threadId: Scalars['ID']['input'];
};

export type DeleteMessageByIdInvalidIdError = DomainError & {
  __typename?: 'DeleteMessageByIdInvalidIdError';
  id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type DeleteMessageByIdInvalidMailboxIdError = DomainError & {
  __typename?: 'DeleteMessageByIdInvalidMailboxIdError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type DeleteMessageByIdInvalidThreadIdError = DomainError & {
  __typename?: 'DeleteMessageByIdInvalidThreadIdError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type DeleteMessageByIdNotFoundError = DomainError & {
  __typename?: 'DeleteMessageByIdNotFoundError';
  id: Scalars['ID']['output'];
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type DeleteMessageByIdResult = DeleteMessageByIdFailure | DeleteMessageByIdSuccess;

export type DeleteMessageByIdSuccess = {
  __typename?: 'DeleteMessageByIdSuccess';
  id: Scalars['ID']['output'];
  mailboxId: Scalars['ID']['output'];
  threadId: Scalars['ID']['output'];
};

export type DeleteMessageInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  mailboxId?: InputMaybe<Scalars['String']['input']>;
  threadId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteMessageThreadByIdDomainError = DeleteMessageThreadByIdInvalidIdError | DeleteMessageThreadByIdInvalidMailboxIdError | DeleteMessageThreadByIdNotFoundError;

export type DeleteMessageThreadByIdFailure = {
  __typename?: 'DeleteMessageThreadByIdFailure';
  /** All errors associated with required Attachment. */
  errors: Array<DeleteMessageThreadByIdDomainError>;
};

export type DeleteMessageThreadByIdInput = {
  id: Scalars['ID']['input'];
  mailboxId: Scalars['ID']['input'];
};

export type DeleteMessageThreadByIdInvalidIdError = DomainError & {
  __typename?: 'DeleteMessageThreadByIdInvalidIdError';
  id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type DeleteMessageThreadByIdInvalidMailboxIdError = DomainError & {
  __typename?: 'DeleteMessageThreadByIdInvalidMailboxIdError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type DeleteMessageThreadByIdNotFoundError = DomainError & {
  __typename?: 'DeleteMessageThreadByIdNotFoundError';
  id: Scalars['ID']['output'];
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type DeleteMessageThreadByIdResult = DeleteMessageThreadByIdFailure | DeleteMessageThreadByIdSuccess;

export type DeleteMessageThreadByIdSuccess = {
  __typename?: 'DeleteMessageThreadByIdSuccess';
  id: Scalars['ID']['output'];
  mailboxId: Scalars['ID']['output'];
};

/**
 * A domain-specific failure to retrieve a physician by AccountId.
 * This type of error represents failures of data or business logic,
 * but not of system or network failures.
 */
export type DomainError = {
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type DynamicEmailType = {
  __typename?: 'DynamicEmailType';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type EmailInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailBody?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  senderAddress?: InputMaybe<Scalars['String']['input']>;
};

export type EmailResponseError = {
  __typename?: 'EmailResponseError';
  message?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
};

export type EmailResponseType = {
  __typename?: 'EmailResponseType';
  actionName?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<EmailResponseError>>>;
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  outputValues?: Maybe<Scalars['String']['output']>;
};

export type EmailType = {
  __typename?: 'EmailType';
  emailAddressesArray?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  emailBody?: Maybe<Scalars['String']['output']>;
  emailSubject?: Maybe<Scalars['String']['output']>;
  senderAddress?: Maybe<Scalars['String']['output']>;
  senderType?: Maybe<Scalars['String']['output']>;
};

export type EnableMemberAutopayByContactIdDomainError = EnableMemberAutopayByContactIdInvalidContactIdError;

export type EnableMemberAutopayByContactIdFailure = {
  __typename?: 'EnableMemberAutopayByContactIdFailure';
  /** All errors associated with required Member By Id. */
  errors: Array<EnableMemberAutopayByContactIdDomainError>;
};

export type EnableMemberAutopayByContactIdInvalidContactIdError = DomainError & {
  __typename?: 'EnableMemberAutopayByContactIdInvalidContactIdError';
  /** ContactId that was included in the query */
  ContactId: Scalars['String']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type EnableMemberAutopayByContactIdResult = EnableMemberAutopayByContactIdFailure | Member;

/**
 * This enum is the extended version of the PaymentProfilePaymentType enum
 * The reason why we introduced this is because we only use `Check` for listing purpose.
 */
export enum ExtendedPaymentProfilePaymentType {
  Ach = 'ACH',
  Amex = 'AMEX',
  Check = 'Check',
  Disc = 'DISC',
  Mc = 'MC',
  Visa = 'VISA'
}

export type F2FCaseType = {
  __typename?: 'F2FCaseType';
  AccountId?: Maybe<Scalars['String']['output']>;
  ContactId?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  Date_of_Face_to_Face__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  LastModifiedDate?: Maybe<Scalars['String']['output']>;
  Meeting_Time__c?: Maybe<Scalars['String']['output']>;
  Result__c?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
};

export type FaqType = {
  __typename?: 'FaqType';
  field_answer?: Maybe<Scalars['String']['output']>;
  field_faq_category?: Maybe<Scalars['CategoryScalar']['output']>;
  field_question?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  AWPAudit__c?: Maybe<Scalars['Boolean']['output']>;
  AWPCreate__c?: Maybe<Scalars['Boolean']['output']>;
  AWPManage__c?: Maybe<Scalars['Boolean']['output']>;
  AWPPlus__c?: Maybe<Scalars['Boolean']['output']>;
  AWPRead__c?: Maybe<Scalars['Boolean']['output']>;
  AcceptingJuniorMembers__c?: Maybe<Scalars['Boolean']['output']>;
  AnalyticsReport__c?: Maybe<Scalars['Boolean']['output']>;
  ApparelandMerchandise__c?: Maybe<Scalars['Boolean']['output']>;
  CollateralandStationary__c?: Maybe<Scalars['Boolean']['output']>;
  Contact__c: Scalars['ID']['output'];
  EventsandMarketingMaterials__c?: Maybe<Scalars['Boolean']['output']>;
  FinancialReports__c?: Maybe<Scalars['Boolean']['output']>;
  Foundation__c?: Maybe<Scalars['Boolean']['output']>;
  Id: Scalars['ID']['output'];
  MCEReferrals__c?: Maybe<Scalars['Boolean']['output']>;
  MDVIPWebmail__c?: Maybe<Scalars['Boolean']['output']>;
  ManageCHL__c?: Maybe<Scalars['Boolean']['output']>;
  ManageGroupPractice__c?: Maybe<Scalars['Boolean']['output']>;
  ManageOfficeHours__c?: Maybe<Scalars['Boolean']['output']>;
  ManagePayments__c?: Maybe<Scalars['Boolean']['output']>;
  ManagePortalUser__c?: Maybe<Scalars['Boolean']['output']>;
  MedicalResources__c?: Maybe<Scalars['Boolean']['output']>;
  MemberSatisfaction__c?: Maybe<Scalars['Boolean']['output']>;
  MyEvents__c?: Maybe<Scalars['Boolean']['output']>;
  MyInitiatives__c?: Maybe<Scalars['Boolean']['output']>;
  MyWaitinglist__c?: Maybe<Scalars['Boolean']['output']>;
  Notifications__c?: Maybe<Scalars['Boolean']['output']>;
  OfficeDepotOfficeMax__c?: Maybe<Scalars['Boolean']['output']>;
  Patients__c?: Maybe<Scalars['Boolean']['output']>;
  Programs__c?: Maybe<Scalars['Boolean']['output']>;
  Prospects__c?: Maybe<Scalars['Boolean']['output']>;
  ReciprocityVisit__c?: Maybe<Scalars['Boolean']['output']>;
  SecureMessaging__c?: Maybe<Scalars['Boolean']['output']>;
  Staplesdotcom__c?: Maybe<Scalars['Boolean']['output']>;
  ToDos__c?: Maybe<Scalars['Boolean']['output']>;
  User__c: Scalars['ID']['output'];
  ValueBasedCare__c?: Maybe<Scalars['Boolean']['output']>;
  WomensHealth__c?: Maybe<Scalars['Boolean']['output']>;
};

export type FeatureFlagByConnectUserIdDomainError = FeatureFlagByConnectUserIdNotFoundError;

export type FeatureFlagByConnectUserIdFailure = {
  __typename?: 'FeatureFlagByConnectUserIdFailure';
  errors: Array<FeatureFlagByConnectUserIdDomainError>;
};

export type FeatureFlagByConnectUserIdNotFoundError = DomainError & {
  __typename?: 'FeatureFlagByConnectUserIdNotFoundError';
  connectUserId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type FeatureFlagByConnectUserIdResult = FeatureFlag | FeatureFlagByConnectUserIdFailure;

export type FeatureFlagByContactIdDomainError = FeatureFlagByContactIdMissingInputError | FeatureFlagByContactIdNotFoundError;

export type FeatureFlagByContactIdFailure = {
  __typename?: 'FeatureFlagByContactIdFailure';
  errors: Array<FeatureFlagByContactIdDomainError>;
};

export type FeatureFlagByContactIdMissingInputError = DomainError & {
  __typename?: 'FeatureFlagByContactIdMissingInputError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type FeatureFlagByContactIdNotFoundError = DomainError & {
  __typename?: 'FeatureFlagByContactIdNotFoundError';
  contactId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type FeatureFlagByContactIdResult = FeatureFlag | FeatureFlagByContactIdFailure;

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  AWPAudit__c?: Maybe<Scalars['Boolean']['output']>;
  AWPCreate__c?: Maybe<Scalars['Boolean']['output']>;
  AWPManage__c?: Maybe<Scalars['Boolean']['output']>;
  AWPPlus__c?: Maybe<Scalars['Boolean']['output']>;
  AWPRead__c?: Maybe<Scalars['Boolean']['output']>;
  AcceptingJuniorMembers__c?: Maybe<Scalars['Boolean']['output']>;
  AnalyticsReport__c?: Maybe<Scalars['Boolean']['output']>;
  ApparelandMerchandise__c?: Maybe<Scalars['Boolean']['output']>;
  CollateralandStationary__c?: Maybe<Scalars['Boolean']['output']>;
  EventsandMarketingMaterials__c?: Maybe<Scalars['Boolean']['output']>;
  FinancialReports__c?: Maybe<Scalars['Boolean']['output']>;
  Foundation__c?: Maybe<Scalars['Boolean']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  MCEReferrals__c?: Maybe<Scalars['Boolean']['output']>;
  MDVIPWebmail__c?: Maybe<Scalars['Boolean']['output']>;
  ManageCHL__c?: Maybe<Scalars['Boolean']['output']>;
  ManageGroupPractice__c?: Maybe<Scalars['Boolean']['output']>;
  ManageOfficeHours__c?: Maybe<Scalars['Boolean']['output']>;
  ManagePayments__c?: Maybe<Scalars['Boolean']['output']>;
  ManagePortalUser__c?: Maybe<Scalars['Boolean']['output']>;
  MedicalResources__c?: Maybe<Scalars['Boolean']['output']>;
  MemberSatisfaction__c?: Maybe<Scalars['Boolean']['output']>;
  MyEvents__c?: Maybe<Scalars['Boolean']['output']>;
  MyInitiatives__c?: Maybe<Scalars['Boolean']['output']>;
  MyWaitinglist__c?: Maybe<Scalars['Boolean']['output']>;
  Notifications__c?: Maybe<Scalars['Boolean']['output']>;
  OfficeDepotOfficeMax__c?: Maybe<Scalars['Boolean']['output']>;
  Patients__c?: Maybe<Scalars['Boolean']['output']>;
  Programs__c?: Maybe<Scalars['Boolean']['output']>;
  Prospects__c?: Maybe<Scalars['Boolean']['output']>;
  ReciprocityVisit__c?: Maybe<Scalars['Boolean']['output']>;
  SecureMessaging__c?: Maybe<Scalars['Boolean']['output']>;
  Staplesdotcom__c?: Maybe<Scalars['Boolean']['output']>;
  ToDos__c?: Maybe<Scalars['Boolean']['output']>;
  WomensHealth__c?: Maybe<Scalars['Boolean']['output']>;
};

export type FeatureFlagsInput = {
  AWPAudit__c?: InputMaybe<Scalars['Boolean']['input']>;
  AWPCreate__c?: InputMaybe<Scalars['Boolean']['input']>;
  AWPManage__c?: InputMaybe<Scalars['Boolean']['input']>;
  AWPPlus__c?: InputMaybe<Scalars['Boolean']['input']>;
  AWPRead__c?: InputMaybe<Scalars['Boolean']['input']>;
  AcceptingJuniorMembers__c?: InputMaybe<Scalars['Boolean']['input']>;
  AnalyticsReport__c?: InputMaybe<Scalars['Boolean']['input']>;
  ApparelandMerchandise__c?: InputMaybe<Scalars['Boolean']['input']>;
  CollateralandStationary__c?: InputMaybe<Scalars['Boolean']['input']>;
  EventsandMarketingMaterials__c?: InputMaybe<Scalars['Boolean']['input']>;
  FinancialReports__c?: InputMaybe<Scalars['Boolean']['input']>;
  Foundation__c?: InputMaybe<Scalars['Boolean']['input']>;
  MCEReferrals__c?: InputMaybe<Scalars['Boolean']['input']>;
  MDVIPWebmail__c?: InputMaybe<Scalars['Boolean']['input']>;
  ManageCHL__c?: InputMaybe<Scalars['Boolean']['input']>;
  ManageGroupPractice__c?: InputMaybe<Scalars['Boolean']['input']>;
  ManageOfficeHours__c?: InputMaybe<Scalars['Boolean']['input']>;
  ManagePayments__c?: InputMaybe<Scalars['Boolean']['input']>;
  ManagePortalUser__c?: InputMaybe<Scalars['Boolean']['input']>;
  MedicalResources__c?: InputMaybe<Scalars['Boolean']['input']>;
  MemberSatisfaction__c?: InputMaybe<Scalars['Boolean']['input']>;
  MyEvents__c?: InputMaybe<Scalars['Boolean']['input']>;
  MyInitiatives__c?: InputMaybe<Scalars['Boolean']['input']>;
  MyWaitinglist__c?: InputMaybe<Scalars['Boolean']['input']>;
  Notifications__c?: InputMaybe<Scalars['Boolean']['input']>;
  OfficeDepotOfficeMax__c?: InputMaybe<Scalars['Boolean']['input']>;
  Patients__c?: InputMaybe<Scalars['Boolean']['input']>;
  Programs__c?: InputMaybe<Scalars['Boolean']['input']>;
  Prospects__c?: InputMaybe<Scalars['Boolean']['input']>;
  ReciprocityVisit__c?: InputMaybe<Scalars['Boolean']['input']>;
  SecureMessaging__c?: InputMaybe<Scalars['Boolean']['input']>;
  Staplesdotcom__c?: InputMaybe<Scalars['Boolean']['input']>;
  ToDos__c?: InputMaybe<Scalars['Boolean']['input']>;
  WomensHealth__c?: InputMaybe<Scalars['Boolean']['input']>;
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
};

export type FoundationInput = {
  Amount?: InputMaybe<Scalars['String']['input']>;
  Frequency?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  PhysicianId?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
};

export type FoundationType = {
  __typename?: 'FoundationType';
  Amount?: Maybe<Scalars['String']['output']>;
  EndDate?: Maybe<Scalars['String']['output']>;
  Frequency?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  PhyRollOutId?: Maybe<Scalars['String']['output']>;
  PhysicianId?: Maybe<Scalars['String']['output']>;
  StartDate?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
};

export type GetAllThreadsInput = {
  mailboxId: Scalars['ID']['input'];
  maxResults?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  toMailboxId?: InputMaybe<Scalars['String']['input']>;
};

export type InitiativesInput = {
  CampaignID?: InputMaybe<Scalars['String']['input']>;
  ContactID?: InputMaybe<Scalars['String']['input']>;
  Engagement__c?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
};

export type InitiativesType = {
  __typename?: 'InitiativesType';
  CampaignMembers?: Maybe<Array<Maybe<CampaignMemberType>>>;
  Campaign__c?: Maybe<Scalars['String']['output']>;
  End_Date__c?: Maybe<Scalars['String']['output']>;
  Engagement__c?: Maybe<Scalars['String']['output']>;
  Enrollees?: Maybe<Scalars['Int']['output']>;
  Id: Scalars['String']['output'];
  Launch_Date__c?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Target_Audience__c?: Maybe<Scalars['String']['output']>;
};

export type Insurance = {
  __typename?: 'Insurance';
  Name?: Maybe<Scalars['String']['output']>;
};

export type InsuranceType = {
  __typename?: 'InsuranceType';
  Name?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<AttributeType>;
};

export type LitleErrorByCode = {
  __typename?: 'LitleErrorByCode';
  MemberPortal__c?: Maybe<Scalars['String']['output']>;
  ResponseCode__c: Scalars['ID']['output'];
};

export type LitleErrorByCodeDomainError = LitleErrorByCodeNotFoundDomainError;

export type LitleErrorByCodeFailure = {
  __typename?: 'LitleErrorByCodeFailure';
  errors: Array<LitleErrorByCodeDomainError>;
};

export type LitleErrorByCodeNotFoundDomainError = {
  __typename?: 'LitleErrorByCodeNotFoundDomainError';
  code: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type LitleErrorByCodeResult = LitleErrorByCode | LitleErrorByCodeFailure;

export type LitleInterfaceDetailsType = {
  __typename?: 'LitleInterfaceDetailsType';
  LitleEnvelopeClose__c?: Maybe<Scalars['String']['output']>;
  LitleEnvelopeOpen__c?: Maybe<Scalars['String']['output']>;
  LitlePassword__c?: Maybe<Scalars['String']['output']>;
  LitlePayPageURL__c?: Maybe<Scalars['String']['output']>;
  LitlePayPage__c?: Maybe<Scalars['String']['output']>;
  LitleURL__c?: Maybe<Scalars['String']['output']>;
  LitleUserName__c?: Maybe<Scalars['String']['output']>;
  MerchantTxnId__c?: Maybe<Scalars['Int']['output']>;
  PayPageId__c?: Maybe<Scalars['String']['output']>;
  ReportGroup__c?: Maybe<Scalars['String']['output']>;
  RoutingNumber__c?: Maybe<Scalars['String']['output']>;
  ValidateFSA__c?: Maybe<Scalars['Boolean']['output']>;
  eProtect_Endpoint__c?: Maybe<Scalars['String']['output']>;
  eProtect_Password__c?: Maybe<Scalars['String']['output']>;
  eProtect_Username__c?: Maybe<Scalars['String']['output']>;
};

export type LitleSettings = {
  __typename?: 'LitleSettings';
  LitlePassword__c: Scalars['String']['output'];
  LitleURL__c: Scalars['String']['output'];
  LitleUserName__c: Scalars['String']['output'];
  MerchantTxnId__c: Scalars['Int']['output'];
  PayPageId__c: Scalars['String']['output'];
  ReportGroup__c: Scalars['String']['output'];
  RoutingNumber__c: Scalars['String']['output'];
  eProtect_Endpoint__c: Scalars['String']['output'];
  eProtect_Password__c: Scalars['String']['output'];
  eProtect_Username__c: Scalars['String']['output'];
};

export type LitleSettingsDomainError = LitleSettingsNotFoundDomainError;

export type LitleSettingsFailure = {
  __typename?: 'LitleSettingsFailure';
  errors: Array<LitleSettingsDomainError>;
};

export type LitleSettingsNotFoundDomainError = {
  __typename?: 'LitleSettingsNotFoundDomainError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type LitleSettingsResult = LitleSettings | LitleSettingsFailure;

export type McReferralInput = {
  AlternatePhone?: InputMaybe<Scalars['String']['input']>;
  ConfirmationOfReleaseOnFile?: InputMaybe<Scalars['Boolean']['input']>;
  ContactSFID?: InputMaybe<Scalars['String']['input']>;
  Diagnosis?: InputMaybe<Scalars['String']['input']>;
  DoNotSendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  EmployerAddress?: InputMaybe<Scalars['String']['input']>;
  EmployerCity?: InputMaybe<Scalars['String']['input']>;
  EmployerName?: InputMaybe<Scalars['String']['input']>;
  EmployerState?: InputMaybe<Scalars['String']['input']>;
  EmployerZip?: InputMaybe<Scalars['String']['input']>;
  Facility?: InputMaybe<Scalars['String']['input']>;
  GroupNumber?: InputMaybe<Scalars['String']['input']>;
  HealthInsurerName?: InputMaybe<Scalars['String']['input']>;
  InsuranceType?: InputMaybe<Scalars['String']['input']>;
  MDVIPApprovedToSpeakTo?: InputMaybe<Scalars['String']['input']>;
  MaritalStatus?: InputMaybe<Scalars['String']['input']>;
  Notes?: InputMaybe<Scalars['String']['input']>;
  Occupation?: InputMaybe<Scalars['String']['input']>;
  PhysicianSFID?: InputMaybe<Scalars['String']['input']>;
  PolicyHolder?: InputMaybe<Scalars['String']['input']>;
  PolicyHolderDOB?: InputMaybe<Scalars['String']['input']>;
  PolicyHolderName?: InputMaybe<Scalars['String']['input']>;
  PolicyHolderOccupation?: InputMaybe<Scalars['String']['input']>;
  PolicyNumber?: InputMaybe<Scalars['String']['input']>;
  PreferredLanguage?: InputMaybe<Scalars['String']['input']>;
  PrimaryPhone?: InputMaybe<Scalars['String']['input']>;
  Race?: InputMaybe<Scalars['String']['input']>;
  RelationshipToPolicyHolder?: InputMaybe<Scalars['String']['input']>;
  SSN?: InputMaybe<Scalars['String']['input']>;
  SecondaryGroupNumber?: InputMaybe<Scalars['String']['input']>;
  SecondaryHealthInsurerName?: InputMaybe<Scalars['String']['input']>;
  SecondaryInsuranceType?: InputMaybe<Scalars['String']['input']>;
  SecondaryPolicyHolder?: InputMaybe<Scalars['String']['input']>;
  SecondaryPolicyHolderDOB?: InputMaybe<Scalars['String']['input']>;
  SecondaryPolicyHolderName?: InputMaybe<Scalars['String']['input']>;
  SecondaryPolicyHolderOccupation?: InputMaybe<Scalars['String']['input']>;
  SecondaryPolicyNumber?: InputMaybe<Scalars['String']['input']>;
  SecondaryRelationshipToPolicyHolder?: InputMaybe<Scalars['String']['input']>;
  SpecialRequest?: InputMaybe<Scalars['String']['input']>;
  Specialty?: InputMaybe<Scalars['String']['input']>;
  Spouse?: InputMaybe<Scalars['String']['input']>;
  WorkEmailAddress?: InputMaybe<Scalars['String']['input']>;
};

export type MdvipUserProfile = {
  __typename?: 'MDVIPUserProfile';
  AccountId?: Maybe<Scalars['String']['output']>;
  Actual_Website__c?: Maybe<Scalars['String']['output']>;
  ContactId?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Id: Scalars['ID']['output'];
  /**
   * Lab_User_ID__c requires the front-end to verify if FeatureFlags.ManageCHL__c == true, then only return Lab_User_ID__c.
   * The fact that data exists does not grant access as the data is retained when a user account access is removed.
   */
  Lab_User_ID__c?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  /**
   * MFMailboxID__c requires the front-end to verify if FeatureFlags.SecureMessaging == true, then only return MFMailboxID__c.
   * The fact that data exists does not grant access as the data is retained when a user account access is removed.
   */
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  PhysicianID__c?: Maybe<Scalars['String']['output']>;
  Physician_Image_URL__c?: Maybe<Scalars['String']['output']>;
  ProfileName: Scalars['String']['output'];
  UserNameNotQualified__c?: Maybe<Scalars['String']['output']>;
  Username: Scalars['String']['output'];
};

export type MdvipUserProfileDomainError = MdvipUserProfileNotFoundError;

/** A domain-specific failure to retrieve a member's account by Id. */
export type MdvipUserProfileFailure = {
  __typename?: 'MDVIPUserProfileFailure';
  /** All errors associated with required Account. */
  errors: Array<MdvipUserProfileDomainError>;
};

export type MdvipUserProfileInput = {
  /** The Id value the same as connect_user_id */
  Id?: InputMaybe<Scalars['ID']['input']>;
};

export type MdvipUserProfileNotFoundError = DomainError & {
  __typename?: 'MDVIPUserProfileNotFoundError';
  /** Id that was included in the query */
  Id: Scalars['String']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type MdvipUserProfileResult = MdvipUserProfile | MdvipUserProfileFailure;

export type Mailbox = {
  __typename?: 'Mailbox';
  awayMessage?: Maybe<Scalars['String']['output']>;
  awayStatus?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  unreadMessageCount: Scalars['Int']['output'];
  unreadThreadCount: Scalars['Int']['output'];
};

export type MailboxByIdDomainError = MailboxByIdForbiddenError | MailboxByIdInvalidMailboxIdError | MailboxByIdNotFoundError | MailboxByIdUnauthorizedError | MailboxByIdValidateMailboxInternalError;

export type MailboxByIdFailure = {
  __typename?: 'MailboxByIdFailure';
  /** All errors associated with required Attachment. */
  errors: Array<MailboxByIdDomainError>;
};

export type MailboxByIdForbiddenError = DomainError & {
  __typename?: 'MailboxByIdForbiddenError';
  id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type MailboxByIdInput = {
  id: Scalars['ID']['input'];
};

export type MailboxByIdInvalidMailboxIdError = DomainError & {
  __typename?: 'MailboxByIdInvalidMailboxIdError';
  id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type MailboxByIdNotFoundError = DomainError & {
  __typename?: 'MailboxByIdNotFoundError';
  id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type MailboxByIdResult = Mailbox | MailboxByIdFailure;

export type MailboxByIdUnauthorizedError = DomainError & {
  __typename?: 'MailboxByIdUnauthorizedError';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type MailboxByIdValidateMailboxInternalError = DomainError & {
  __typename?: 'MailboxByIdValidateMailboxInternalError';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type MailboxType = {
  __typename?: 'MailboxType';
  awayMessage?: Maybe<Scalars['String']['output']>;
  awayStatus?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  unreadMessageCount?: Maybe<Scalars['Int']['output']>;
  unreadThreadCount?: Maybe<Scalars['Int']['output']>;
};

export type ManageF2FCaseInput = {
  CaseID?: InputMaybe<Scalars['String']['input']>;
  DateofFaceofFace?: InputMaybe<Scalars['String']['input']>;
  MeetingTime?: InputMaybe<Scalars['String']['input']>;
  ProfileName?: InputMaybe<Scalars['String']['input']>;
  Result?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<ManageProspectCaseEnum>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

export enum ManageProspectCaseEnum {
  AppointmentCancelled = 'AppointmentCancelled',
  AppointmentCompleted = 'AppointmentCompleted',
  AppointmentRefused = 'AppointmentRefused',
  AppointmentScheduled = 'AppointmentScheduled'
}

export type MaximumPaymentAmountDomainError = MaximumPaymentAmountNotFoundDomainError;

export type MaximumPaymentAmountFailure = {
  __typename?: 'MaximumPaymentAmountFailure';
  errors: Array<MaximumPaymentAmountDomainError>;
};

export type MaximumPaymentAmountNotFoundDomainError = {
  __typename?: 'MaximumPaymentAmountNotFoundDomainError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type MaximumPaymentAmountResult = MaximumPaymentAmountFailure | MaximumPaymentAmountSettings;

export type MaximumPaymentAmountSettings = {
  __typename?: 'MaximumPaymentAmountSettings';
  Amount__c?: Maybe<Scalars['Float']['output']>;
  Description__c?: Maybe<Scalars['String']['output']>;
  Id: Scalars['ID']['output'];
  Label?: Maybe<Scalars['String']['output']>;
};

export type MaximumPaymentAmountType = {
  __typename?: 'MaximumPaymentAmountType';
  Amount__c?: Maybe<Scalars['Float']['output']>;
  Description__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Label?: Maybe<Scalars['String']['output']>;
};

export type Member = Contact & {
  __typename?: 'Member';
  Account?: Maybe<Account>;
  /** AccountId from Contact record */
  AccountId: Scalars['String']['output'];
  AmountMSRP__c?: Maybe<Scalars['Int']['output']>;
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Best_Method_Of_Communication__c?: Maybe<Scalars['String']['output']>;
  Bill_to_National_Account__c?: Maybe<Scalars['String']['output']>;
  Billing_Days_Past_Due__c?: Maybe<Scalars['Int']['output']>;
  Cancel_Date__c?: Maybe<Scalars['String']['output']>;
  CancellationStatistics__c?: Maybe<Scalars['Boolean']['output']>;
  /** This field will be only available for Physician and Physician Practice Staff */
  Cancellation_Probability__c?: Maybe<Scalars['Float']['output']>;
  Current_Agreement_Effective_Date__c?: Maybe<Scalars['String']['output']>;
  Current_Agreement_MSRP__c?: Maybe<Scalars['Float']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Current_Balance_Formula__c?: Maybe<Scalars['String']['output']>;
  Current_Balance__c?: Maybe<Scalars['Float']['output']>;
  Current_Membership_Type__c?: Maybe<Scalars['String']['output']>;
  /**
   * Date_Of_Birth__c from Contact record
   * Format: YYYY-MM-DD
   */
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Date_of_Oldest_Unpaid_Invoice__c?: Maybe<Scalars['String']['output']>;
  /** Disposition_Code__c from Contact record */
  Disposition_Code__c?: Maybe<Scalars['String']['output']>;
  Do_Not_Sync_EHR__c?: Maybe<Scalars['Boolean']['output']>;
  EID__c?: Maybe<Scalars['String']['output']>;
  /** Email from Contact record */
  Email?: Maybe<Scalars['String']['output']>;
  /**
   * Emergency_Phone__c from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Emergency_Phone__c?: Maybe<Scalars['String']['output']>;
  Employer__c?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  /** Estimated_Paid_Through__c from Contact record */
  Estimated_Paid_Through__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fax from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Fax?: Maybe<Scalars['String']['output']>;
  /** FirstName from Contact record */
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Gender__c from Contact record */
  Gender__c?: Maybe<Scalars['String']['output']>;
  /** Id from Contact record */
  Id: Scalars['ID']['output'];
  Insurance__r?: Maybe<Insurance>;
  Junior_Membership__c?: Maybe<Scalars['Boolean']['output']>;
  /** LastName from Contact record */
  LastName?: Maybe<Scalars['String']['output']>;
  /** Last_App_Login__c from Contact record */
  Last_App_Login__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount_Text__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  LeadSource?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  /** MailingCity from Contact record */
  MailingCity?: Maybe<Scalars['String']['output']>;
  /** MailingPostalCode from Contact record */
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  /** MailingState from Contact record */
  MailingState?: Maybe<Scalars['String']['output']>;
  /** MailingStreet from Contact record */
  MailingStreet?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  /**
   * MobilePhone from Contact record
   * Format: (XXX) XXX-XXXX
   */
  MobilePhone?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccount>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Next_Renewal_Date__c?: Maybe<Scalars['String']['output']>;
  OtherCity?: Maybe<Scalars['String']['output']>;
  OtherPostalCode?: Maybe<Scalars['String']['output']>;
  OtherState?: Maybe<Scalars['String']['output']>;
  OtherStreet?: Maybe<Scalars['String']['output']>;
  /** This field will be only available for Physician and Physician Practice Staff */
  Outreach_Status?: Maybe<Scalars['String']['output']>;
  /**
   * Phone from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Phone?: Maybe<Scalars['String']['output']>;
  /** This field will be only available for Physician and Physician Practice Staff */
  Promoter__c?: Maybe<Scalars['String']['output']>;
  /** User preference to enable push notifications for new secure messages */
  Push_Notifications__c?: Maybe<Scalars['Boolean']['output']>;
  Referral_Name__c?: Maybe<Scalars['String']['output']>;
  /** User preference to enable email notifications for new secure messages */
  SM_Email_Notification__c?: Maybe<Scalars['Boolean']['output']>;
  /** Salutation from Contact record */
  Salutation?: Maybe<Scalars['String']['output']>;
  Secondary_Insurance__c?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  Title?: Maybe<Scalars['String']['output']>;
  /**
   * Localized string of Date_Of_Birth__c
   * Format: MM/DD/YYYY
   */
  USLocalizedDateOfBirth?: Maybe<Scalars['String']['output']>;
  Unknown_Insurance_Company_Name__c?: Maybe<Scalars['String']['output']>;
  Work_Ext__c?: Maybe<Scalars['String']['output']>;
  Work_Phone__c?: Maybe<Scalars['String']['output']>;
  estimatedNextPaymentAmount?: Maybe<Scalars['Float']['output']>;
};

export type MemberAccountType = {
  __typename?: 'MemberAccountType';
  Credentials__c?: Maybe<Scalars['String']['output']>;
  Emergency_Phone__c?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Portal_Emergency_Contact__c?: Maybe<Scalars['String']['output']>;
  Sync_EHR__c?: Maybe<Scalars['String']['output']>;
};

export type MemberActivityType = {
  __typename?: 'MemberActivityType';
  CreatedByProfile__c?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  WhoId?: Maybe<Scalars['String']['output']>;
};

export type MemberByContactIdDomainError = MemberByContactIdNotFoundError;

export type MemberByContactIdFailure = {
  __typename?: 'MemberByContactIdFailure';
  /** All errors associated with required Member By Id. */
  errors: Array<MemberByContactIdDomainError>;
};

export type MemberByContactIdNotFoundError = DomainError & {
  __typename?: 'MemberByContactIdNotFoundError';
  /** ContactId that was included in the query */
  ContactId: Scalars['String']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type MemberByContactIdResult = Member | MemberByContactIdFailure;

export type MemberInput = {
  AmountMSRP__c?: InputMaybe<Scalars['Int']['input']>;
  AutoPay__c?: InputMaybe<Scalars['Boolean']['input']>;
  Best_Method_Of_Communication__c?: InputMaybe<Scalars['String']['input']>;
  CancellationStatistics__c?: InputMaybe<Scalars['Boolean']['input']>;
  Connect_User_Status__c?: InputMaybe<Scalars['Boolean']['input']>;
  Current_Agreement_Payment_Schedule__c?: InputMaybe<Scalars['String']['input']>;
  Current_Balance_Formula__c?: InputMaybe<Scalars['String']['input']>;
  Current_Balance__c?: InputMaybe<Scalars['Float']['input']>;
  Current_Membership_Type__c?: InputMaybe<Scalars['String']['input']>;
  Date_Of_Birth__c?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Emergency_Phone__c?: InputMaybe<Scalars['String']['input']>;
  Employer__c?: InputMaybe<Scalars['String']['input']>;
  Established_Date__c?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender__c?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  Last_App_Login__c?: InputMaybe<Scalars['String']['input']>;
  Last_Payment_Date__c?: InputMaybe<Scalars['String']['input']>;
  Last_Wellness_Exam__c?: InputMaybe<Scalars['String']['input']>;
  LeadSource?: InputMaybe<Scalars['String']['input']>;
  MDVIPID__c?: InputMaybe<Scalars['String']['input']>;
  MDVIP_Connect_User__c?: InputMaybe<Scalars['Boolean']['input']>;
  MailingCity?: InputMaybe<Scalars['String']['input']>;
  MailingPostalCode?: InputMaybe<Scalars['String']['input']>;
  MailingState?: InputMaybe<Scalars['String']['input']>;
  MailingStreet?: InputMaybe<Scalars['String']['input']>;
  Member_Status__c?: InputMaybe<Scalars['String']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  National_Account__r?: InputMaybe<NationalAccountInput>;
  Next_Renewal_Date_Formula__c?: InputMaybe<Scalars['String']['input']>;
  OtherCity?: InputMaybe<Scalars['String']['input']>;
  OtherPostalCode?: InputMaybe<Scalars['String']['input']>;
  OtherState?: InputMaybe<Scalars['String']['input']>;
  OtherStreet?: InputMaybe<Scalars['String']['input']>;
  Phone?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use MailingCity */
  Primary_City__c?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use MailingState */
  Primary_State__c?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use MailingStreet */
  Primary_Street_Address__c?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use MailingPostalCode */
  Primary_Zip_Postal_Code__c?: InputMaybe<Scalars['String']['input']>;
  Referral_Name__c?: InputMaybe<Scalars['String']['input']>;
  Salutation?: InputMaybe<Scalars['String']['input']>;
  Secondary_Insurance__c?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Unknown_Insurance_Company_Name__c?: InputMaybe<Scalars['String']['input']>;
  Work_Ext__c?: InputMaybe<Scalars['String']['input']>;
  Work_Phone__c?: InputMaybe<Scalars['String']['input']>;
};

export type MemberToDoNotification = {
  __typename?: 'MemberToDoNotification';
  key: Scalars['ID']['output'];
  metadata: ToDoNotificationMetadata;
};

export type MemberToDoNotificationsInput = {
  ContactId: Scalars['String']['input'];
};

export type MemberToDoNotificationsResult = {
  __typename?: 'MemberToDoNotificationsResult';
  edges: Array<MemberToDoNotification>;
};

export type MemberType = {
  __typename?: 'MemberType';
  Account?: Maybe<MemberAccountType>;
  AccountId?: Maybe<Scalars['String']['output']>;
  AmountMSRP__c?: Maybe<Scalars['Int']['output']>;
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Best_Method_Of_Communication__c?: Maybe<Scalars['String']['output']>;
  Bill_to_National_Account__c?: Maybe<Scalars['String']['output']>;
  Billing_Days_Past_Due__c?: Maybe<Scalars['Int']['output']>;
  Cancel_Date__c?: Maybe<Scalars['String']['output']>;
  CancellationStatistics__c?: Maybe<Scalars['Boolean']['output']>;
  Cancellation_Probability__c?: Maybe<Scalars['String']['output']>;
  Current_Agreement_Effective_Date__c?: Maybe<Scalars['String']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Current_Balance_Formula__c?: Maybe<Scalars['String']['output']>;
  Current_Balance__c?: Maybe<Scalars['Float']['output']>;
  Current_Membership_Type__c?: Maybe<Scalars['String']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Date_of_Oldest_Unpaid_Invoice__c?: Maybe<Scalars['String']['output']>;
  Disposition_Code__c?: Maybe<Scalars['String']['output']>;
  Do_Not_Sync_EHR__c?: Maybe<Scalars['Boolean']['output']>;
  EID__c?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Emergency_Phone__c?: Maybe<Scalars['String']['output']>;
  Employer__c?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  Estimated_Paid_Through__c?: Maybe<Scalars['String']['output']>;
  Fax?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Gender__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['ID']['output']>;
  Insurance__r?: Maybe<InsuranceType>;
  Junior_Membership__c?: Maybe<Scalars['Boolean']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_App_Login__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount_Text__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  LeadSource?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccountType>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Next_Renewal_Date__c?: Maybe<Scalars['String']['output']>;
  OtherCity?: Maybe<Scalars['String']['output']>;
  OtherPostalCode?: Maybe<Scalars['String']['output']>;
  OtherState?: Maybe<Scalars['String']['output']>;
  OtherStreet?: Maybe<Scalars['String']['output']>;
  Outreach_Status?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingCity */
  Primary_City__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingState */
  Primary_State__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingStreet */
  Primary_Street_Address__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingPostalCode */
  Primary_Zip_Postal_Code__c?: Maybe<Scalars['String']['output']>;
  Promoter__c?: Maybe<Scalars['String']['output']>;
  Referral_Name__c?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Secondary_Insurance__c?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  Title?: Maybe<Scalars['String']['output']>;
  USLocalizedDateOfBirth?: Maybe<Scalars['String']['output']>;
  Unknown_Insurance_Company_Name__c?: Maybe<Scalars['String']['output']>;
  Work_Ext__c?: Maybe<Scalars['String']['output']>;
  Work_Phone__c?: Maybe<Scalars['String']['output']>;
  campaignIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  campaigns?: Maybe<Array<Maybe<CampaignType>>>;
  physicianIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  physicians?: Maybe<Array<Maybe<PhysicianType>>>;
};

export type MembersByAccountIdInput = {
  Statuses?: InputMaybe<Array<Scalars['String']['input']>>;
  filterStr?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MembersByAccountIdResult = {
  __typename?: 'MembersByAccountIdResult';
  edges: Array<Member>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type MembersByPhysicianIdResult = {
  __typename?: 'MembersByPhysicianIdResult';
  edges: Array<Member>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type Message = {
  __typename?: 'Message';
  attachments?: Maybe<Array<Attachment>>;
  bcc?: Maybe<Array<MessageUser>>;
  body?: Maybe<Scalars['String']['output']>;
  cc?: Maybe<Array<MessageUser>>;
  createdTimestamp: Scalars['String']['output'];
  draft?: Maybe<Scalars['Boolean']['output']>;
  from: MessageUser;
  id: Scalars['ID']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  threadId?: Maybe<Scalars['String']['output']>;
  to: Array<MessageUser>;
};

export type MessageThread = {
  __typename?: 'MessageThread';
  allowReply?: Maybe<Scalars['Boolean']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  broadcast?: Maybe<Scalars['Boolean']['output']>;
  hasAttachment?: Maybe<Scalars['Boolean']['output']>;
  hasDraft?: Maybe<Scalars['Boolean']['output']>;
  hasUnread?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  messageCount: Scalars['Int']['output'];
  messages: Array<Message>;
  name: Scalars['String']['output'];
  participants?: Maybe<Array<Scalars['String']['output']>>;
  starred?: Maybe<Scalars['Boolean']['output']>;
  updatedTimestamp?: Maybe<Scalars['String']['output']>;
};

export type MessageThreadByIdDomainError = MessageThreadByIdInvalidMailboxIdError | MessageThreadByIdInvalidThreadIdError | MessageThreadByIdNotFoundError;

export type MessageThreadByIdFailure = {
  __typename?: 'MessageThreadByIdFailure';
  /** All errors associated with required Attachment. */
  errors: Array<MessageThreadByIdDomainError>;
};

export type MessageThreadByIdInput = {
  mailboxId: Scalars['ID']['input'];
  threadId: Scalars['ID']['input'];
};

export type MessageThreadByIdInvalidMailboxIdError = DomainError & {
  __typename?: 'MessageThreadByIdInvalidMailboxIdError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type MessageThreadByIdInvalidThreadIdError = DomainError & {
  __typename?: 'MessageThreadByIdInvalidThreadIdError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type MessageThreadByIdNotFoundError = DomainError & {
  __typename?: 'MessageThreadByIdNotFoundError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type MessageThreadByIdResult = MessageThread | MessageThreadByIdFailure;

export type MessageThreadInput = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentTypeInput>>>;
  bcc?: InputMaybe<Array<InputMaybe<MessageThreadInputFromAndTo>>>;
  body?: InputMaybe<Scalars['String']['input']>;
  cc?: InputMaybe<Array<InputMaybe<MessageThreadInputFromAndTo>>>;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<MessageThreadInputFromAndTo>;
  id?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  threadId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Array<InputMaybe<MessageThreadInputFromAndTo>>>;
};

export type MessageThreadInputFromAndTo = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum MessageThreadState {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Starred = 'STARRED'
}

export type MessageThreadsByMailboxIdDomainError = MessageThreadsByMailboxIdForbiddenError | MessageThreadsByMailboxIdInternalError | MessageThreadsByMailboxIdInvalidMailboxIdError | MessageThreadsByMailboxIdNotFoundError | MessageThreadsByMailboxIdUnauthorizedError;

export type MessageThreadsByMailboxIdFailure = {
  __typename?: 'MessageThreadsByMailboxIdFailure';
  /** All errors associated with required Attachment. */
  errors: Array<MessageThreadsByMailboxIdDomainError>;
};

export type MessageThreadsByMailboxIdFilters = {
  state?: InputMaybe<MessageThreadState>;
  toMailboxId?: InputMaybe<Scalars['String']['input']>;
};

export type MessageThreadsByMailboxIdForbiddenError = DomainError & {
  __typename?: 'MessageThreadsByMailboxIdForbiddenError';
  mailboxId: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type MessageThreadsByMailboxIdInput = {
  filters?: InputMaybe<MessageThreadsByMailboxIdFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mailboxId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MessageThreadsByMailboxIdInternalError = DomainError & {
  __typename?: 'MessageThreadsByMailboxIdInternalError';
  mailboxId: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type MessageThreadsByMailboxIdInvalidMailboxIdError = DomainError & {
  __typename?: 'MessageThreadsByMailboxIdInvalidMailboxIdError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type MessageThreadsByMailboxIdNotFoundError = DomainError & {
  __typename?: 'MessageThreadsByMailboxIdNotFoundError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type MessageThreadsByMailboxIdResult = MessageThreadsByMailboxIdFailure | MessageThreadsByMailboxIdSuccess;

export type MessageThreadsByMailboxIdSuccess = {
  __typename?: 'MessageThreadsByMailboxIdSuccess';
  edges: Array<MessageThread>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  total: Scalars['Int']['output'];
};

export type MessageThreadsByMailboxIdUnauthorizedError = DomainError & {
  __typename?: 'MessageThreadsByMailboxIdUnauthorizedError';
  mailboxId: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type MessageType = {
  __typename?: 'MessageType';
  attachments?: Maybe<Array<Maybe<AttachmentType>>>;
  bcc?: Maybe<Array<Maybe<MessageUserType>>>;
  body?: Maybe<Scalars['String']['output']>;
  cc?: Maybe<Array<Maybe<MessageUserType>>>;
  createdTimestamp?: Maybe<Scalars['String']['output']>;
  draft?: Maybe<Scalars['Boolean']['output']>;
  from?: Maybe<MessageUserType>;
  id: Scalars['String']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  threadId?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Array<Maybe<MessageUserType>>>;
};

export type MessageUser = {
  __typename?: 'MessageUser';
  authId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mailboxIds?: Maybe<Array<Scalars['String']['output']>>;
  mailboxes?: Maybe<Array<Mailbox>>;
  name: Scalars['String']['output'];
  unreadCount?: Maybe<Scalars['Int']['output']>;
};

export type MessageUserType = {
  __typename?: 'MessageUserType';
  authId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mailboxIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  mailboxes?: Maybe<Array<Maybe<MailboxType>>>;
  name?: Maybe<Scalars['String']['output']>;
  unreadCount?: Maybe<Scalars['Int']['output']>;
};

export type MessagesByMailboxThreadDomainError = MessagesByMailboxThreadInvalidMailboxIdError | MessagesByMailboxThreadInvalidThreadIdError | MessagesByMailboxThreadNotFoundError;

export type MessagesByMailboxThreadFailure = {
  __typename?: 'MessagesByMailboxThreadFailure';
  /** All errors associated with required Attachment. */
  errors: Array<MessagesByMailboxThreadDomainError>;
};

export type MessagesByMailboxThreadInput = {
  mailboxId: Scalars['ID']['input'];
  threadId: Scalars['ID']['input'];
};

export type MessagesByMailboxThreadInvalidMailboxIdError = DomainError & {
  __typename?: 'MessagesByMailboxThreadInvalidMailboxIdError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type MessagesByMailboxThreadInvalidThreadIdError = DomainError & {
  __typename?: 'MessagesByMailboxThreadInvalidThreadIdError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type MessagesByMailboxThreadNotFoundError = DomainError & {
  __typename?: 'MessagesByMailboxThreadNotFoundError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type MessagesByMailboxThreadResult = MessagesByMailboxThreadFailure | MessagesByMailboxThreadSuccess;

export type MessagesByMailboxThreadSuccess = {
  __typename?: 'MessagesByMailboxThreadSuccess';
  edges: Array<Message>;
};

export type MessagesInput = {
  mailboxId: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
};

export type MessagingContact = {
  __typename?: 'MessagingContact';
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Id: Scalars['String']['output'];
  LastName?: Maybe<Scalars['String']['output']>;
  MDVIPID?: Maybe<Scalars['String']['output']>;
  /** @deprecated use MDVIPID */
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated use MailboxID */
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  MailboxID?: Maybe<Scalars['String']['output']>;
  RecordType?: Maybe<Scalars['String']['output']>;
  /** @deprecated use RecordType */
  RecordTypeId?: Maybe<Scalars['String']['output']>;
};

/** TODO: Deprecating soon */
export enum MessagingContactForType {
  Affiliate = 'AFFILIATE',
  Member = 'MEMBER',
  OfficeStaff = 'OFFICE_STAFF'
}

/** TODO: Deprecating soon */
export type MessagingContactsByContactIdResult = {
  __typename?: 'MessagingContactsByContactIdResult';
  edges: Array<MessagingContact>;
};

export type MessagingContactsForAffiliateInput = {
  AccountId: Scalars['String']['input'];
};

export type MessagingContactsForEmployeeInput = {
  AccountId: Scalars['String']['input'];
  ContactId: Scalars['String']['input'];
};

export type MessagingContactsForMemberInput = {
  ContactId: Scalars['String']['input'];
};

export type MessagingContactsForStaffInput = {
  AccountId: Scalars['String']['input'];
  ContactId: Scalars['String']['input'];
};

export type MessagingContactsListResult = {
  __typename?: 'MessagingContactsListResult';
  edges: Array<MessagingContact>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addNewGuest?: Maybe<CampaignMemberType>;
  case?: Maybe<CaseType>;
  /** @deprecated use createNewAwp */
  createAWP?: Maybe<CreateAwpResponse>;
  createBroadcastMessageThread: CreateBroadcastMessageThreadResult;
  /** @deprecated use createProspectF2FCase */
  createF2FCase?: Maybe<StateResponse>;
  /** @deprecated use createNewMessage */
  createMessage?: Maybe<MessageType>;
  /**
   * Feature flags needed for this mutation:
   * - AWP Create = Upload AWP readonly
   * - AWP Audit = Approve AWP
   * - AWP Manage = Edit AWP
   * - AWP Read = Read AWP
   */
  createNewAwp: CreateNewAwpResponse;
  /** Creates a new message in the mailbox thread */
  createNewMessage: CreateNewMessageResult;
  /**
   * Creates a new Payment Profile
   * Usage - Express Pay
   */
  createNewPaymentProfile: CreateNewPaymentProfileResult;
  /**
   * Create a new prospect
   * TODO: Rename this to "createProspect" after legacy "createProspect" is removed.
   * TODO: Verify @authorization directive with consumers
   */
  createNewProspect: CreateProspectResult;
  createNonBroadcastMessageThread: CreateNonBroadcastMessageThreadResult;
  /**
   * Creates a new One-time Payment
   * TODO: rename to createPayment once the old one is deprecated.
   */
  createOneTimePayment: CreateOneTimePaymentResult;
  /** @deprecated use createOneTimePayment */
  createPayment?: Maybe<Payment_Old>;
  /** @deprecated use createNewPaymentProfile */
  createPaymentProfile?: Maybe<PaymentProfile_Old>;
  /** Create a new PhysicianPracticestaff */
  createPhysicianPracticeStaff: CreatePhysicianPracticeStaffResult;
  /** @deprecated use createNewProspect */
  createProspect?: Maybe<ProspectType>;
  /** Create a new prospect face-to-face case */
  createProspectF2FCase: CreateProspectF2FCaseResult;
  createReciprocityVisit?: Maybe<ReciprocityVisitType>;
  createReferral?: Maybe<CreateReferralType>;
  /**
   * Id for createStaff and updateConnectStatus can be AccountId or ContactId
   * @deprecated use createPhysicianPracticeStaff
   */
  createStaff?: Maybe<Scalars['String']['output']>;
  /** @deprecated use createMessageThread */
  createThread?: Maybe<ThreadType>;
  /** @deprecated use deactivatePaymentProfileById */
  deactivatePaymentProfile?: Maybe<PaymentProfile_Old>;
  deactivatePaymentProfileById: DeactivatePaymentProfileByIdResult;
  /** @deprecated use deleteMessageById */
  deleteMessage?: Maybe<StateResponse>;
  deleteMessageById: DeleteMessageByIdResult;
  deleteMessageThreadById: DeleteMessageThreadByIdResult;
  /** @deprecated use deleteMessageThreadById */
  deleteThread?: Maybe<StateResponse>;
  /** @deprecated use enableMemberAutopayByContactId */
  enableAutopay?: Maybe<MemberType>;
  /** Enable members autopay */
  enableMemberAutopayByContactId: EnableMemberAutopayByContactIdResult;
  enroll?: Maybe<InitiativesType>;
  insertDonation?: Maybe<Scalars['String']['output']>;
  /** @deprecated use updateProspectF2FCaseByCaseId */
  manageF2FCase?: Maybe<StateResponse>;
  /** @deprecated use updateMemberByContactId */
  member?: Maybe<MemberType>;
  root?: Maybe<Scalars['String']['output']>;
  sendDynamicEmail?: Maybe<DynamicEmailType>;
  sendEmail?: Maybe<EmailResponseType>;
  sendPaymentReceiptEmailById: SendPaymentReceiptEmailByIdResult;
  setDefaultPaymentProfile: SetDefaultPaymentProfileResult;
  /** @deprecated use setupMyMailbox */
  setMailboxId?: Maybe<MemberType>;
  setupMyMailbox: SetupMyMailboxResult;
  /** Transfer Prospect to MDVIP */
  transferProspectToMDVIP: TransferProspectToMdvipResult;
  /** @deprecated use transferProspectToMDVIP */
  transferToMDVIP?: Maybe<StateResponse>;
  /** @deprecated use updateAwpByPhrId */
  updateAWP?: Maybe<UpdateAwpResponse>;
  updateAccount?: Maybe<StateResponse>;
  /**
   * Feature flags needed for this mutation:
   * - AWP Create = Upload AWP readonly
   * - AWP Audit = Approve AWP
   * - AWP Manage = Edit AWP
   * - AWP Read = Read AWP
   */
  updateAwpByPhrId: UpdateAwpByPhrIdResponse;
  updateCampaignMember?: Maybe<CampaignMemberType>;
  /** @deprecated use updatePhysicianPracticeStaffAccessByContactId */
  updateConnectUser?: Maybe<Scalars['String']['output']>;
  /** @deprecated use setDefaultPaymentProfile */
  updateDefaultPaymentProfile?: Maybe<Array<Maybe<PaymentProfile_Old>>>;
  updateDonation?: Maybe<Scalars['String']['output']>;
  updateFeatureFlagById: UpdateFeatureFlagByIdResult;
  /** @deprecated use updateFeatureFlagById */
  updateFlags?: Maybe<Scalars['String']['output']>;
  /** @deprecated use updateMailboxById */
  updateMailbox: MailboxType;
  updateMailboxById: UpdateMailboxByIdResult;
  /** Update existing member details by ContactId */
  updateMemberByContactId: UpdateMemberByContactIdResult;
  /** @deprecated use updateMessageById */
  updateMessage?: Maybe<StateResponse>;
  updateMessageById: UpdateMessageByIdResult;
  updateMessageThreadById: UpdateMessageThreadByIdResult;
  /** @deprecated use updateUpcomingPaymentById */
  updatePayment?: Maybe<Payment_Old>;
  /** @deprecated use updatePaymentProfileById */
  updatePaymentProfile?: Maybe<PaymentProfile_Old>;
  updatePaymentProfileById: UpdatePaymentProfileByIdResult;
  /** @deprecated Use 'updatePhysicianByContactId' */
  updatePhysician?: Maybe<PhysicianType>;
  /** Updates physician's demographic information */
  updatePhysicianByContactId: UpdatePhysicianByContactIdResult;
  /** Updates PhysicianPracticestaff's access to portal */
  updatePhysicianPracticeStaffAccessByContactId: UpdatePhysicianPracticeStaffAccessByContactIdResult;
  /** Updates PhysicianPracticestaff contact details */
  updatePhysicianPracticeStaffByContactId: UpdatePhysicianPracticeStaffByContactIdResult;
  /** @deprecated use updateProspectByContactId */
  updateProspect?: Maybe<ProspectType>;
  /** Update existing prospect by ContactId */
  updateProspectByContactId: UpdateProspectByContactIdResult;
  /** Update existing prospect face-to-face case by CaseId */
  updateProspectF2FCaseByCaseId: UpdateProspectF2FCaseByCaseIdResult;
  /** @deprecated use updatePhysicianPracticeStaffByContactId */
  updateStaff?: Maybe<StaffType>;
  /** @deprecated use updateMessageThreadById */
  updateThread?: Maybe<StateResponse>;
  updateUpcomingPaymentById: UpdateUpcomingPaymentByIdResult;
  /** Verifies ACH information */
  verifyACHInformation: VerifyAchInformationResult;
  /** Verifies Credit Card Information */
  verifyCreditCardInformation: VerifyCreditCardInformationResult;
};


export type MutationAddNewGuestArgs = {
  input?: InputMaybe<CreateCampaignMemberInput>;
};


export type MutationCaseArgs = {
  input?: InputMaybe<CaseInput>;
};


export type MutationCreateAwpArgs = {
  input: CreateAwpInput;
};


export type MutationCreateBroadcastMessageThreadArgs = {
  input: CreateBroadcastMessageThreadInput;
};


export type MutationCreateF2FCaseArgs = {
  input?: InputMaybe<CreateF2FCase>;
};


export type MutationCreateMessageArgs = {
  input?: InputMaybe<CreateMessageInput>;
};


export type MutationCreateNewAwpArgs = {
  input: CreateNewAwpInput;
};


export type MutationCreateNewMessageArgs = {
  input: CreateNewMessageInput;
};


export type MutationCreateNewPaymentProfileArgs = {
  input: CreateNewPaymentProfileInput;
};


export type MutationCreateNewProspectArgs = {
  input: CreateProspectInput;
};


export type MutationCreateNonBroadcastMessageThreadArgs = {
  input: CreateNonBroadcastMessageThreadInput;
};


export type MutationCreateOneTimePaymentArgs = {
  input: CreateOneTimePaymentInput;
};


export type MutationCreatePaymentArgs = {
  input?: InputMaybe<CreatePaymentInput>;
};


export type MutationCreatePaymentProfileArgs = {
  input?: InputMaybe<CreatePaymentProfileInput>;
};


export type MutationCreatePhysicianPracticeStaffArgs = {
  input: CreatePhysicianPracticeStaffInput;
};


export type MutationCreateProspectArgs = {
  input?: InputMaybe<ProspectInput>;
};


export type MutationCreateProspectF2FCaseArgs = {
  input: CreateProspectF2FCaseInput;
};


export type MutationCreateReciprocityVisitArgs = {
  data?: InputMaybe<CreateReciprocityVisitInput>;
};


export type MutationCreateReferralArgs = {
  input?: InputMaybe<SubmitReferralInput>;
};


export type MutationCreateStaffArgs = {
  Id: Scalars['ID']['input'];
  data: StaffDataTypeInput;
};


export type MutationCreateThreadArgs = {
  input?: InputMaybe<CreateThreadInput>;
};


export type MutationDeactivatePaymentProfileArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeactivatePaymentProfileByIdArgs = {
  Id: Scalars['ID']['input'];
};


export type MutationDeleteMessageArgs = {
  input?: InputMaybe<DeleteMessageInput>;
};


export type MutationDeleteMessageByIdArgs = {
  input: DeleteMessageByIdInput;
};


export type MutationDeleteMessageThreadByIdArgs = {
  input: DeleteMessageThreadByIdInput;
};


export type MutationDeleteThreadArgs = {
  mailboxId: Scalars['ID']['input'];
  threadId: Scalars['ID']['input'];
};


export type MutationEnableAutopayArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEnableMemberAutopayByContactIdArgs = {
  ContactId: Scalars['String']['input'];
};


export type MutationEnrollArgs = {
  input?: InputMaybe<InitiativesInput>;
};


export type MutationInsertDonationArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<FoundationInput>;
};


export type MutationManageF2FCaseArgs = {
  input?: InputMaybe<ManageF2FCaseInput>;
};


export type MutationMemberArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<MemberInput>;
};


export type MutationSendDynamicEmailArgs = {
  input?: InputMaybe<SendDynamicEmailInput>;
};


export type MutationSendEmailArgs = {
  input?: InputMaybe<EmailInput>;
};


export type MutationSendPaymentReceiptEmailByIdArgs = {
  Id: Scalars['ID']['input'];
};


export type MutationSetDefaultPaymentProfileArgs = {
  Id: Scalars['ID']['input'];
};


export type MutationSetMailboxIdArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
  mailboxId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTransferProspectToMdvipArgs = {
  input: TransferProspectToMdvipInput;
};


export type MutationTransferToMdvipArgs = {
  input?: InputMaybe<TransferToMdvipInput>;
};


export type MutationUpdateAwpArgs = {
  input: UpdateAwpInput;
};


export type MutationUpdateAccountArgs = {
  input?: InputMaybe<UpdateAccountInput>;
};


export type MutationUpdateAwpByPhrIdArgs = {
  input: UpdateAwpByPhrIdInput;
  phrId: Scalars['ID']['input'];
};


export type MutationUpdateCampaignMemberArgs = {
  input?: InputMaybe<UpdateCampaignMemberInput>;
};


export type MutationUpdateConnectUserArgs = {
  Id: Scalars['ID']['input'];
  staffId: Scalars['ID']['input'];
  type: ConnectUserEnum;
};


export type MutationUpdateDefaultPaymentProfileArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDonationArgs = {
  input?: InputMaybe<FoundationInput>;
  rollOutId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateFeatureFlagByIdArgs = {
  Id: Scalars['ID']['input'];
  input: UpdateFeatureFlagByIdInput;
};


export type MutationUpdateFlagsArgs = {
  input?: InputMaybe<UpdateFeatureFlagsInput>;
};


export type MutationUpdateMailboxArgs = {
  mailboxData: UpdateMailboxInput;
};


export type MutationUpdateMailboxByIdArgs = {
  input: UpdateMailboxByIdInput;
};


export type MutationUpdateMemberByContactIdArgs = {
  ContactId: Scalars['String']['input'];
  input: UpdateMemberByContactIdInput;
};


export type MutationUpdateMessageArgs = {
  input?: InputMaybe<UpdateMessageInput>;
};


export type MutationUpdateMessageByIdArgs = {
  input: UpdateMessageByIdInput;
};


export type MutationUpdateMessageThreadByIdArgs = {
  input: UpdateMessageThreadByIdInput;
};


export type MutationUpdatePaymentArgs = {
  input?: InputMaybe<UpdatePaymentInput>;
};


export type MutationUpdatePaymentProfileArgs = {
  input?: InputMaybe<UpdatePaymentProfileInput>;
};


export type MutationUpdatePaymentProfileByIdArgs = {
  Id: Scalars['ID']['input'];
  input: UpdatePaymentProfileByIdInput;
};


export type MutationUpdatePhysicianArgs = {
  Id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdatePhysicianByContactIdArgs = {
  ContactId: Scalars['ID']['input'];
  input: UpdatePhysicianByContactIdInput;
};


export type MutationUpdatePhysicianPracticeStaffAccessByContactIdArgs = {
  ContactId: Scalars['ID']['input'];
  input: UpdatePhysicianPracticeStaffAccessByContactIdInput;
};


export type MutationUpdatePhysicianPracticeStaffByContactIdArgs = {
  ContactId: Scalars['ID']['input'];
  input: UpdatePhysicianPracticeStaffByContactIdInput;
};


export type MutationUpdateProspectArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<ProspectInput>;
};


export type MutationUpdateProspectByContactIdArgs = {
  ContactId: Scalars['ID']['input'];
  input: UpdateProspectByContactIdInput;
};


export type MutationUpdateProspectF2FCaseByCaseIdArgs = {
  CaseId: Scalars['ID']['input'];
  input: UpdateProspectF2FCaseByCaseIdInput;
};


export type MutationUpdateStaffArgs = {
  Id: Scalars['ID']['input'];
  data: StaffDataTypeInput;
};


export type MutationUpdateThreadArgs = {
  input?: InputMaybe<UpdateThreadInput>;
};


export type MutationUpdateUpcomingPaymentByIdArgs = {
  Id: Scalars['ID']['input'];
  input: UpdateUpcomingPaymentByIdInput;
};


export type MutationVerifyAchInformationArgs = {
  input: VerifyAchInformationInput;
};


export type MutationVerifyCreditCardInformationArgs = {
  input: VerifyCreditCardInformationInput;
};

export type MyMailboxIdDomainError = MyMailboxIdNotFoundError;

export type MyMailboxIdFailure = {
  __typename?: 'MyMailboxIdFailure';
  /** All errors associated with required Attachment. */
  errors: Array<MyMailboxIdDomainError>;
};

export type MyMailboxIdInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type MyMailboxIdNotFoundError = DomainError & {
  __typename?: 'MyMailboxIdNotFoundError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type MyMailboxIdResult = MyMailboxIdFailure | MyMailboxIdSuccess;

export type MyMailboxIdSuccess = {
  __typename?: 'MyMailboxIdSuccess';
  id: Scalars['ID']['output'];
};

export type NationalAccount = {
  __typename?: 'NationalAccount';
  Name?: Maybe<Scalars['String']['output']>;
};

export type NationalAccountInput = {
  Name?: InputMaybe<Scalars['String']['input']>;
};

export type NationalAccountType = {
  __typename?: 'NationalAccountType';
  Name?: Maybe<Scalars['String']['output']>;
};

export type PatientActivityReport = {
  __typename?: 'PatientActivityReport';
  edges: Array<PatientActivityReportEdge>;
};

export type PatientActivityReportEdge = {
  __typename?: 'PatientActivityReportEdge';
  DOB?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MemberActivity: Array<PatientMemberActivity>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  RecordTypeName?: Maybe<Scalars['String']['output']>;
};

export type PatientActivityReportInput = {
  AccountId: Scalars['String']['input'];
  Range: Scalars['Int']['input'];
};

export type PatientActivityReportInvalidAccountIdError = DomainError & {
  __typename?: 'PatientActivityReportInvalidAccountIdError';
  /** AccountId that was included in the query */
  AccountId: Scalars['String']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type PatientActivityReportResult = PatientActivityReport | PatientActivityReportInvalidAccountIdError;

export type PatientCaseReport = {
  __typename?: 'PatientCaseReport';
  edges: Array<PatientCaseReportEdge>;
};

export type PatientCaseReportEdge = {
  __typename?: 'PatientCaseReportEdge';
  ClosedDate?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  DOB?: Maybe<Scalars['String']['output']>;
  DateOfFacetoFace?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  RecordTypeName?: Maybe<Scalars['String']['output']>;
  Result?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
};

export type PatientCaseReportInvalidAccountIdError = DomainError & {
  __typename?: 'PatientCaseReportInvalidAccountIdError';
  /** AccountId that was included in the query */
  AccountId: Scalars['String']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type PatientCaseReportResult = PatientCaseReport | PatientCaseReportInvalidAccountIdError;

export type PatientDirectoryReportQuery = {
  __typename?: 'PatientDirectoryReportQuery';
  /**
   * Patient Directory Report for All Active Members
   * Validate that the All Members report delivers all the attributes defined in the acceptance criteria.
   */
  pdrAllActiveMembers: PdrAllActiveMembersResult;
  /**
   * Patient Directory Report for Cancelled Members
   * Members whose memberships have been terminated in the prior 12 months.
   */
  pdrCancelledMembers: PdrCancelledMembersResult;
  /**
   * Patient Directory Report for Future Cancellation members
   * Members scheduled to cancel at a future date.
   */
  pdrFutureCancellationMembers: PdrFutureCancellationMembersResult;
  /**
   * Patient Directory Report for IPPE Calculation
   * As Staff, I want to see which Members are becoming eligible for Medicare in the coming year so that I can schedule the physical.
   */
  pdrIPPECalculation: PdrIppeCalculationResult;
  /**
   * Patient Directory Report for New Members
   * Individuals who have enrolled in your practice within the 12 Months.
   */
  pdrNewMembers: PdrNewMembersResult;
  /**
   * Patient Directory Report for Outstanding Wellness Exam
   * Members who have not had a Wellness Exam performed in over 1 year.
   */
  pdrOutstandingWellnessExam: PdrOutstandingWellnessExamResult;
  /**
   * Patient Directory Report for Past Due Members
   * Members whose payments are overdue (1 thru 61 days overdue).
   * Members greater than 61 days past due are Suspended
   */
  pdrPastDueMembers: PdrPastDueMembersResult;
  /**
   * Patient Directory Report for Past Patient List (Transition Prospects)
   * All pre-MDVIP Practice Patients that have not enrolled.
   * This list will only be available during the first 18 months of practice affiliation.
   */
  pdrPastPatientList: PdrPastPatientListResult;
  /**
   * Patient Directory Report for Pending Wellness Exams
   * Members with a Wellness Exam uploaded that’s awaiting review and approval.
   */
  pdrPendingWellnessExams: PdrPendingWellnessExamsResult;
  /**
   * Patient Directory Report for Recommended Outreach members
   * Members whose should be contacted by the office prior to their upcoming renewal
   * to ensure their satisfaction with the program.(Use Member’s preferred contact method.)
   */
  pdrRecommendedOutreachMembers: PdrRecommendedOutreachMembersResult;
  /**
   * Patient Directory Report for Re-instated Members
   * Any prior members of your practice whose memberships have returned to active status.
   */
  pdrReinstatedMembers: PdrReinstatedMembersResult;
  /**
   * Patient Directory Report for Scholarship Members
   * Members whose annual fees have been waived for 1 year as approved by the Physician.
   */
  pdrScholarshipMembers: PdrScholarshipMembersResult;
  /**
   * Patient Directory Report for Suspended Members
   * Members whose payments are overdue by 62 days or greater.
   * Members less than 62 days past due are not yet Suspended but Past Due
   */
  pdrSuspendedMembers: PdrSuspendedMembersResult;
  /**
   * Patient Directory Report for Upcoming Renewals
   * Members whose memberships are scheduled to renew within the next two months.
   */
  pdrUpcomingRenewals: PdrUpcomingRenewalsResult;
};


export type PatientDirectoryReportQueryPdrAllActiveMembersArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrCancelledMembersArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrFutureCancellationMembersArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrIppeCalculationArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrNewMembersArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrOutstandingWellnessExamArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrPastDueMembersArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrPastPatientListArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrPendingWellnessExamsArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrRecommendedOutreachMembersArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrReinstatedMembersArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrScholarshipMembersArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrSuspendedMembersArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientDirectoryReportQueryPdrUpcomingRenewalsArgs = {
  AccountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PatientMemberActivity = {
  __typename?: 'PatientMemberActivity';
  CreatedByProfile__c?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  WhoId?: Maybe<Scalars['String']['output']>;
};

export type PatientReceiptByDocumentIdFailure = {
  __typename?: 'PatientReceiptByDocumentIdFailure';
  /** All errors associated with required Invoice. */
  errors: Array<PatientReceiptByDocumentIdFailureError>;
};

export type PatientReceiptByDocumentIdFailureError = DomainError & {
  __typename?: 'PatientReceiptByDocumentIdFailureError';
  /** Id that was included in the query */
  contactId: Scalars['String']['output'];
  documentId: Scalars['String']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

/** Query to return Patient Account Invoice Document by ID through Salesforce */
export type PatientReceiptByDocumentIdInput = {
  contactId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
};

export type PatientReceiptByDocumentIdQueryResult = {
  __typename?: 'PatientReceiptByDocumentIdQueryResult';
  document?: Maybe<Scalars['String']['output']>;
};

export type PatientReceiptByDocumentIdResult = PatientReceiptByDocumentIdFailure | PatientReceiptByDocumentIdQueryResult;

export type Payment = {
  __typename?: 'Payment';
  Amount__c?: Maybe<Scalars['Float']['output']>;
  Apply_Date__c?: Maybe<Scalars['String']['output']>;
  Check__c?: Maybe<Scalars['String']['output']>;
  Id: Scalars['ID']['output'];
  Last_4_Digits__c?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Payment_Profile__c?: Maybe<Scalars['String']['output']>;
  Payment_Profile__r?: Maybe<PaymentProfile>;
  Payment_Type__c?: Maybe<PaymentPaymentType>;
  Type__c?: Maybe<TransactionType>;
};

export type PaymentByIdDomainError = PaymentByIdNotFoundDomainError;

export type PaymentByIdFailure = {
  __typename?: 'PaymentByIdFailure';
  errors: Array<PaymentByIdDomainError>;
};

export type PaymentByIdNotFoundDomainError = {
  __typename?: 'PaymentByIdNotFoundDomainError';
  Id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type PaymentByIdResult = Payment | PaymentByIdFailure;

export enum PaymentPaymentType {
  /** Inactive values */
  Ach = 'ACH',
  Amex = 'AMEX',
  /** American Express */
  AmericanExpress = 'American_Express',
  Cash = 'Cash',
  Check = 'Check',
  /** Credit Memo */
  CreditMemo = 'Credit_Memo',
  Disc = 'DISC',
  Discover = 'Discover',
  Mc = 'MC',
  Mastercard = 'Mastercard',
  Visa = 'Visa'
}

export type PaymentPreferencesQuery = {
  __typename?: 'PaymentPreferencesQuery';
  litleErrorByCode: LitleErrorByCodeResult;
  litleSettings: LitleSettingsResult;
  maximumPaymentAmount: MaximumPaymentAmountResult;
};


export type PaymentPreferencesQueryLitleErrorByCodeArgs = {
  code: Scalars['ID']['input'];
};

export type PaymentProfile = {
  __typename?: 'PaymentProfile';
  ACHBankName__c?: Maybe<Scalars['String']['output']>;
  ACHLast6__c?: Maybe<Scalars['String']['output']>;
  ACHRoutingLast9__c?: Maybe<Scalars['String']['output']>;
  ACH_Name_of_Account__c?: Maybe<Scalars['String']['output']>;
  Active__c?: Maybe<Scalars['Boolean']['output']>;
  Address_1__c?: Maybe<Scalars['String']['output']>;
  Address_2__c?: Maybe<Scalars['String']['output']>;
  CCLast4__c?: Maybe<Scalars['String']['output']>;
  Card_Type__c?: Maybe<Scalars['String']['output']>;
  City__c?: Maybe<Scalars['String']['output']>;
  Default__c?: Maybe<Scalars['Boolean']['output']>;
  Expiration_Month__c?: Maybe<Scalars['String']['output']>;
  Expiration_Year__c?: Maybe<Scalars['String']['output']>;
  Expired__c?: Maybe<Scalars['String']['output']>;
  HSACard__c?: Maybe<PaymentProfileHsaCard>;
  Id: Scalars['ID']['output'];
  Issuer_Country__c?: Maybe<Scalars['String']['output']>;
  Member_ID__c?: Maybe<Scalars['String']['output']>;
  Name_on_Card__c?: Maybe<Scalars['String']['output']>;
  PaymentType__c?: Maybe<ExtendedPaymentProfilePaymentType>;
  States__c?: Maybe<Scalars['String']['output']>;
  Zipcode__c?: Maybe<Scalars['String']['output']>;
};

export enum PaymentProfileAchAccountType {
  Checking = 'Checking',
  Other = 'Other',
  Savings = 'Savings'
}

export type PaymentProfileByIdDomainError = PaymentProfileByIdNotFoundDomainError;

export type PaymentProfileByIdFailure = {
  __typename?: 'PaymentProfileByIdFailure';
  errors: Array<PaymentProfileByIdDomainError>;
};

export type PaymentProfileByIdNotFoundDomainError = DomainError & {
  __typename?: 'PaymentProfileByIdNotFoundDomainError';
  Id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type PaymentProfileByIdResult = PaymentProfile | PaymentProfileByIdFailure;

export enum PaymentProfileCardType {
  Commercial = 'COMMERCIAL',
  Consumer = 'CONSUMER',
  Unknown = 'UNKNOWN'
}

export enum PaymentProfileHsaCard {
  No = 'No',
  Yes = 'Yes'
}

export enum PaymentProfilePaymentType {
  Ach = 'ACH',
  Amex = 'AMEX',
  Disc = 'DISC',
  Mc = 'MC',
  Visa = 'VISA'
}

export enum PaymentProfileTypeFilter {
  BankAccount = 'BANK_ACCOUNT',
  CreditCard = 'CREDIT_CARD'
}

export type PaymentProfile_Old = {
  __typename?: 'PaymentProfile_Old';
  ACHBankName__c?: Maybe<Scalars['String']['output']>;
  ACHLast6__c?: Maybe<Scalars['String']['output']>;
  ACHRoutingLast9__c?: Maybe<Scalars['String']['output']>;
  ACH_Account_Type__c?: Maybe<Scalars['String']['output']>;
  ACH_Name_of_Account__c?: Maybe<Scalars['String']['output']>;
  Active__c?: Maybe<Scalars['Boolean']['output']>;
  Address_1__c?: Maybe<Scalars['String']['output']>;
  Address_2__c?: Maybe<Scalars['String']['output']>;
  CCLast4__c?: Maybe<Scalars['String']['output']>;
  Card_Type__c?: Maybe<Scalars['String']['output']>;
  City__c?: Maybe<Scalars['String']['output']>;
  Default__c?: Maybe<Scalars['Boolean']['output']>;
  Expiration_Month__c?: Maybe<Scalars['String']['output']>;
  Expiration_Year__c?: Maybe<Scalars['String']['output']>;
  Expired__c?: Maybe<Scalars['String']['output']>;
  HSACard__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Issuer_Country__c?: Maybe<Scalars['String']['output']>;
  Member_ID__c?: Maybe<Scalars['String']['output']>;
  Name_on_Card__c?: Maybe<Scalars['String']['output']>;
  PaymentType__c?: Maybe<PaymentTypeEnum>;
  States__c?: Maybe<Scalars['String']['output']>;
  Zipcode__c?: Maybe<Scalars['String']['output']>;
};

export type PaymentProfilesFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  mdvipId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PaymentProfileTypeFilter>;
};

export type PaymentProfilesInput = {
  filters?: InputMaybe<PaymentProfilesFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentProfilesResult = {
  __typename?: 'PaymentProfilesResult';
  edges: Array<PaymentProfile>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type PaymentSettingsInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<RequestTypeEnum>;
};

export type PaymentSettingsType = {
  __typename?: 'PaymentSettingsType';
  LitleInterfaceDetails?: Maybe<LitleInterfaceDetailsType>;
  MaximumPaymentAmount?: Maybe<MaximumPaymentAmountType>;
  ResponseCodeMessage?: Maybe<ResponseCodeMessageType>;
};

export enum PaymentType {
  CreditCardPayment = 'CreditCardPayment',
  Payment = 'Payment'
}

export enum PaymentTypeEnum {
  Ach = 'ACH',
  Amex = 'AMEX',
  Check = 'Check',
  Disc = 'DISC',
  Discover = 'Discover',
  Mc = 'MC',
  Mastercard = 'Mastercard',
  Visa = 'VISA'
}

export type Payment_Old = {
  __typename?: 'Payment_Old';
  ACHBankName__c?: Maybe<Scalars['String']['output']>;
  ACHLast6__c?: Maybe<Scalars['String']['output']>;
  Amount__c?: Maybe<Scalars['String']['output']>;
  Apply_Date__c?: Maybe<Scalars['String']['output']>;
  CCLast4__c?: Maybe<Scalars['String']['output']>;
  Check__c?: Maybe<Scalars['String']['output']>;
  Date_Processed__c?: Maybe<Scalars['String']['output']>;
  Expiration_Month__c?: Maybe<Scalars['String']['output']>;
  Expiration_Year__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  PaymentType__c?: Maybe<Scalars['String']['output']>;
  Payment_Amount__c?: Maybe<Scalars['String']['output']>;
  Payment_Profile__c?: Maybe<Scalars['String']['output']>;
  Payment_Profile__r?: Maybe<PaymentProfile_Old>;
  Payment_Type__c?: Maybe<Scalars['String']['output']>;
  Schedule_Date__c?: Maybe<Scalars['String']['output']>;
};

export type PaymentsFilters = {
  Type__c?: InputMaybe<Array<TransactionType>>;
};

export type PaymentsInput = {
  filters?: InputMaybe<PaymentsFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentsResult = {
  __typename?: 'PaymentsResult';
  edges: Array<Payment>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/**
 * Patient Directory Report - Active Member
 * Validate that the All Members report delivers all the attributes defined in the acceptance criteria.
 */
export type PdrActiveMember = {
  __typename?: 'PdrActiveMember';
  AccountId?: Maybe<Scalars['String']['output']>;
  Age__c?: Maybe<Scalars['String']['output']>;
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Bill_to_National_Account__c?: Maybe<Scalars['String']['output']>;
  Billing_Days_Past_Due__c?: Maybe<Scalars['String']['output']>;
  BirthDate?: Maybe<Scalars['String']['output']>;
  Current_Agreement_MSRP__c?: Maybe<Scalars['Float']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Current_Balance_Formula__c?: Maybe<Scalars['String']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Do_Not_Sync_EHR__c?: Maybe<Scalars['Boolean']['output']>;
  EID__c?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Employer__c?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  Insurance__r?: Maybe<Insurance>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_App_Login__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Portal_Login__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  LeadSource?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccount>;
  Next_Birthday__c?: Maybe<Scalars['String']['output']>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Referral_Name__c?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
  Unknown_Insurance_Company_Name__c?: Maybe<Scalars['String']['output']>;
};

/** Patient Directory Report - All Active Members */
export type PdrAllActiveMembersResult = {
  __typename?: 'PdrAllActiveMembersResult';
  edges: Array<PdrActiveMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/** Cancelled Member - Patient Directory Report */
export type PdrCancelledMember = {
  __typename?: 'PdrCancelledMember';
  Cancel_Reason__c?: Maybe<Scalars['String']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Current_Balance__c?: Maybe<Scalars['Float']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Do_Not_Sync_EHR__c?: Maybe<Scalars['Boolean']['output']>;
  EID__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Email?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Key_Event_Log__c */
  Event_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  Insurance__r?: Maybe<Insurance>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Cancel_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccount>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Portal_Audience__c?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Status__c?: Maybe<Scalars['String']['output']>;
  Subject__c?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
};

/** Patient Directory Report - Cancelled Members */
export type PdrCancelledMembersResult = {
  __typename?: 'PdrCancelledMembersResult';
  edges: Array<PdrCancelledMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/**
 * Patient Directory Report for Future Cancellation members
 * Member scheduled to cancel at a future date.
 */
export type PdrFutureCancellationMember = {
  __typename?: 'PdrFutureCancellationMember';
  AccountId?: Maybe<Scalars['String']['output']>;
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Bill_to_National_Account__c?: Maybe<Scalars['String']['output']>;
  Cancel_Reason__c?: Maybe<Scalars['String']['output']>;
  Current_Agreement_MSRP__c?: Maybe<Scalars['Float']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Current_Balance_Formula__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Cancel_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccount>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
};

/** Patient Directory Report - Future Cancellations */
export type PdrFutureCancellationMembersResult = {
  __typename?: 'PdrFutureCancellationMembersResult';
  edges: Array<PdrFutureCancellationMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type PdrIppeCalculationMember = {
  __typename?: 'PdrIPPECalculationMember';
  AccountId?: Maybe<Scalars['String']['output']>;
  Age__c?: Maybe<Scalars['String']['output']>;
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Current_Agreement_MSRP__c?: Maybe<Scalars['Float']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Current_Balance_Formula__c?: Maybe<Scalars['String']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  EID__c?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Id: Scalars['ID']['output'];
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Next_Birthday__c?: Maybe<Scalars['String']['output']>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  /** Date MDVIP Member turn 66 years of age */
  SuggestedEndDate?: Maybe<Scalars['String']['output']>;
  /** Date MDVIP Member turn 65 years of age */
  SuggestedStartDate?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
  Unknown_Insurance_Company_Name__c?: Maybe<Scalars['String']['output']>;
};

/** Patient Directory Report - IPPE calculation */
export type PdrIppeCalculationResult = {
  __typename?: 'PdrIPPECalculationResult';
  edges: Array<PdrIppeCalculationMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/** New Member - Patient Directory Report */
export type PdrNewMember = {
  __typename?: 'PdrNewMember';
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Cancel_Reason__c?: Maybe<Scalars['String']['output']>;
  Current_Agreement_MSRP__c?: Maybe<Scalars['Float']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Email?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Key_Event_Log__c  */
  Event_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  Insurance__r?: Maybe<Insurance>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  LeadSource?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccount>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Portal_Audience__c?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Status__c?: Maybe<Scalars['String']['output']>;
  Subject__c?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
  Unknown_Insurance_Company_Name__c?: Maybe<Scalars['String']['output']>;
};

/** Patient Directory Report - New Members */
export type PdrNewMembersResult = {
  __typename?: 'PdrNewMembersResult';
  edges: Array<PdrNewMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/**
 * Outstanding Wellness Exam Member
 * Member who have not had a Wellness Exam performed in over 1 year.
 */
export type PdrOutstandingWellnessExamMember = {
  __typename?: 'PdrOutstandingWellnessExamMember';
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Current_Balance_Formula__c?: Maybe<Scalars['String']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Email?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
};

/** Patient Directory Report - Outstanding Wellness Exam */
export type PdrOutstandingWellnessExamResult = {
  __typename?: 'PdrOutstandingWellnessExamResult';
  edges: Array<PdrOutstandingWellnessExamMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/**
 * Patient Directory Report - Past Due Member
 * Members whose payments are overdue (1 thru 61 days overdue).
 * Members greater than 61 days past due are Suspended
 */
export type PdrPastDueMember = {
  __typename?: 'PdrPastDueMember';
  AccountId?: Maybe<Scalars['String']['output']>;
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Bill_to_National_Account__r?: Maybe<Scalars['String']['output']>;
  Billing_Days_Past_Due__c?: Maybe<Scalars['Int']['output']>;
  CC_Decline_Call__c?: Maybe<Scalars['String']['output']>;
  CC_Decline_Letter__c?: Maybe<Scalars['String']['output']>;
  Current_Balance__c?: Maybe<Scalars['Float']['output']>;
  DUN_Call_1__c?: Maybe<Scalars['String']['output']>;
  DUN_Call_2__c?: Maybe<Scalars['String']['output']>;
  DUN_Call_3__c?: Maybe<Scalars['String']['output']>;
  DUN_Call_4__c?: Maybe<Scalars['String']['output']>;
  DUN_Email_2__c?: Maybe<Scalars['String']['output']>;
  DUN_Email_3__c?: Maybe<Scalars['String']['output']>;
  DUN_Email_4__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_1__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_2__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_3__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_4__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Dun_Email_1__c?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount_Text__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccount>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
  X30_Days__c?: Maybe<Scalars['String']['output']>;
  X60_Days__c?: Maybe<Scalars['String']['output']>;
  X90_Days__c?: Maybe<Scalars['String']['output']>;
  X120_Days__c?: Maybe<Scalars['String']['output']>;
};

/** Patient Directory Report - Past Due Members */
export type PdrPastDueMembersResult = {
  __typename?: 'PdrPastDueMembersResult';
  edges: Array<PdrPastDueMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/** Past Patient (Transition Prospect) */
export type PdrPastPatient = {
  __typename?: 'PdrPastPatient';
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
};

/** Patient Directory Report - Past Patient List (Transition Prospects) */
export type PdrPastPatientListResult = {
  __typename?: 'PdrPastPatientListResult';
  edges: Array<PdrPastPatient>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/**
 * Patient Directory Report for Pending Wellness Exams
 * Members with a Wellness Exam uploaded that’s awaiting review and approval.
 */
export type PdrPendingWellnessExam = {
  __typename?: 'PdrPendingWellnessExam';
  AccountId?: Maybe<Scalars['String']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Email?: Maybe<Scalars['String']['output']>;
  Exam_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
  Upload_Date__c?: Maybe<Scalars['String']['output']>;
  Upload_Status__c?: Maybe<Scalars['String']['output']>;
};

/** Patient Directory Report - Pending Wellness Exams */
export type PdrPendingWellnessExamsResult = {
  __typename?: 'PdrPendingWellnessExamsResult';
  edges: Array<PdrPendingWellnessExam>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/**
 * Patient Directory Report for Recommended Outreach members
 * Member whose should be contacted by the office prior to their upcoming renewal
 * to ensure their satisfaction with the program.(Use Member’s preferred contact method.)
 */
export type PdrRecommendedOutreachMember = {
  __typename?: 'PdrRecommendedOutreachMember';
  AccountId?: Maybe<Scalars['String']['output']>;
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Billing_Days_Past_Due__c?: Maybe<Scalars['Int']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Current_Balance_Formula__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccount>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
};

/** Patient Directory Report - Recommended Outreach */
export type PdrRecommendedOutreachMembersResult = {
  __typename?: 'PdrRecommendedOutreachMembersResult';
  edges: Array<PdrRecommendedOutreachMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/** Reinstated Member - Patient Directory Report */
export type PdrReinstatedMember = {
  __typename?: 'PdrReinstatedMember';
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Bill_to_National_Account__c?: Maybe<Scalars['String']['output']>;
  Cancel_Reason__c?: Maybe<Scalars['String']['output']>;
  Current_Agreement_MSRP__c?: Maybe<Scalars['Float']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Current_Balance_Formula__c?: Maybe<Scalars['String']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Email?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Key_Event_Log__c */
  Event_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  Insurance__r?: Maybe<Insurance>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  LeadSource?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccount>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Portal_Audience__c?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Status__c?: Maybe<Scalars['String']['output']>;
  Subject__c?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
  Unknown_Insurance_Company_Name__c?: Maybe<Scalars['String']['output']>;
};

/** Patient Directory Report - Re-instated Members */
export type PdrReinstatedMembersResult = {
  __typename?: 'PdrReinstatedMembersResult';
  edges: Array<PdrReinstatedMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/**
 * Patient Directory Report - Scholarship Member
 * Member whose annual fees have been waived for 1 year as approved by the Physician.
 */
export type PdrScholarshipMember = {
  __typename?: 'PdrScholarshipMember';
  AccountId?: Maybe<Scalars['String']['output']>;
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Bill_to_National_Account__c?: Maybe<Scalars['String']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Current_Balance__c?: Maybe<Scalars['Float']['output']>;
  DUN_Call_1__c?: Maybe<Scalars['String']['output']>;
  DUN_Call_2__c?: Maybe<Scalars['String']['output']>;
  DUN_Call_3__c?: Maybe<Scalars['String']['output']>;
  DUN_Call_4__c?: Maybe<Scalars['String']['output']>;
  DUN_Email_2__c?: Maybe<Scalars['String']['output']>;
  DUN_Email_3__c?: Maybe<Scalars['String']['output']>;
  DUN_Email_4__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_1__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_2__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_3__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_4__c?: Maybe<Scalars['String']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Dun_Email_1__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Email?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  Insurance__r?: Maybe<Insurance>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  LeadSource?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccount>;
  Next_Birthday__c?: Maybe<Scalars['String']['output']>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Scholarship_End_Date__c?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
  Unknown_Insurance_Company_Name__c?: Maybe<Scalars['String']['output']>;
};

/** Patient Directory Report - Scholarship Members */
export type PdrScholarshipMembersResult = {
  __typename?: 'PdrScholarshipMembersResult';
  edges: Array<PdrScholarshipMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/**
 * Patient Directory Report - Suspended Member
 * Member whose payments are overdue by 62 days or greater.
 * Member less than 62 days past due are not yet Suspended but Past Due
 */
export type PdrSuspendedMember = {
  __typename?: 'PdrSuspendedMember';
  AccountId?: Maybe<Scalars['String']['output']>;
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Bill_to_National_Account__r?: Maybe<Scalars['String']['output']>;
  Billing_Days_Past_Due__c?: Maybe<Scalars['Int']['output']>;
  CC_Decline_Call__c?: Maybe<Scalars['String']['output']>;
  CC_Decline_Letter__c?: Maybe<Scalars['String']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Current_Balance_Formula__c?: Maybe<Scalars['String']['output']>;
  Current_Balance__c?: Maybe<Scalars['Float']['output']>;
  DUN_Call_1__c?: Maybe<Scalars['String']['output']>;
  DUN_Call_2__c?: Maybe<Scalars['String']['output']>;
  DUN_Call_3__c?: Maybe<Scalars['String']['output']>;
  DUN_Call_4__c?: Maybe<Scalars['String']['output']>;
  DUN_Email_2__c?: Maybe<Scalars['String']['output']>;
  DUN_Email_3__c?: Maybe<Scalars['String']['output']>;
  DUN_Email_4__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_1__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_2__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_3__c?: Maybe<Scalars['String']['output']>;
  DUN_Letter_4__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Dun_Email_1__c?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  Insurance__r?: Maybe<Insurance>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccount>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
  X30_Days__c?: Maybe<Scalars['String']['output']>;
  X60_Days__c?: Maybe<Scalars['String']['output']>;
  X90_Days__c?: Maybe<Scalars['String']['output']>;
  X120_Days__c?: Maybe<Scalars['String']['output']>;
};

/** Patient Directory Report - Suspended Members */
export type PdrSuspendedMembersResult = {
  __typename?: 'PdrSuspendedMembersResult';
  edges: Array<PdrSuspendedMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/**
 * Patient Directory Report - Outstanding Wellness Exam Member
 * Member whose memberships are scheduled to renew within the next two months.
 */
export type PdrUpcomingRenewalMember = {
  __typename?: 'PdrUpcomingRenewalMember';
  AutoPay__c?: Maybe<Scalars['Boolean']['output']>;
  Bill_to_National_Account__c?: Maybe<Scalars['String']['output']>;
  Current_Agreement_MSRP__c?: Maybe<Scalars['Float']['output']>;
  Current_Agreement_Payment_Schedule__c?: Maybe<Scalars['String']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Do_Not_Sync_EHR__c?: Maybe<Scalars['Boolean']['output']>;
  EID__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fields that are not needed for the report
   * but are used for the UI.
   */
  Email?: Maybe<Scalars['String']['output']>;
  Established_Date__c?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Fields for the report from Contact */
  Id: Scalars['ID']['output'];
  Insurance__r?: Maybe<Insurance>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_Cancel_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Amount__c?: Maybe<Scalars['String']['output']>;
  Last_Payment_Date__c?: Maybe<Scalars['String']['output']>;
  Last_Wellness_Exam__c?: Maybe<Scalars['String']['output']>;
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  MFMailboxID__c?: Maybe<Scalars['String']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  Member_Status__c?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  National_Account__r?: Maybe<NationalAccount>;
  Next_Renewal_Date_Formula__c?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Array<Scalars['String']['output']>>;
  TodoCount: Scalars['Int']['output'];
};

/** Patient Directory Report - Outstanding Wellness Exam */
export type PdrUpcomingRenewalsResult = {
  __typename?: 'PdrUpcomingRenewalsResult';
  edges: Array<PdrUpcomingRenewalMember>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type Physician = Contact & {
  __typename?: 'Physician';
  /** Account information of Physician */
  Account?: Maybe<Account>;
  /** AccountId of Physician */
  AccountId: Scalars['String']['output'];
  AmountMSRP__c?: Maybe<Scalars['Int']['output']>;
  /** Date_Of_Birth__c of Physician */
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  /** Disposition_Code__c of Physician */
  Disposition_Code__c?: Maybe<Scalars['String']['output']>;
  /** Email of Physician */
  Email?: Maybe<Scalars['String']['output']>;
  /**
   * Emergency_Phone__c of Physician
   * Format: (XXX) XXX-XXXX
   */
  Emergency_Phone__c?: Maybe<Scalars['String']['output']>;
  /** Estimated_Paid_Through__c of Physician */
  Estimated_Paid_Through__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fax from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Fax?: Maybe<Scalars['String']['output']>;
  /** FirstName of Physician */
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Gender__c of Physician */
  Gender__c?: Maybe<Scalars['String']['output']>;
  /** AccountId of Physician */
  Id: Scalars['ID']['output'];
  /** LastName of Physician */
  LastName?: Maybe<Scalars['String']['output']>;
  /** Last_App_Login__c of Physician */
  Last_App_Login__c?: Maybe<Scalars['String']['output']>;
  /** MailingCity of Physician */
  MailingCity?: Maybe<Scalars['String']['output']>;
  /** MailingPostalCode of Physician */
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  /** MailingState of Physician */
  MailingState?: Maybe<Scalars['String']['output']>;
  /** MailingStreet of Physician */
  MailingStreet?: Maybe<Scalars['String']['output']>;
  /**
   * MobilePhone of Physician
   * Format: (XXX) XXX-XXXX
   */
  MobilePhone?: Maybe<Scalars['String']['output']>;
  /**
   * Phone of Physician
   * Format: (XXX) XXX-XXXX
   */
  Phone?: Maybe<Scalars['String']['output']>;
  /** User preference to enable push notifications for new secure messages */
  Push_Notifications__c?: Maybe<Scalars['Boolean']['output']>;
  /** User preference to enable email notifications for new secure messages */
  SM_Email_Notification__c?: Maybe<Scalars['Boolean']['output']>;
  /** Salutation of Physician */
  Salutation?: Maybe<Scalars['String']['output']>;
  /** Localized string of Date_Of_Birth__c */
  USLocalizedDateOfBirth?: Maybe<Scalars['String']['output']>;
};

export type PhysicianByAccountIdDomainError = PhysicianByAccountIdNotFoundError;

/** A domain-specific failure to retrieve a physician by AccountId. */
export type PhysicianByAccountIdFailure = {
  __typename?: 'PhysicianByAccountIdFailure';
  /** All errors associated with required Physician. */
  errors: Array<PhysicianByAccountIdDomainError>;
};

export type PhysicianByAccountIdNotFoundError = DomainError & {
  __typename?: 'PhysicianByAccountIdNotFoundError';
  /** AccountId that was included in the query */
  AccountId: Scalars['String']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type PhysicianByAccountIdResult = Physician | PhysicianByAccountIdFailure;

export type PhysicianByContactIdDomainError = PhysicianByContactIdNotFoundError;

/** A domain-specific failure to retrieve a physician by ContactId. */
export type PhysicianByContactIdFailure = {
  __typename?: 'PhysicianByContactIdFailure';
  /** All errors associated with required Physician. */
  errors: Array<PhysicianByContactIdDomainError>;
};

export type PhysicianByContactIdNotFoundError = DomainError & {
  __typename?: 'PhysicianByContactIdNotFoundError';
  /** ContactId that was included in the query */
  ContactId: Scalars['String']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type PhysicianByContactIdResult = Physician | PhysicianByContactIdFailure;

export type PhysicianPracticeStaff = Contact & {
  __typename?: 'PhysicianPracticeStaff';
  /** AccountId from Contact record */
  AccountId: Scalars['String']['output'];
  /** Connect_User_Status__c from Contact record */
  Connect_User_Status__c?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Date_Of_Birth__c from Contact record
   * Format: YYYY-MM-DD
   */
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  /** Disposition_Code__c from Contact record */
  Disposition_Code__c?: Maybe<Scalars['String']['output']>;
  /** Email from Contact record */
  Email?: Maybe<Scalars['String']['output']>;
  /**
   * Emergency_Phone__c from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Emergency_Phone__c?: Maybe<Scalars['String']['output']>;
  /** Estimated_Paid_Through__c from Contact record */
  Estimated_Paid_Through__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fax from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Fax?: Maybe<Scalars['String']['output']>;
  /** FirstName from Contact record */
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Gender__c from Contact record */
  Gender__c?: Maybe<Scalars['String']['output']>;
  /** Id from Contact record */
  Id: Scalars['ID']['output'];
  /** LastName from Contact record */
  LastName?: Maybe<Scalars['String']['output']>;
  /** Last_App_Login__c from Contact record */
  Last_App_Login__c?: Maybe<Scalars['String']['output']>;
  /** MDVIP_Connect_User__c from Contact record */
  MDVIP_Connect_User__c?: Maybe<Scalars['Boolean']['output']>;
  /** MailingCity from Contact record */
  MailingCity?: Maybe<Scalars['String']['output']>;
  /** MailingPostalCode from Contact record */
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  /** MailingState from Contact record */
  MailingState?: Maybe<Scalars['String']['output']>;
  /** MailingStreet from Contact record */
  MailingStreet?: Maybe<Scalars['String']['output']>;
  /**
   * MobilePhone from Contact record
   * Format: (XXX) XXX-XXXX
   */
  MobilePhone?: Maybe<Scalars['String']['output']>;
  /**
   * Phone from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Phone?: Maybe<Scalars['String']['output']>;
  /** User preference to enable push notifications for new secure messages */
  Push_Notifications__c?: Maybe<Scalars['Boolean']['output']>;
  /** User preference to enable email notifications for new secure messages */
  SM_Email_Notification__c?: Maybe<Scalars['Boolean']['output']>;
  /** Salutation from Contact record */
  Salutation?: Maybe<Scalars['String']['output']>;
  /**
   * Localized string of Date_Of_Birth__c
   * Format: MM/DD/YYYY
   */
  USLocalizedDateOfBirth?: Maybe<Scalars['String']['output']>;
  /** UserNameNotQualified__c from Contact record */
  UserNameNotQualified__c?: Maybe<Scalars['String']['output']>;
  /** The PhysicianPracticeStaff's corresponding Physician. */
  physician?: Maybe<Physician>;
};

export type PhysicianPracticeStaffByContactIdDomainError = PhysicianPracticeStaffByContactIdNotFoundError;

/** A domain-specific failure to retrieve a PhysicianPracticestaff by Id. */
export type PhysicianPracticeStaffByContactIdFailure = {
  __typename?: 'PhysicianPracticeStaffByContactIdFailure';
  /** All errors associated with required PhysicianPracticeStaff. */
  errors: Array<PhysicianPracticeStaffByContactIdDomainError>;
};

export type PhysicianPracticeStaffByContactIdNotFoundError = DomainError & {
  __typename?: 'PhysicianPracticeStaffByContactIdNotFoundError';
  /** ContactId that was included in the query */
  ContactId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type PhysicianPracticeStaffByContactIdResult = PhysicianPracticeStaff | PhysicianPracticeStaffByContactIdFailure;

export type PhysicianPracticeStaffsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PhysicianPracticeStaffsResult = {
  __typename?: 'PhysicianPracticeStaffsResult';
  edges: Array<PhysicianPracticeStaff>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type PhysicianType = ContactType & {
  __typename?: 'PhysicianType';
  Account?: Maybe<AccountInfoType>;
  AccountId?: Maybe<Scalars['String']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Disposition_Code__c?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Emergency_Phone__c?: Maybe<Scalars['String']['output']>;
  Estimated_Paid_Through__c?: Maybe<Scalars['String']['output']>;
  Fax?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Gender__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['ID']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_App_Login__c?: Maybe<Scalars['String']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingCity */
  Primary_City__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingState */
  Primary_State__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingStreet */
  Primary_Street_Address__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingPostalCode */
  Primary_Zip_Postal_Code__c?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  USLocalizedDateOfBirth?: Maybe<Scalars['String']['output']>;
  memberIds?: Maybe<Array<Scalars['String']['output']>>;
  members?: Maybe<Array<Maybe<MemberType>>>;
  staff?: Maybe<Array<StaffType>>;
};

export type PhysiciansInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PhysiciansResult = {
  __typename?: 'PhysiciansResult';
  edges: Array<Physician>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type PracticeReportByFilePathPayload = {
  __typename?: 'PracticeReportByFilePathPayload';
  PHR?: Maybe<Scalars['String']['output']>;
};

export type PracticeReportByFilePathResult = PracticeReportByFilePathPayload;

export type Prospect = Contact & {
  __typename?: 'Prospect';
  /** AccountId of Prospect */
  AccountId: Scalars['String']['output'];
  /** CreatedDate of Prospect */
  CreatedDate?: Maybe<Scalars['String']['output']>;
  /**
   * Date_Of_Birth__c of Prospect
   * Format: YYYY-MM-DD
   */
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  /** Disposition_Code__c of Prospect */
  Disposition_Code__c?: Maybe<Scalars['String']['output']>;
  /** Email of Prospect */
  Email?: Maybe<Scalars['String']['output']>;
  /**
   * Emergency_Phone__c of Prospect
   * Format: (XXX) XXX-XXXX
   */
  Emergency_Phone__c?: Maybe<Scalars['String']['output']>;
  /** Estimated_Paid_Through__c of Prospect */
  Estimated_Paid_Through__c?: Maybe<Scalars['String']['output']>;
  /**
   * Fax from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Fax?: Maybe<Scalars['String']['output']>;
  /** FirstName of Prospect */
  FirstName?: Maybe<Scalars['String']['output']>;
  /** Gender__c of Prospect */
  Gender__c?: Maybe<Scalars['String']['output']>;
  /** Id of Prospect */
  Id: Scalars['ID']['output'];
  /** Joiner_Type__c of Prospect */
  Joiner_Type__c?: Maybe<Scalars['String']['output']>;
  /** LastName of Prospect */
  LastName?: Maybe<Scalars['String']['output']>;
  /** Last_App_Login__c of Prospect */
  Last_App_Login__c?: Maybe<Scalars['String']['output']>;
  /** LeadSource of Prospect */
  LeadSource?: Maybe<Scalars['String']['output']>;
  /** MailingCity of Prospect */
  MailingCity?: Maybe<Scalars['String']['output']>;
  /** MailingPostalCode of Prospect */
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  /** MailingState of Prospect */
  MailingState?: Maybe<Scalars['String']['output']>;
  /** MailingStreet of Prospect */
  MailingStreet?: Maybe<Scalars['String']['output']>;
  /**
   * MobilePhone of Prospect
   * Format: (XXX) XXX-XXXX
   */
  MobilePhone?: Maybe<Scalars['String']['output']>;
  /**
   * Phone of Prospect
   * Format: (XXX) XXX-XXXX
   */
  Phone?: Maybe<Scalars['String']['output']>;
  /** User preference to enable push notifications for new secure messages */
  Push_Notifications__c?: Maybe<Scalars['Boolean']['output']>;
  /** User preference to enable email notifications for new secure messages */
  SM_Email_Notification__c?: Maybe<Scalars['Boolean']['output']>;
  /** Salutation of Prospect */
  Salutation?: Maybe<Scalars['String']['output']>;
  /**
   * Localized string of Date_Of_Birth__c
   * Format: MM/DD/YYYY
   */
  USLocalizedDateOfBirth?: Maybe<Scalars['String']['output']>;
  /** Unknown_Insurance_Company_Name__c of Prospect */
  Unknown_Insurance_Company_Name__c?: Maybe<Scalars['String']['output']>;
  /** Prospect case */
  case?: Maybe<ProspectF2FCase>;
  /** Physician for this Prospect */
  physician?: Maybe<Physician>;
};

export type ProspectByContactIdDomainError = ProspectByContactIdNotFoundError;

/** A domain-specific failure to retrieve a prospect by Id. */
export type ProspectByContactIdFailure = {
  __typename?: 'ProspectByContactIdFailure';
  /** All errors associated with required Physician. */
  errors: Array<ProspectByContactIdDomainError>;
};

export type ProspectByContactIdNotFoundError = DomainError & {
  __typename?: 'ProspectByContactIdNotFoundError';
  /** ContactId that was included in the query */
  ContactId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type ProspectByContactIdResult = Prospect | ProspectByContactIdFailure;

export type ProspectCase = {
  __typename?: 'ProspectCase';
  AccountId?: Maybe<Scalars['String']['output']>;
  ContactId?: Maybe<Scalars['String']['output']>;
  Date_of_Face_to_Face__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Meeting_Time__c?: Maybe<Scalars['String']['output']>;
  Result__c?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
};

export type ProspectF2FCase = {
  __typename?: 'ProspectF2FCase';
  /** AccountId of the prospect case */
  AccountId: Scalars['String']['output'];
  /** ContactId of the prospect case */
  ContactId: Scalars['String']['output'];
  /** Date_of_Face_to_Face__c of the prospect case */
  Date_of_Face_to_Face__c?: Maybe<Scalars['String']['output']>;
  /** Id of the prospect case */
  Id: Scalars['String']['output'];
  /** Meeting_Time__c of the prospect case */
  Meeting_Time__c?: Maybe<Scalars['String']['output']>;
  /** Result__c of the prospect case */
  Result__c?: Maybe<Scalars['String']['output']>;
  /** Status of the prospect case */
  Status?: Maybe<ProspectF2FCaseStatus>;
};

export enum ProspectF2FCaseStatus {
  /** Means that the appointment is cancelled */
  AppointmentCancelled = 'AppointmentCancelled',
  /** Means that the appointment is completed */
  AppointmentCompleted = 'AppointmentCompleted',
  /** Means that the appointment is pending */
  AppointmentPending = 'AppointmentPending',
  /** Means that the appointment is refused */
  AppointmentRefused = 'AppointmentRefused',
  /** Means that the appointment is scheduled */
  AppointmentScheduled = 'AppointmentScheduled'
}

export type ProspectInput = {
  AccountID?: InputMaybe<Scalars['String']['input']>;
  AcquisitionChannel?: InputMaybe<Scalars['String']['input']>;
  AcquisitionSubChannel?: InputMaybe<Scalars['String']['input']>;
  CampaignID?: InputMaybe<Scalars['String']['input']>;
  ContactID: Scalars['String']['input'];
  Date_Of_Birth__c?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender__c?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LeadID?: InputMaybe<Scalars['String']['input']>;
  LeadSource?: InputMaybe<Scalars['String']['input']>;
  MDVIPCanContact?: InputMaybe<Scalars['Boolean']['input']>;
  MDVIPID?: InputMaybe<Scalars['String']['input']>;
  MailingCity?: InputMaybe<Scalars['String']['input']>;
  MailingPostalCode?: InputMaybe<Scalars['String']['input']>;
  MailingState?: InputMaybe<Scalars['String']['input']>;
  MailingStreet?: InputMaybe<Scalars['String']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Phone?: InputMaybe<Scalars['String']['input']>;
  PhysicianName?: InputMaybe<Scalars['String']['input']>;
  Primary_City__c?: InputMaybe<Scalars['String']['input']>;
  Primary_State__c?: InputMaybe<Scalars['String']['input']>;
  Primary_Street_Address__c?: InputMaybe<Scalars['String']['input']>;
  Primary_Zip_Postal_Code__c?: InputMaybe<Scalars['String']['input']>;
  ProfileName?: InputMaybe<Scalars['String']['input']>;
  Referral_Name__c?: InputMaybe<Scalars['String']['input']>;
  Salutation?: InputMaybe<Scalars['String']['input']>;
  USLocalizedDateOfBirth?: InputMaybe<Scalars['String']['input']>;
  Unknown_Insurance_Company_Name__c?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
  UserNameNotQualified?: InputMaybe<Scalars['String']['input']>;
};

export type ProspectType = ContactType & {
  __typename?: 'ProspectType';
  AccountId?: Maybe<Scalars['String']['output']>;
  Case?: Maybe<ProspectCase>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Disposition_Code__c?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Emergency_Phone__c?: Maybe<Scalars['String']['output']>;
  Estimated_Paid_Through__c?: Maybe<Scalars['String']['output']>;
  Fax?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Gender__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['ID']['output']>;
  Joiner_Type__c?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_App_Login__c?: Maybe<Scalars['String']['output']>;
  LeadSource?: Maybe<Scalars['String']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingCity */
  Primary_City__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingState */
  Primary_State__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingStreet */
  Primary_Street_Address__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingPostalCode */
  Primary_Zip_Postal_Code__c?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  USLocalizedDateOfBirth?: Maybe<Scalars['String']['output']>;
  Unknown_Insurance_Company_Name__c?: Maybe<Scalars['String']['output']>;
  physician?: Maybe<PhysicianType>;
};

export type ProspectsByAccountIdInput = {
  filterStr?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProspectsByAccountIdResult = {
  __typename?: 'ProspectsByAccountIdResult';
  edges: Array<Prospect>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  AWPByAccountId?: Maybe<Array<Awp>>;
  /** @deprecated use awpByPhrId */
  AWPById?: Maybe<AwpFile>;
  account?: Maybe<AccountType>;
  /**
   * Account by Id field
   * This query is designed for only member to query Physician information.
   */
  accountById: AccountByIdResult;
  accounts?: Maybe<Array<Maybe<AccountType>>>;
  activeAffiliates?: Maybe<Array<Maybe<AffiliateWithContacts>>>;
  /** @deprecated use membersByAccountId */
  activeMembers?: Maybe<Array<Maybe<MemberType>>>;
  /** @deprecated use paymentProfilesList */
  activePaymentProfiles?: Maybe<Array<Maybe<PaymentProfile_Old>>>;
  /** @deprecated use patientActivityReport */
  activityReport?: Maybe<Array<Maybe<ReportActivityType>>>;
  /** @deprecated use physicianByContactId or physicianByAccountId */
  affiliateDetails?: Maybe<Array<Maybe<AffiliateType>>>;
  affiliateMembers?: Maybe<Array<Maybe<AffiliateType>>>;
  affiliateMessagingContactList?: Maybe<Array<Maybe<AffiliateContact>>>;
  /**
   * All accounts in the platform
   * TODO: The name is not following conventions - should be `accounts` after removing old one.
   */
  allAccounts: AccountsResult;
  /** @deprecated use paymentProfilesList */
  allPaymentProfiles?: Maybe<Array<Maybe<PaymentProfile_Old>>>;
  /**
   * All physicians in the platform
   * TODO: The name is not following conventions - should be `physicians` after removing old one.
   * @deprecated use allAccounts
   */
  allPhysicians: PhysiciansResult;
  allPracticeReport: AllPracticeReportResult;
  announcement?: Maybe<Announcements>;
  announcements?: Maybe<Array<Maybe<Announcements>>>;
  /** @deprecated use attachmentDataById */
  attachmentById?: Maybe<Scalars['String']['output']>;
  attachmentDataById: AttachmentDataByIdResult;
  /** @deprecated this service will be deleted because it doesn't exist in Secure Messaging backend */
  attachmentsByMessageId?: Maybe<Array<Maybe<AttachmentType>>>;
  /**
   * Feature flags needed for this mutation:
   * - AWP Create = Upload AWP readonly
   * - AWP Audit = Approve AWP
   * - AWP Manage = Edit AWP
   * - AWP Read = Read AWP
   */
  awpByPhrId: AwpByPhrIdResult;
  /** @deprecated use paymentProfilesList */
  bankAccountPaymentProfiles?: Maybe<Array<Maybe<PaymentProfile_Old>>>;
  /** @deprecated use membersByAccountId */
  byPhysicianId?: Maybe<Array<Maybe<MemberType>>>;
  campaignMembers?: Maybe<Array<Maybe<CampaignMemberType>>>;
  case?: Maybe<CaseType>;
  /** @deprecated use patientCaseReport */
  caseReport?: Maybe<Array<Maybe<ReportCaseType>>>;
  cases?: Maybe<Array<Maybe<CaseType>>>;
  /** @deprecated use paymentProfilesList */
  creditCardPaymentProfiles?: Maybe<Array<Maybe<PaymentProfile_Old>>>;
  /** @deprecated use defaultPaymentProfileForContact */
  defaultPaymentProfile?: Maybe<PaymentProfile_Old>;
  /** TODO: should be renamed to defaultPaymentProfile once old one is deprecated */
  defaultPaymentProfileForContact: DefaultPaymentProfileResult;
  donations?: Maybe<Array<Maybe<FoundationType>>>;
  event?: Maybe<CampaignType>;
  events?: Maybe<Array<Maybe<CampaignType>>>;
  f2FCasesByContactId?: Maybe<Array<Maybe<F2FCaseType>>>;
  faq?: Maybe<FaqType>;
  faqs?: Maybe<Array<Maybe<FaqType>>>;
  featureFlagByConnectUserId: FeatureFlagByConnectUserIdResult;
  featureFlagByContactId: FeatureFlagByContactIdResult;
  /** @deprecated use featureFlagByContactId */
  flagsByContactId?: Maybe<FeatureFlags>;
  /** @deprecated use featureFlagByConnectUserId */
  flagsByUserId?: Maybe<FeatureFlags>;
  /** @deprecated use myMailboxId */
  getMailboxId: Scalars['Int']['output'];
  getPDMByAccountId?: Maybe<User>;
  initiatives?: Maybe<Array<Maybe<InitiativesType>>>;
  /** @deprecated use mailboxById */
  mailbox: MailboxType;
  mailboxById: MailboxByIdResult;
  mdvipUserProfile: MdvipUserProfileResult;
  /** @deprecated use memberByContactId */
  member?: Maybe<MemberType>;
  /** Returns the existing member by ContactId */
  memberByContactId: MemberByContactIdResult;
  memberToDoNotifications: MemberToDoNotificationsResult;
  /** @deprecated use membersByAccountId */
  members?: Maybe<Array<Maybe<MemberType>>>;
  /** Returns all the existing members by AccountId with filters applied */
  membersByAccountId: MembersByAccountIdResult;
  messageThreadById: MessageThreadByIdResult;
  messageThreadsByMailboxId: MessageThreadsByMailboxIdResult;
  /** @deprecated this service will be deleted because it doesn't exist in Secure Messaging backend */
  messageUser: MessageUserType;
  /** @deprecated use messagesByMailboxThread */
  messages?: Maybe<Array<Maybe<MessageType>>>;
  messagesByMailboxThread: MessagesByMailboxThreadResult;
  /** @deprecated Use messagingContactsByContactId */
  messagingContactListForAffiliate?: Maybe<Array<Maybe<ContactListMember>>>;
  /** @deprecated Use messagingContactsByContactId */
  messagingContactListForMembers?: Maybe<Array<Maybe<ContactListMember>>>;
  /** @deprecated Use messagingContactsByContactId */
  messagingContactListOfficeStaff?: Maybe<Array<Maybe<ContactListMember>>>;
  /**
   * Returns all messaging contacts by ContactId and Type
   * @deprecated use messagingContactsForAffiliate or messagingContactsForAffiliate or messagingContactsForMember
   */
  messagingContactsByContactId: MessagingContactsByContactIdResult;
  /**
   * Returns the list of members and practice staff
   * who have active user record and access to SecureMessaging
   * of the affiliates in the same practice.
   */
  messagingContactsForAffiliate: MessagingContactsListResult;
  /**
   * Returns physician and the list of practice staff of affiliate
   * who have active user record and access to SecureMessaging
   * in the practice of the affiliate.
   */
  messagingContactsForEmployee: MessagingContactsListResult;
  /**
   * Returns the list of practice staff and affiliate
   * who have active user record and access to SecureMessaging
   * related to affiliate.
   */
  messagingContactsForMember: MessagingContactsListResult;
  /**
   * Returns the list of affiliates
   * who have active user record and access to SecureMesssaging
   * excluding the physician calling the api.
   */
  messagingContactsForPhysician: MessagingContactsListResult;
  /**
   * Returns the list of member, practice staff and affiliate
   * who have active user record and access to SecureMessaging
   * in the practice of the affiliate.
   */
  messagingContactsForStaff: MessagingContactsListResult;
  myMailboxId: MyMailboxIdResult;
  patientActivityReport: PatientActivityReportResult;
  patientCaseReport: PatientCaseReportResult;
  patientDirectoryReport: PatientDirectoryReportQuery;
  /**
   * Patient Account Invoice by Document Id field
   * This query is designed for only member to query Account Invoice information.
   */
  patientReceiptByDocumentId: PatientReceiptByDocumentIdResult;
  /** @deprecated use paymentById */
  payment?: Maybe<Payment_Old>;
  paymentById: PaymentByIdResult;
  paymentPreferences: PaymentPreferencesQuery;
  /** @deprecated use paymentProfileById */
  paymentProfile?: Maybe<PaymentProfile_Old>;
  paymentProfileById: PaymentProfileByIdResult;
  /** @deprecated use paymentProfilesList */
  paymentProfiles?: Maybe<Array<Maybe<PaymentProfile_Old>>>;
  /** TODO: should be renamed to `paymentProfiles` after old one is deprecated */
  paymentProfilesList: PaymentProfilesResult;
  /** @deprecated use litleErrorByCode, litleSettings or maximumPaymentAmount */
  paymentSettings?: Maybe<PaymentSettingsType>;
  /** @deprecated use paymentsList */
  payments?: Maybe<Array<Maybe<Payment_Old>>>;
  /** TODO: rename this to "payments" after the old one is deprecated and removed. */
  paymentsList: PaymentsResult;
  /** @deprecated Use 'accountById' or 'physicianByContactId' */
  physician?: Maybe<PhysicianType>;
  physicianAccounts?: Maybe<Array<Maybe<AccountType>>>;
  /**
   * Physician by AccountId field
   * TODO: Verify @authorization directive with consumers
   */
  physicianByAccountId: PhysicianByAccountIdResult;
  /**
   * Physician by ContactId field
   * TODO: Verify @authorization directive with consumers
   */
  physicianByContactId: PhysicianByContactIdResult;
  /** Get Physician Practice Staff by contactId */
  physicianPracticeStaffByContactId: PhysicianPracticeStaffByContactIdResult;
  /** Get all Physician Practice Staffs */
  physicianPracticeStaffs: PhysicianPracticeStaffsResult;
  /** @deprecated Use 'allAccounts' */
  physicians?: Maybe<Array<Maybe<PhysicianType>>>;
  /** @deprecated use practiceReportByFilePath */
  practiceReport?: Maybe<ReportPracticeType>;
  practiceReportByFilePath: PracticeReportByFilePathResult;
  /** @deprecated use prospectByContactId */
  prospect?: Maybe<ProspectType>;
  /** Propsect by Id field */
  prospectByContactId: ProspectByContactIdResult;
  /** @deprecated use prospectsByAccountId */
  prospects?: Maybe<Array<Maybe<ProspectType>>>;
  /** Prospects by AccountId field */
  prospectsByAccountId: ProspectsByAccountIdResult;
  recipe?: Maybe<RecipeType>;
  recipeList?: Maybe<RecipeListType>;
  reciprocityVisit?: Maybe<ReciprocityVisitType>;
  reciprocityVisits?: Maybe<Array<Maybe<ReciprocityVisitType>>>;
  /** All referral facilities by Account */
  referralFacilities: ReferralFacilitiesResult;
  referrals?: Maybe<Array<Maybe<ReferralType>>>;
  /** @deprecated use allPracticeReport */
  reportList?: Maybe<Array<Maybe<ReportList>>>;
  /**
   * Get an individual Staff by ID.
   * @deprecated use physicianPracticeStaffByContactId
   */
  staff?: Maybe<StaffType>;
  /**
   * Get all Staff.
   * @deprecated use physicianPracticeStaffs
   */
  staffs?: Maybe<Array<Maybe<StaffType>>>;
  /** @deprecated use messageThreadById */
  thread?: Maybe<ThreadType>;
  /** @deprecated use messageThreadsByMailboxId */
  threads?: Maybe<Array<Maybe<ThreadType>>>;
  todoNotifications: ToDoNotificationsResult;
  /** @deprecated use upcomingPaymentById */
  upcomingPayment?: Maybe<Payment_Old>;
  upcomingPaymentById: UpcomingPaymentByIdResult;
  /** @deprecated use upcomingPaymentsList */
  upcomingPayments?: Maybe<Array<Maybe<Payment_Old>>>;
  /** TODO: rename this to "upcomingPayments" after the old one is deprecated and removed. */
  upcomingPaymentsList: UpcomingPaymentsResult;
  waitlist?: Maybe<Array<Maybe<CaseType>>>;
};


export type QueryAwpByAccountIdArgs = {
  input: AwpByAccountIdInput;
};


export type QueryAwpByIdArgs = {
  PHR_ID: Scalars['String']['input'];
};


export type QueryAccountArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAccountByIdArgs = {
  Id: Scalars['String']['input'];
};


export type QueryActiveMembersArgs = {
  AccountId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryActivePaymentProfilesArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryActivityReportArgs = {
  input?: InputMaybe<ActivityReportInput>;
};


export type QueryAffiliateDetailsArgs = {
  AccountID?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAffiliateMembersArgs = {
  AccountID?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAffiliateMessagingContactListArgs = {
  ContactID?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllAccountsArgs = {
  input: AccountsInput;
};


export type QueryAllPhysiciansArgs = {
  input: PhysiciansInput;
};


export type QueryAllPracticeReportArgs = {
  physicianId: Scalars['String']['input'];
};


export type QueryAnnouncementArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAnnouncementsArgs = {
  type?: InputMaybe<Scalars['AnnouncementTypeScalar']['input']>;
};


export type QueryAttachmentByIdArgs = {
  input?: InputMaybe<AttachmentsByIdInput>;
};


export type QueryAttachmentDataByIdArgs = {
  input: AttachmentDataByIdInput;
};


export type QueryAttachmentsByMessageIdArgs = {
  input?: InputMaybe<AttachmentsByMessageIdInput>;
};


export type QueryAwpByPhrIdArgs = {
  phrId: Scalars['ID']['input'];
};


export type QueryByPhysicianIdArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCampaignMembersArgs = {
  AccountId?: InputMaybe<Scalars['String']['input']>;
  CampaignId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCaseArgs = {
  Id: Scalars['String']['input'];
};


export type QueryCaseReportArgs = {
  Id: Scalars['String']['input'];
};


export type QueryDonationsArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEventArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryEventsArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  filterStr?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryF2FCasesByContactIdArgs = {
  contactId: Scalars['String']['input'];
};


export type QueryFaqArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFaqsArgs = {
  category?: InputMaybe<Scalars['CategoryScalar']['input']>;
};


export type QueryFeatureFlagByConnectUserIdArgs = {
  connectUserId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFeatureFlagByContactIdArgs = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFlagsByContactIdArgs = {
  contactId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFlagsByUserIdArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPdmByAccountIdArgs = {
  accountId: Scalars['ID']['input'];
};


export type QueryInitiativesArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMailboxArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMailboxByIdArgs = {
  input: MailboxByIdInput;
};


export type QueryMdvipUserProfileArgs = {
  input: MdvipUserProfileInput;
};


export type QueryMemberArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMemberByContactIdArgs = {
  ContactId: Scalars['String']['input'];
};


export type QueryMemberToDoNotificationsArgs = {
  input: MemberToDoNotificationsInput;
};


export type QueryMembersArgs = {
  AccountId?: InputMaybe<Scalars['String']['input']>;
  Statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryMembersByAccountIdArgs = {
  AccountId: Scalars['String']['input'];
  input: MembersByAccountIdInput;
};


export type QueryMessageThreadByIdArgs = {
  input: MessageThreadByIdInput;
};


export type QueryMessageThreadsByMailboxIdArgs = {
  input: MessageThreadsByMailboxIdInput;
};


export type QueryMessageUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMessagesArgs = {
  input?: InputMaybe<MessagesInput>;
};


export type QueryMessagesByMailboxThreadArgs = {
  input: MessagesByMailboxThreadInput;
};


export type QueryMessagingContactListForAffiliateArgs = {
  ContactID?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMessagingContactListForMembersArgs = {
  ContactID?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMessagingContactListOfficeStaffArgs = {
  ContactID?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMessagingContactsByContactIdArgs = {
  ContactId: Scalars['String']['input'];
  Type: MessagingContactForType;
};


export type QueryMessagingContactsForAffiliateArgs = {
  input: MessagingContactsForAffiliateInput;
};


export type QueryMessagingContactsForEmployeeArgs = {
  input: MessagingContactsForEmployeeInput;
};


export type QueryMessagingContactsForMemberArgs = {
  input: MessagingContactsForMemberInput;
};


export type QueryMessagingContactsForPhysicianArgs = {
  input: MessagingContactsForPhysicianInput;
};


export type QueryMessagingContactsForStaffArgs = {
  input: MessagingContactsForStaffInput;
};


export type QueryMyMailboxIdArgs = {
  input?: InputMaybe<MyMailboxIdInput>;
};


export type QueryPatientActivityReportArgs = {
  input: PatientActivityReportInput;
};


export type QueryPatientCaseReportArgs = {
  AccountId: Scalars['String']['input'];
};


export type QueryPatientReceiptByDocumentIdArgs = {
  input: PatientReceiptByDocumentIdInput;
};


export type QueryPaymentArgs = {
  Id: Scalars['String']['input'];
};


export type QueryPaymentByIdArgs = {
  Id: Scalars['ID']['input'];
};


export type QueryPaymentProfileArgs = {
  Id: Scalars['ID']['input'];
};


export type QueryPaymentProfileByIdArgs = {
  Id: Scalars['ID']['input'];
};


export type QueryPaymentProfilesArgs = {
  mdvipId: Scalars['ID']['input'];
};


export type QueryPaymentProfilesListArgs = {
  input: PaymentProfilesInput;
};


export type QueryPaymentSettingsArgs = {
  input?: InputMaybe<PaymentSettingsInput>;
};


export type QueryPaymentsListArgs = {
  input: PaymentsInput;
};


export type QueryPhysicianArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPhysicianByAccountIdArgs = {
  AccountId: Scalars['String']['input'];
};


export type QueryPhysicianByContactIdArgs = {
  ContactId: Scalars['String']['input'];
};


export type QueryPhysicianPracticeStaffByContactIdArgs = {
  ContactId: Scalars['String']['input'];
};


export type QueryPhysicianPracticeStaffsArgs = {
  input: PhysicianPracticeStaffsInput;
};


export type QueryPracticeReportArgs = {
  filepath: Scalars['String']['input'];
};


export type QueryPracticeReportByFilePathArgs = {
  filepath: Scalars['String']['input'];
};


export type QueryProspectArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProspectByContactIdArgs = {
  ContactId: Scalars['ID']['input'];
};


export type QueryProspectsArgs = {
  AccountId: Scalars['String']['input'];
};


export type QueryProspectsByAccountIdArgs = {
  AccountId: Scalars['String']['input'];
  input: ProspectsByAccountIdInput;
};


export type QueryRecipeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRecipeListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReciprocityVisitArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReciprocityVisitsArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReferralFacilitiesArgs = {
  input: ReferralFacilitiesInput;
};


export type QueryReferralsArgs = {
  input?: InputMaybe<ReferralInput>;
};


export type QueryReportListArgs = {
  physicianId: Scalars['String']['input'];
};


export type QueryStaffArgs = {
  Id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryThreadArgs = {
  mailboxId: Scalars['ID']['input'];
  threadId: Scalars['ID']['input'];
};


export type QueryThreadsArgs = {
  input?: InputMaybe<GetAllThreadsInput>;
};


export type QueryTodoNotificationsArgs = {
  input: TodoNotificationsInput;
};


export type QueryUpcomingPaymentArgs = {
  Id: Scalars['String']['input'];
};


export type QueryUpcomingPaymentByIdArgs = {
  Id: Scalars['ID']['input'];
};


export type QueryUpcomingPaymentsListArgs = {
  input: UpcomingPaymentsInput;
};


export type QueryWaitlistArgs = {
  accountId: Scalars['String']['input'];
};

export type RecipeListType = {
  __typename?: 'RecipeListType';
  count?: Maybe<Scalars['Int']['output']>;
  recipes?: Maybe<Array<Maybe<RecipeType>>>;
};

export type RecipeType = {
  __typename?: 'RecipeType';
  field_dietary_restrictions?: Maybe<Array<Maybe<Scalars['RecipeCategory']['output']>>>;
  field_health_diet_considerations?: Maybe<Array<Maybe<Scalars['RecipeCategory']['output']>>>;
  field_main_ingredient?: Maybe<Array<Maybe<Scalars['RecipeCategory']['output']>>>;
  field_meal_course?: Maybe<Array<Maybe<Scalars['RecipeCategory']['output']>>>;
  field_preparation_technique?: Maybe<Array<Maybe<Scalars['RecipeCategory']['output']>>>;
  field_recipe_body?: Maybe<Scalars['String']['output']>;
  field_recipe_description_value?: Maybe<Scalars['String']['output']>;
  field_recipe_ingredients?: Maybe<Scalars['String']['output']>;
  field_recipe_nutrition?: Maybe<Scalars['String']['output']>;
  field_recipe_nutrition_bonus?: Maybe<Scalars['String']['output']>;
  field_recipe_prep_time?: Maybe<Scalars['String']['output']>;
  field_recipe_preparation?: Maybe<Scalars['String']['output']>;
  field_recipe_total_time?: Maybe<Scalars['String']['output']>;
  field_recipe_yield?: Maybe<Scalars['Int']['output']>;
  field_season_occasion?: Maybe<Array<Maybe<Scalars['RecipeCategory']['output']>>>;
  field_type_of_cuisine?: Maybe<Array<Maybe<Scalars['RecipeCategory']['output']>>>;
  field_type_of_dish?: Maybe<Array<Maybe<Scalars['RecipeCategory']['output']>>>;
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ReciprocityVisitType = {
  __typename?: 'ReciprocityVisitType';
  Account__c?: Maybe<Scalars['String']['output']>;
  DOB_Provided__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Patient_First_Name__c?: Maybe<Scalars['String']['output']>;
  Patient_Home_Phone_Provided__c?: Maybe<Scalars['String']['output']>;
  Patient_Last_Name__c?: Maybe<Scalars['String']['output']>;
  Reason_for_Visit__c?: Maybe<Scalars['String']['output']>;
  Referring_Physician__r?: Maybe<ReferringPhysicianType>;
  Visit_Date__c?: Maybe<Scalars['String']['output']>;
  formattedDob?: Maybe<Scalars['String']['output']>;
  formattedVisitDate?: Maybe<Scalars['String']['output']>;
};

export type ReferralFacilitiesInput = {
  AccountId: Scalars['String']['input'];
};

export type ReferralFacilitiesResult = {
  __typename?: 'ReferralFacilitiesResult';
  edges: Array<ReferralFacility>;
};

export type ReferralFacility = {
  __typename?: 'ReferralFacility';
  AccountId: Scalars['String']['output'];
  AccountName: Scalars['String']['output'];
};

export type ReferralInput = {
  AccountID?: InputMaybe<Scalars['String']['input']>;
};

export type ReferralType = {
  __typename?: 'ReferralType';
  AppointmentDate?: Maybe<Scalars['String']['output']>;
  ContactMobilePhone?: Maybe<Scalars['String']['output']>;
  ContactPhone?: Maybe<Scalars['String']['output']>;
  ContactSFID?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  FacilityName?: Maybe<Scalars['String']['output']>;
  PatientName?: Maybe<Scalars['String']['output']>;
  ReferralSFId?: Maybe<Scalars['String']['output']>;
  ReferralSpecialty?: Maybe<Scalars['String']['output']>;
  SpecialistReferrals?: Maybe<Array<SpecialistReferralType>>;
  Status?: Maybe<Scalars['String']['output']>;
};

export type ReferringPhysicianInput = {
  Id?: InputMaybe<Scalars['String']['input']>;
};

export type ReferringPhysicianType = {
  __typename?: 'ReferringPhysicianType';
  Name?: Maybe<Scalars['String']['output']>;
};

export type ReportActivityType = {
  __typename?: 'ReportActivityType';
  DOB?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MemberActivity?: Maybe<Array<Maybe<MemberActivityType>>>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  RecordTypeName?: Maybe<Scalars['String']['output']>;
};

export type ReportCaseType = {
  __typename?: 'ReportCaseType';
  ClosedDate?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  DOB?: Maybe<Scalars['String']['output']>;
  DateOfFacetoFace?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  RecordTypeName?: Maybe<Scalars['String']['output']>;
  Result?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
};

export type ReportList = {
  __typename?: 'ReportList';
  Created?: Maybe<Scalars['String']['output']>;
  File_Name?: Maybe<Scalars['String']['output']>;
  File_Type?: Maybe<Scalars['String']['output']>;
  Path?: Maybe<Scalars['String']['output']>;
  Physician_ID?: Maybe<Scalars['String']['output']>;
};

export type ReportPracticeType = {
  __typename?: 'ReportPracticeType';
  PHR?: Maybe<Scalars['String']['output']>;
};

export enum RequestTypeEnum {
  LitleInterfaceDetails = 'LitleInterfaceDetails',
  MaximumPaymentAmount = 'MaximumPaymentAmount',
  ResponseCodeMessage = 'ResponseCodeMessage'
}

export type ResponseCodeMessageType = {
  __typename?: 'ResponseCodeMessageType';
  PortalReponseMessage__c?: Maybe<Scalars['String']['output']>;
};

export type SendDynamicEmailInput = {
  bccAddress?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ccAddress?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  confEmail?: InputMaybe<Scalars['String']['input']>;
  confEmailEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  confEmailHtmlBody?: InputMaybe<Scalars['String']['input']>;
  confEmailPlainTextBody?: InputMaybe<Scalars['String']['input']>;
  confEmailSubject?: InputMaybe<Scalars['String']['input']>;
  fromEmail?: InputMaybe<Scalars['String']['input']>;
  htmlBody?: InputMaybe<Scalars['String']['input']>;
  plainTextBody?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  toEmail?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SendPaymentReceiptEmailByIdDomainError = SendPaymentReceiptEmailByIdFailedDomainError | SendPaymentReceiptEmailByIdNotFoundDomainError;

export type SendPaymentReceiptEmailByIdFailedDomainError = {
  __typename?: 'SendPaymentReceiptEmailByIdFailedDomainError';
  Id: Scalars['ID']['output'];
  contactId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type SendPaymentReceiptEmailByIdFailure = {
  __typename?: 'SendPaymentReceiptEmailByIdFailure';
  errors: Array<SendPaymentReceiptEmailByIdDomainError>;
};

export type SendPaymentReceiptEmailByIdNotFoundDomainError = {
  __typename?: 'SendPaymentReceiptEmailByIdNotFoundDomainError';
  Id: Scalars['ID']['output'];
  contactId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type SendPaymentReceiptEmailByIdResult = SendPaymentReceiptEmailByIdFailure | SendPaymentReceiptEmailByIdSuccess;

export type SendPaymentReceiptEmailByIdSuccess = {
  __typename?: 'SendPaymentReceiptEmailByIdSuccess';
  Id: Scalars['ID']['output'];
  email: Scalars['String']['output'];
};

export type SetDefaultPaymentProfileDomainError = SetDefaultPaymentProfileNotFoundDomainError;

export type SetDefaultPaymentProfileFailure = {
  __typename?: 'SetDefaultPaymentProfileFailure';
  errors: Array<SetDefaultPaymentProfileDomainError>;
};

export type SetDefaultPaymentProfileNotFoundDomainError = {
  __typename?: 'SetDefaultPaymentProfileNotFoundDomainError';
  Id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type SetDefaultPaymentProfileResult = PaymentProfile | SetDefaultPaymentProfileFailure;

export type SetupMyMailboxContactNotFoundError = DomainError & {
  __typename?: 'SetupMyMailboxContactNotFoundError';
  ContactId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type SetupMyMailboxDomainError = SetupMyMailboxContactNotFoundError | SetupMyMailboxMailboxNotFoundError;

export type SetupMyMailboxFailure = {
  __typename?: 'SetupMyMailboxFailure';
  /** All errors associated with required Attachment. */
  errors: Array<SetupMyMailboxDomainError>;
};

export type SetupMyMailboxMailboxNotFoundError = DomainError & {
  __typename?: 'SetupMyMailboxMailboxNotFoundError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type SetupMyMailboxResult = SetupMyMailboxFailure | SetupMyMailboxSuccess;

export type SetupMyMailboxSuccess = {
  __typename?: 'SetupMyMailboxSuccess';
  mailboxId: Scalars['ID']['output'];
};

export type SpecialistReferralInput = {
  Diagnosis__c?: InputMaybe<Scalars['String']['input']>;
  ICD10__c?: InputMaybe<Scalars['String']['input']>;
  Notes__c?: InputMaybe<Scalars['String']['input']>;
  Physician_Requested__c?: InputMaybe<Scalars['String']['input']>;
  Specialty__c?: InputMaybe<Scalars['String']['input']>;
};

export type SpecialistReferralType = {
  __typename?: 'SpecialistReferralType';
  AppointmentDate?: Maybe<Scalars['String']['output']>;
  AppointmentTime?: Maybe<Scalars['String']['output']>;
  SpecialistName?: Maybe<Scalars['String']['output']>;
  SpecialistPhone?: Maybe<Scalars['String']['output']>;
  SpecialistReferralSFId?: Maybe<Scalars['String']['output']>;
  SpecialistWebsite?: Maybe<Scalars['String']['output']>;
  Specialty?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
};

export type StaffDataTypeInput = {
  Connect_User_Status__c?: InputMaybe<Scalars['Boolean']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  MDVIP_Connect_User__c?: InputMaybe<Scalars['Boolean']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Phone?: InputMaybe<Scalars['String']['input']>;
  Salutation?: InputMaybe<Scalars['String']['input']>;
};

export type StaffType = ContactType & {
  __typename?: 'StaffType';
  AccountId: Scalars['String']['output'];
  Connect_User_Status__c?: Maybe<Scalars['Boolean']['output']>;
  Date_Of_Birth__c?: Maybe<Scalars['String']['output']>;
  Disposition_Code__c?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Emergency_Phone__c?: Maybe<Scalars['String']['output']>;
  Estimated_Paid_Through__c?: Maybe<Scalars['String']['output']>;
  Fax?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Gender__c?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['ID']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  Last_App_Login__c?: Maybe<Scalars['String']['output']>;
  MDVIP_Connect_User__c?: Maybe<Scalars['Boolean']['output']>;
  MailingCity?: Maybe<Scalars['String']['output']>;
  MailingPostalCode?: Maybe<Scalars['String']['output']>;
  MailingState?: Maybe<Scalars['String']['output']>;
  MailingStreet?: Maybe<Scalars['String']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingCity */
  Primary_City__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingState */
  Primary_State__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingStreet */
  Primary_Street_Address__c?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use MailingPostalCode */
  Primary_Zip_Postal_Code__c?: Maybe<Scalars['String']['output']>;
  Salutation?: Maybe<Scalars['String']['output']>;
  USLocalizedDateOfBirth?: Maybe<Scalars['String']['output']>;
  UserNameNotQualified__c?: Maybe<Scalars['String']['output']>;
  /** The Staff's corresponding Physician. */
  physician: PhysicianType;
  /** The ID of the Staff's corresponding Physician. */
  physicianId: Scalars['ID']['output'];
};

export type StateResponse = {
  __typename?: 'StateResponse';
  state?: Maybe<Scalars['String']['output']>;
};

export type SubmitReferralInput = {
  MCReferral?: InputMaybe<McReferralInput>;
  SpecialistReferral?: InputMaybe<Array<InputMaybe<SpecialistReferralInput>>>;
};

export type ThreadType = {
  __typename?: 'ThreadType';
  allowReply?: Maybe<Scalars['Boolean']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  broadcast?: Maybe<Scalars['Boolean']['output']>;
  hasAttachment?: Maybe<Scalars['Boolean']['output']>;
  hasDraft?: Maybe<Scalars['Boolean']['output']>;
  hasUnread?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  messageCount?: Maybe<Scalars['Int']['output']>;
  messages?: Maybe<Array<Maybe<MessageType>>>;
  name?: Maybe<Scalars['String']['output']>;
  participants?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  starred?: Maybe<Scalars['Boolean']['output']>;
  updatedTimestamp?: Maybe<Scalars['String']['output']>;
};

export type ToDoNotification = {
  __typename?: 'ToDoNotification';
  count: Scalars['Int']['output'];
  key: Scalars['ID']['output'];
  metadata: ToDoNotificationMetadata;
};

export type ToDoNotificationMetadata = {
  __typename?: 'ToDoNotificationMetadata';
  color: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type ToDoNotificationsResult = {
  __typename?: 'ToDoNotificationsResult';
  edges: Array<ToDoNotification>;
};

export type TodoNotificationsInput = {
  AccountId: Scalars['String']['input'];
  ContactId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Supported Keys:
   * - newMembers
   * - cancelledMembers
   * - reinstatedMembers
   * - futureCancellations
   * - recommendedOutreach
   * - pendingWellnessExams
   */
  keys?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum TransactionType {
  CreditMemo = 'Credit_Memo',
  Payment = 'Payment',
  PaymentReversal = 'Payment_Reversal',
  Refund = 'Refund'
}

export type TransferProspectToMdvipDomainError = TransferProspectToMdvipInvalidDataError;

export type TransferProspectToMdvipFailure = {
  __typename?: 'TransferProspectToMDVIPFailure';
  /** All errors associated while creating a prospect f2f case. */
  errors: Array<TransferProspectToMdvipDomainError>;
};

export type TransferProspectToMdvipInput = {
  /** AccountID of prospect */
  AccountID: Scalars['String']['input'];
  /** ContactID of prospect */
  ContactID: Scalars['String']['input'];
  /** Date of face to face appointment - Should follow 'MM/DD/YYYY' format */
  DateofFaceofFace?: InputMaybe<Scalars['String']['input']>;
  /** MeetingTime of prospect case */
  MeetingTime?: InputMaybe<Scalars['String']['input']>;
  /** PhysicianName of prospect */
  PhysicianName: Scalars['String']['input'];
  /** ProfileName of prospect */
  ProfileName: Scalars['String']['input'];
  /** Result of prospect case */
  Result?: InputMaybe<Scalars['String']['input']>;
  /** Status of prospect case */
  Status?: InputMaybe<ProspectF2FCaseStatus>;
  /** UserName of prospect */
  UserName: Scalars['String']['input'];
};

export type TransferProspectToMdvipInvalidDataError = DomainError & {
  __typename?: 'TransferProspectToMDVIPInvalidDataError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type TransferProspectToMdvipResult = TransferProspectToMdvipFailure | TransferProspectToMdvipSuccess;

export type TransferProspectToMdvipSuccess = {
  __typename?: 'TransferProspectToMDVIPSuccess';
  /** Success status of transfer result */
  status?: Maybe<TransferProspectToMdvipSuccessStatus>;
};

export enum TransferProspectToMdvipSuccessStatus {
  /** Means that Prospect has been successfully transferred to MDVIP */
  Success = 'SUCCESS'
}

export type TransferToMdvipInput = {
  AccountID?: InputMaybe<Scalars['String']['input']>;
  CaseID?: InputMaybe<Scalars['String']['input']>;
  ContactID?: InputMaybe<Scalars['String']['input']>;
  DateofFaceofFace?: InputMaybe<Scalars['String']['input']>;
  MeetingTime?: InputMaybe<Scalars['String']['input']>;
  PhysicianName?: InputMaybe<Scalars['String']['input']>;
  ProfileName?: InputMaybe<Scalars['String']['input']>;
  Result?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<ManageProspectCaseEnum>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

export type UpcomingPayment = {
  __typename?: 'UpcomingPayment';
  Amount__c?: Maybe<Scalars['Float']['output']>;
  Date_Processed__c?: Maybe<Scalars['String']['output']>;
  Id: Scalars['ID']['output'];
  MDVIPID__c?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Payment_Profile__c?: Maybe<Scalars['String']['output']>;
  Payment_Profile__r?: Maybe<PaymentProfile>;
  Payment_Type__c?: Maybe<Scalars['String']['output']>;
  Refund_Amount__c?: Maybe<Scalars['Float']['output']>;
  Refund_Status__c?: Maybe<UpcomingPaymentRefundStatus>;
  Schedule_Date__c?: Maybe<Scalars['String']['output']>;
  Status__c?: Maybe<UpcomingPaymentStatus>;
};

export type UpcomingPaymentByIdDomainError = UpcomingPaymentByIdNotFoundDomainError;

export type UpcomingPaymentByIdFailure = {
  __typename?: 'UpcomingPaymentByIdFailure';
  errors: Array<UpcomingPaymentByIdDomainError>;
};

export type UpcomingPaymentByIdNotFoundDomainError = {
  __typename?: 'UpcomingPaymentByIdNotFoundDomainError';
  Id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpcomingPaymentByIdResult = UpcomingPayment | UpcomingPaymentByIdFailure;

export enum UpcomingPaymentRefundStatus {
  Cleared = 'Cleared',
  Outstanding = 'Outstanding',
  Processing = 'Processing',
  Void = 'Void'
}

export enum UpcomingPaymentStatus {
  /** Canceled */
  Canceled = 'Canceled',
  /** Cancelled */
  Cancelled = 'Cancelled',
  /** ERROR! */
  Error = 'Error',
  /** Future Scheduled One Time Payment */
  FutureScheduledOneTimePayment = 'Future_Scheduled_One_Time_Payment',
  /** Processed - Charge Rejected */
  ProcessedChargeRejected = 'Processed_Charge_Rejected',
  /** Processed - Credit Card Declined */
  ProcessedCreditCardDeclined = 'Processed_Credit_Card_Declined',
  /** Processed - Successful */
  ProcessedSuccessful = 'Processed_Successful',
  /** Rescheduled - Charge Rejected */
  RescheduledChargeRejected = 'Rescheduled_Charge_Rejected',
  /** Set to be Processed */
  SetToBeProcessed = 'Set_To_Be_Processed'
}

export enum UpcomingPaymentStatusForUpdate {
  /** Canceled */
  Canceled = 'Canceled',
  /** Set to be Processed */
  SetToBeProcessed = 'Set_To_Be_Processed'
}

export type UpcomingPaymentsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UpcomingPaymentsResult = {
  __typename?: 'UpcomingPaymentsResult';
  edges: Array<UpcomingPayment>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/**
 * All the fields should be there
 * but "PHR" could be null if the image didn't change.
 */
export type UpdateAwpInput = {
  Date_of_Physical?: InputMaybe<Scalars['String']['input']>;
  MPower_ID?: InputMaybe<Scalars['String']['input']>;
  Member_ID?: InputMaybe<Scalars['String']['input']>;
  PHR?: InputMaybe<Scalars['String']['input']>;
  PHR_ID?: InputMaybe<Scalars['String']['input']>;
  PHR_Status?: InputMaybe<Scalars['String']['input']>;
  Physician_ID?: InputMaybe<Scalars['String']['input']>;
  Rejected_Reason?: InputMaybe<Scalars['String']['input']>;
  User?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAwpResponse = {
  __typename?: 'UpdateAWPResponse';
  state?: Maybe<Scalars['String']['output']>;
};

export type UpdateAccountInput = {
  AccountID?: InputMaybe<Scalars['String']['input']>;
  Friday_End_Time__c?: InputMaybe<Scalars['String']['input']>;
  Friday_Start_Time__c?: InputMaybe<Scalars['String']['input']>;
  Monday_End_Time__c?: InputMaybe<Scalars['String']['input']>;
  Monday_Start_Time__c?: InputMaybe<Scalars['String']['input']>;
  Saturday_End_Time__c?: InputMaybe<Scalars['String']['input']>;
  Saturday_Start_Time__c?: InputMaybe<Scalars['String']['input']>;
  Sunday_End_Time__c?: InputMaybe<Scalars['String']['input']>;
  Sunday_Start_Time__c?: InputMaybe<Scalars['String']['input']>;
  Thursday_End_Time__c?: InputMaybe<Scalars['String']['input']>;
  Thursday_Start_Time__c?: InputMaybe<Scalars['String']['input']>;
  Tuesday_End_Time__c?: InputMaybe<Scalars['String']['input']>;
  Tuesday_Start_Time__c?: InputMaybe<Scalars['String']['input']>;
  Wednesday_End_Time__c?: InputMaybe<Scalars['String']['input']>;
  Wednesday_Start_Time__c?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAwpByPhrIdDomainError = UpdateAwpByPhrIdFailedError | UpdateAwpByPhrIdFileNotFoundError;

export type UpdateAwpByPhrIdFailedError = DomainError & {
  __typename?: 'UpdateAwpByPhrIdFailedError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateAwpByPhrIdFailure = {
  __typename?: 'UpdateAwpByPhrIdFailure';
  errors: Array<UpdateAwpByPhrIdDomainError>;
};

export type UpdateAwpByPhrIdFileNotFoundError = DomainError & {
  __typename?: 'UpdateAwpByPhrIdFileNotFoundError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateAwpByPhrIdInput = {
  /** Username who creates AWP - i.e. physician full name */
  createdByName: Scalars['String']['input'];
  /** Formatted exam date string - 08/10/2023 or 2023-08-10 */
  examDate: Scalars['String']['input'];
  /** Member MDVIP Id */
  memberMdvipId: Scalars['ID']['input'];
  /** Physician id */
  physicianId: Scalars['ID']['input'];
  /** Rejected reason - This should be provided when uploadStatus is "Rejected" */
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  /** PHR status or AWP upload status */
  uploadStatus: WellnessExamUploadStatus;
  /** Same as CreateAWPResponse.Wellness_Exam_ID and AWP.Id */
  wellnessExamId: Scalars['ID']['input'];
};

export type UpdateAwpByPhrIdResponse = UpdateAwpByPhrIdFailure | UpdateAwpByPhrIdSuccess;

export type UpdateAwpByPhrIdSuccess = {
  __typename?: 'UpdateAwpByPhrIdSuccess';
  memberMdvipId: Scalars['ID']['output'];
  phrId: Scalars['ID']['output'];
  wellnessExamId: Scalars['ID']['output'];
};

export type UpdateCampaignMemberInput = {
  CampaignId?: InputMaybe<Scalars['String']['input']>;
  ContactId?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFeatureFlagByIdDomainError = UpdateFeatureFlagByIdNotFoundError | UpdateFeatureFlagByIdUnauthorizedError;

export type UpdateFeatureFlagByIdFailure = {
  __typename?: 'UpdateFeatureFlagByIdFailure';
  errors: Array<UpdateFeatureFlagByIdDomainError>;
};

export type UpdateFeatureFlagByIdInput = {
  AWPAudit__c?: InputMaybe<Scalars['Boolean']['input']>;
  AWPCreate__c?: InputMaybe<Scalars['Boolean']['input']>;
  AWPManage__c?: InputMaybe<Scalars['Boolean']['input']>;
  AWPPlus__c?: InputMaybe<Scalars['Boolean']['input']>;
  AWPRead__c?: InputMaybe<Scalars['Boolean']['input']>;
  AcceptingJuniorMembers__c?: InputMaybe<Scalars['Boolean']['input']>;
  AnalyticsReport__c?: InputMaybe<Scalars['Boolean']['input']>;
  ApparelandMerchandise__c?: InputMaybe<Scalars['Boolean']['input']>;
  CollateralandStationary__c?: InputMaybe<Scalars['Boolean']['input']>;
  EventsandMarketingMaterials__c?: InputMaybe<Scalars['Boolean']['input']>;
  FinancialReports__c?: InputMaybe<Scalars['Boolean']['input']>;
  Foundation__c?: InputMaybe<Scalars['Boolean']['input']>;
  MCEReferrals__c?: InputMaybe<Scalars['Boolean']['input']>;
  MDVIPWebmail__c?: InputMaybe<Scalars['Boolean']['input']>;
  ManageCHL__c?: InputMaybe<Scalars['Boolean']['input']>;
  ManageGroupPractice__c?: InputMaybe<Scalars['Boolean']['input']>;
  ManageOfficeHours__c?: InputMaybe<Scalars['Boolean']['input']>;
  ManagePayments__c?: InputMaybe<Scalars['Boolean']['input']>;
  ManagePortalUser__c?: InputMaybe<Scalars['Boolean']['input']>;
  MedicalResources__c?: InputMaybe<Scalars['Boolean']['input']>;
  MemberSatisfaction__c?: InputMaybe<Scalars['Boolean']['input']>;
  MyEvents__c?: InputMaybe<Scalars['Boolean']['input']>;
  MyInitiatives__c?: InputMaybe<Scalars['Boolean']['input']>;
  MyWaitinglist__c?: InputMaybe<Scalars['Boolean']['input']>;
  Notifications__c?: InputMaybe<Scalars['Boolean']['input']>;
  OfficeDepotOfficeMax__c?: InputMaybe<Scalars['Boolean']['input']>;
  Patients__c?: InputMaybe<Scalars['Boolean']['input']>;
  Programs__c?: InputMaybe<Scalars['Boolean']['input']>;
  Prospects__c?: InputMaybe<Scalars['Boolean']['input']>;
  ReciprocityVisit__c?: InputMaybe<Scalars['Boolean']['input']>;
  SecureMessaging__c?: InputMaybe<Scalars['Boolean']['input']>;
  Staplesdotcom__c?: InputMaybe<Scalars['Boolean']['input']>;
  ToDos__c?: InputMaybe<Scalars['Boolean']['input']>;
  ValueBasedCare__c?: InputMaybe<Scalars['Boolean']['input']>;
  WomensHealth__c?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateFeatureFlagByIdNotFoundError = DomainError & {
  __typename?: 'UpdateFeatureFlagByIdNotFoundError';
  Id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateFeatureFlagByIdResult = FeatureFlag | UpdateFeatureFlagByIdFailure;

export type UpdateFeatureFlagByIdUnauthorizedError = DomainError & {
  __typename?: 'UpdateFeatureFlagByIdUnauthorizedError';
  Id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type UpdateFeatureFlagsInput = {
  flags?: InputMaybe<FeatureFlagsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMailboxByIdAwayMessageMissingError = DomainError & {
  __typename?: 'UpdateMailboxByIdAwayMessageMissingError';
  awayMessage?: Maybe<Scalars['String']['output']>;
  awayStatus: Scalars['Boolean']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateMailboxByIdDomainError = UpdateMailboxByIdAwayMessageMissingError | UpdateMailboxByIdInvalidMailboxIdError | UpdateMailboxByIdNotFoundError;

export type UpdateMailboxByIdFailure = {
  __typename?: 'UpdateMailboxByIdFailure';
  /** All errors associated with required Attachment. */
  errors: Array<UpdateMailboxByIdDomainError>;
};

export type UpdateMailboxByIdInput = {
  awayMessage?: InputMaybe<Scalars['String']['input']>;
  awayStatus: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateMailboxByIdInvalidMailboxIdError = DomainError & {
  __typename?: 'UpdateMailboxByIdInvalidMailboxIdError';
  id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateMailboxByIdNotFoundError = DomainError & {
  __typename?: 'UpdateMailboxByIdNotFoundError';
  id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateMailboxByIdResult = Mailbox | UpdateMailboxByIdFailure;

export type UpdateMailboxInput = {
  awayMessage?: InputMaybe<Scalars['String']['input']>;
  awayStatus?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};

export type UpdateMemberByContactIdDomainError = UpdateMemberByContactIdInvalidContactIdError;

export type UpdateMemberByContactIdFailure = {
  __typename?: 'UpdateMemberByContactIdFailure';
  /** All errors associated with required Member By Id. */
  errors: Array<UpdateMemberByContactIdDomainError>;
};

export type UpdateMemberByContactIdInput = {
  AmountMSRP__c?: InputMaybe<Scalars['Int']['input']>;
  AutoPay__c?: InputMaybe<Scalars['Boolean']['input']>;
  Best_Method_Of_Communication__c?: InputMaybe<Scalars['String']['input']>;
  CancellationStatistics__c?: InputMaybe<Scalars['Boolean']['input']>;
  Connect_User_Status__c?: InputMaybe<Scalars['Boolean']['input']>;
  Current_Agreement_Payment_Schedule__c?: InputMaybe<Scalars['String']['input']>;
  Current_Balance_Formula__c?: InputMaybe<Scalars['String']['input']>;
  Current_Balance__c?: InputMaybe<Scalars['Float']['input']>;
  Current_Membership_Type__c?: InputMaybe<Scalars['String']['input']>;
  /**
   * Date_Of_Birth__c of a member
   * Format: YYYY-MM-DD
   */
  Date_Of_Birth__c?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  /**
   * Emergency_Phone__c of a member
   * Format: (XXX) XXX-XXXX
   */
  Emergency_Phone__c?: InputMaybe<Scalars['String']['input']>;
  Employer__c?: InputMaybe<Scalars['String']['input']>;
  Established_Date__c?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fax of a member
   * Format: (XXX) XXX-XXXX
   */
  Fax?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender__c?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  Last_App_Login__c?: InputMaybe<Scalars['String']['input']>;
  Last_Payment_Date__c?: InputMaybe<Scalars['String']['input']>;
  Last_Wellness_Exam__c?: InputMaybe<Scalars['String']['input']>;
  LeadSource?: InputMaybe<Scalars['String']['input']>;
  MDVIPID__c?: InputMaybe<Scalars['String']['input']>;
  MDVIP_Connect_User__c?: InputMaybe<Scalars['Boolean']['input']>;
  MFMailboxID__c?: InputMaybe<Scalars['String']['input']>;
  MailingCity?: InputMaybe<Scalars['String']['input']>;
  MailingPostalCode?: InputMaybe<Scalars['String']['input']>;
  MailingState?: InputMaybe<Scalars['String']['input']>;
  MailingStreet?: InputMaybe<Scalars['String']['input']>;
  Member_Status__c?: InputMaybe<Scalars['String']['input']>;
  /**
   * MobilePhone of a member
   * Format: (XXX) XXX-XXXX
   */
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Next_Renewal_Date_Formula__c?: InputMaybe<Scalars['String']['input']>;
  OtherCity?: InputMaybe<Scalars['String']['input']>;
  OtherPostalCode?: InputMaybe<Scalars['String']['input']>;
  OtherState?: InputMaybe<Scalars['String']['input']>;
  OtherStreet?: InputMaybe<Scalars['String']['input']>;
  /**
   * Phone of a member
   * Format: (XXX) XXX-XXXX
   */
  Phone?: InputMaybe<Scalars['String']['input']>;
  /** User preference to enable push notifications for new secure messages */
  Push_Notifications__c?: InputMaybe<Scalars['Boolean']['input']>;
  Referral_Name__c?: InputMaybe<Scalars['String']['input']>;
  /** User preference to enable email notifications for new secure messages */
  SM_Email_Notification__c?: InputMaybe<Scalars['Boolean']['input']>;
  Salutation?: InputMaybe<Scalars['String']['input']>;
  Secondary_Insurance__c?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Unknown_Insurance_Company_Name__c?: InputMaybe<Scalars['String']['input']>;
  Work_Ext__c?: InputMaybe<Scalars['String']['input']>;
  Work_Phone__c?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMemberByContactIdInvalidContactIdError = DomainError & {
  __typename?: 'UpdateMemberByContactIdInvalidContactIdError';
  /** ContactId that was included in the query */
  ContactId: Scalars['String']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateMemberByContactIdResult = Member | UpdateMemberByContactIdFailure;

export type UpdateMessageByIdDomainError = UpdateMessageByIdInvalidIdError | UpdateMessageByIdInvalidMailboxIdError | UpdateMessageByIdInvalidThreadIdError | UpdateMessageByIdNotFoundError;

export type UpdateMessageByIdFailure = {
  __typename?: 'UpdateMessageByIdFailure';
  /** All errors associated with required Attachment. */
  errors: Array<UpdateMessageByIdDomainError>;
};

export type UpdateMessageByIdInput = {
  attachments: Array<UpdateMessageByIdInputAttachment>;
  bcc?: InputMaybe<Array<UpdateMessageByIdInputRecipient>>;
  body: Scalars['String']['input'];
  cc?: InputMaybe<Array<UpdateMessageByIdInputRecipient>>;
  draft: Scalars['Boolean']['input'];
  from: UpdateMessageByIdInputSender;
  id: Scalars['ID']['input'];
  mailboxId: Scalars['ID']['input'];
  read?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  threadId: Scalars['ID']['input'];
  to: Array<UpdateMessageByIdInputRecipient>;
};

export type UpdateMessageByIdInputAttachment = {
  createdTimestamp: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateMessageByIdInputRecipient = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateMessageByIdInputSender = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateMessageByIdInvalidIdError = DomainError & {
  __typename?: 'UpdateMessageByIdInvalidIdError';
  id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateMessageByIdInvalidMailboxIdError = DomainError & {
  __typename?: 'UpdateMessageByIdInvalidMailboxIdError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateMessageByIdInvalidThreadIdError = DomainError & {
  __typename?: 'UpdateMessageByIdInvalidThreadIdError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type UpdateMessageByIdNotFoundError = DomainError & {
  __typename?: 'UpdateMessageByIdNotFoundError';
  id: Scalars['ID']['output'];
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type UpdateMessageByIdResult = UpdateMessageByIdFailure | UpdateMessageByIdSuccess;

export type UpdateMessageByIdSuccess = {
  __typename?: 'UpdateMessageByIdSuccess';
  id: Scalars['ID']['output'];
  mailboxId: Scalars['ID']['output'];
  threadId: Scalars['ID']['output'];
};

export type UpdateMessageInput = {
  attachments?: InputMaybe<Array<InputMaybe<CreateMessageAttachmentInput>>>;
  bcc?: InputMaybe<Array<InputMaybe<CreateMessageUserInput>>>;
  body?: InputMaybe<Scalars['String']['input']>;
  cc?: InputMaybe<Array<InputMaybe<CreateMessageUserInput>>>;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<CreateMessageUserInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  mailboxId?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  threadId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Array<InputMaybe<CreateMessageUserInput>>>;
};

export type UpdateMessageThreadByIdDomainError = UpdateMessageThreadByIdInvalidIdError | UpdateMessageThreadByIdInvalidMailboxIdError | UpdateMessageThreadByIdNotFoundError;

export type UpdateMessageThreadByIdFailure = {
  __typename?: 'UpdateMessageThreadByIdFailure';
  /** All errors associated with required Attachment. */
  errors: Array<UpdateMessageThreadByIdDomainError>;
};

export type UpdateMessageThreadByIdInput = {
  allowReply: Scalars['Boolean']['input'];
  archived: Scalars['Boolean']['input'];
  broadcast: Scalars['Boolean']['input'];
  hasAttachment: Scalars['Boolean']['input'];
  hasDraft: Scalars['Boolean']['input'];
  hasUnread: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  mailboxId: Scalars['ID']['input'];
  messageCount: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  participants: Array<Scalars['String']['input']>;
  starred: Scalars['Boolean']['input'];
};

export type UpdateMessageThreadByIdInvalidIdError = DomainError & {
  __typename?: 'UpdateMessageThreadByIdInvalidIdError';
  id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateMessageThreadByIdInvalidMailboxIdError = DomainError & {
  __typename?: 'UpdateMessageThreadByIdInvalidMailboxIdError';
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateMessageThreadByIdNotFoundError = DomainError & {
  __typename?: 'UpdateMessageThreadByIdNotFoundError';
  id: Scalars['ID']['output'];
  mailboxId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateMessageThreadByIdResult = UpdateMessageThreadByIdFailure | UpdateMessageThreadByIdSuccess;

export type UpdateMessageThreadByIdSuccess = {
  __typename?: 'UpdateMessageThreadByIdSuccess';
  thread: MessageThread;
};

export type UpdatePaymentInput = {
  Amount__c: Scalars['String']['input'];
  Id: Scalars['String']['input'];
  Payment_Profile__c: Scalars['String']['input'];
  Schedule_Date__c: Scalars['String']['input'];
  Type__c: Scalars['String']['input'];
};

export type UpdatePaymentProfileByIdAchInput = {
  ACHBankName__c?: InputMaybe<Scalars['String']['input']>;
  ACH_Name_of_Account__c?: InputMaybe<PaymentProfileAchAccountType>;
  Name_on_Card__c?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePaymentProfileByIdCreditCardInput = {
  Card_Type__c?: InputMaybe<PaymentProfileCardType>;
  Expiration_Month__c?: InputMaybe<Scalars['String']['input']>;
  Expiration_Year__c?: InputMaybe<Scalars['String']['input']>;
  Name_on_Card__c?: InputMaybe<Scalars['String']['input']>;
  PaymentType__c?: InputMaybe<PaymentProfilePaymentType>;
};

export type UpdatePaymentProfileByIdDomainError = UpdatePaymentProfileByIdInvalidInputDomainError | UpdatePaymentProfileByIdNotFoundDomainError;

export type UpdatePaymentProfileByIdFailure = {
  __typename?: 'UpdatePaymentProfileByIdFailure';
  errors: Array<UpdatePaymentProfileByIdDomainError>;
};

export type UpdatePaymentProfileByIdInput = {
  /** ACH information to update existing payment profile */
  ACHInfo?: InputMaybe<UpdatePaymentProfileByIdAchInput>;
  Address_1__c?: InputMaybe<Scalars['String']['input']>;
  Address_2__c?: InputMaybe<Scalars['String']['input']>;
  City__c?: InputMaybe<Scalars['String']['input']>;
  /** Credit Card information to update existing payment profile */
  CreditCardInfo?: InputMaybe<UpdatePaymentProfileByIdCreditCardInput>;
  Default__c?: InputMaybe<Scalars['Boolean']['input']>;
  Expired__c?: InputMaybe<Scalars['String']['input']>;
  HSACard__c?: InputMaybe<PaymentProfileHsaCard>;
  States__c?: InputMaybe<Scalars['String']['input']>;
  Zipcode__c?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePaymentProfileByIdInvalidInputDomainError = {
  __typename?: 'UpdatePaymentProfileByIdInvalidInputDomainError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdatePaymentProfileByIdNotFoundDomainError = {
  __typename?: 'UpdatePaymentProfileByIdNotFoundDomainError';
  Id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdatePaymentProfileByIdResult = PaymentProfile | UpdatePaymentProfileByIdFailure;

export type UpdatePaymentProfileInput = {
  ACHBankName__c?: InputMaybe<Scalars['String']['input']>;
  ACH_Account_Type__c?: InputMaybe<Scalars['String']['input']>;
  ACH_Name_of_Account__c?: InputMaybe<Scalars['String']['input']>;
  Address_1__c?: InputMaybe<Scalars['String']['input']>;
  Address_2__c?: InputMaybe<Scalars['String']['input']>;
  City__c?: InputMaybe<Scalars['String']['input']>;
  Default__c?: InputMaybe<Scalars['Boolean']['input']>;
  Expiration_Month__c?: InputMaybe<Scalars['String']['input']>;
  Expiration_Year__c?: InputMaybe<Scalars['String']['input']>;
  Expired__c?: InputMaybe<Scalars['String']['input']>;
  HSACard__c?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['String']['input'];
  Name_on_Card__c?: InputMaybe<Scalars['String']['input']>;
  PaymentType__c?: InputMaybe<PaymentTypeEnum>;
  States__c?: InputMaybe<Scalars['String']['input']>;
  Zipcode__c?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePhysicianByContactIdDomainError = UpdatePhysicianByContactIdInvalidContactIdError;

/** A domain-specific failure to update an existing physician by contactId */
export type UpdatePhysicianByContactIdFailure = {
  __typename?: 'UpdatePhysicianByContactIdFailure';
  /** All errors associated while updating an existing physician by contactId */
  errors: Array<UpdatePhysicianByContactIdDomainError>;
};

export type UpdatePhysicianByContactIdInput = {
  /**
   * Date of Birth of a physician
   * Format: YYYY-MM-DD
   */
  Date_Of_Birth__c?: InputMaybe<Scalars['String']['input']>;
  /** Email of a physician */
  Email?: InputMaybe<Scalars['String']['input']>;
  /**
   * Emergency Phone of a physician
   * Format: (XXX) XXX-XXXX
   */
  Emergency_Phone__c?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fax of a physician
   * Format: (XXX) XXX-XXXX
   */
  Fax?: InputMaybe<Scalars['String']['input']>;
  /** First name of a physician */
  FirstName?: InputMaybe<Scalars['String']['input']>;
  /** Gender of a physician */
  Gender__c?: InputMaybe<Scalars['String']['input']>;
  /** Last name of a physician */
  LastName?: InputMaybe<Scalars['String']['input']>;
  /** Mailing city of a physician */
  MailingCity?: InputMaybe<Scalars['String']['input']>;
  /** Mailing postal code of a physician */
  MailingPostalCode?: InputMaybe<Scalars['String']['input']>;
  /** Mailing state of a physician */
  MailingState?: InputMaybe<Scalars['String']['input']>;
  /** Mailing street of a physician */
  MailingStreet?: InputMaybe<Scalars['String']['input']>;
  /**
   * Mobile phone of a physician
   * Format: (XXX) XXX-XXXX
   */
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  /**
   * Phone number of a physician
   * Format: (XXX) XXX-XXXX
   */
  Phone?: InputMaybe<Scalars['String']['input']>;
  /** User preference to enable push notifications for new secure messages */
  Push_Notifications__c?: InputMaybe<Scalars['Boolean']['input']>;
  /** User preference to enable email notifications for new secure messages */
  SM_Email_Notification__c?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Salutation of a physician
   * i.e. Mr, Mrs, ...
   */
  Salutation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePhysicianByContactIdInvalidContactIdError = DomainError & {
  __typename?: 'UpdatePhysicianByContactIdInvalidContactIdError';
  /** ContactId field that was in the input */
  ContactId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdatePhysicianByContactIdResult = Physician | UpdatePhysicianByContactIdFailure;

export enum UpdatePhysicianPracticeStaffAccessActionType {
  /** Toggle PhysicianPracticestaff's portal access */
  DeactivateUser = 'DEACTIVATE_USER',
  /** Re-activates  */
  ReactivateUser = 'REACTIVATE_USER',
  /**
   * Toggle PhysicianPracticestaff's portal access
   * - If it's enabled, this mutation disables its access.
   * - If it's disabled, this mutation enables its access.
   */
  TogglePortalAccess = 'TOGGLE_PORTAL_ACCESS'
}

export type UpdatePhysicianPracticeStaffAccessByContactIdDomainError = UpdatePhysicianPracticeStaffAccessByContactIdInvalidDataError;

export type UpdatePhysicianPracticeStaffAccessByContactIdFailure = {
  __typename?: 'UpdatePhysicianPracticeStaffAccessByContactIdFailure';
  /** All errors associated with failure. */
  errors: Array<UpdatePhysicianPracticeStaffAccessByContactIdDomainError>;
};

export type UpdatePhysicianPracticeStaffAccessByContactIdInput = {
  /** Access type of PhysicianPracticeStaff to update to */
  Type: UpdatePhysicianPracticeStaffAccessActionType;
  /** AccountId or ContactId of Super Admin */
  UserId: Scalars['String']['input'];
};

/** Invalid Data domain error */
export type UpdatePhysicianPracticeStaffAccessByContactIdInvalidDataError = DomainError & {
  __typename?: 'UpdatePhysicianPracticeStaffAccessByContactIdInvalidDataError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
  /** A human-friendly error reason. */
  reason: Scalars['String']['output'];
};

export type UpdatePhysicianPracticeStaffAccessByContactIdResult = UpdatePhysicianPracticeStaffAccessByContactIdFailure | UpdatePhysicianPracticeStaffAccessByContactIdSuccessResult;

export type UpdatePhysicianPracticeStaffAccessByContactIdSuccessResult = {
  __typename?: 'UpdatePhysicianPracticeStaffAccessByContactIdSuccessResult';
  status: UpdatePhysicianPracticeStaffAccessByContactIdSuccessStatus;
};

export enum UpdatePhysicianPracticeStaffAccessByContactIdSuccessStatus {
  Success = 'SUCCESS'
}

export type UpdatePhysicianPracticeStaffByContactIdDomainError = UpdatePhysicianPracticeStaffByContactIdInvalidContactIdError;

export type UpdatePhysicianPracticeStaffByContactIdFailure = {
  __typename?: 'UpdatePhysicianPracticeStaffByContactIdFailure';
  /** All errors associated while creating a prospect. */
  errors: Array<UpdatePhysicianPracticeStaffByContactIdDomainError>;
};

export type UpdatePhysicianPracticeStaffByContactIdInput = {
  Connect_User_Status__c?: InputMaybe<Scalars['Boolean']['input']>;
  /** YYYY-MM-DD formatted date */
  Date_Of_Birth__c?: InputMaybe<Scalars['String']['input']>;
  /** Email of the Physician Practice Staff */
  Email?: InputMaybe<Scalars['String']['input']>;
  /** Emergency Phone of the Physician Practice Staff */
  Emergency_Phone__c?: InputMaybe<Scalars['String']['input']>;
  /** Fax of the Physician Practice Staff */
  Fax?: InputMaybe<Scalars['String']['input']>;
  /** First name of the Physician Practice Staff */
  FirstName?: InputMaybe<Scalars['String']['input']>;
  /** Gender of the new Physician Practice Staff */
  Gender__c?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the Physician Practice Staff */
  LastName?: InputMaybe<Scalars['String']['input']>;
  MDVIP_Connect_User__c?: InputMaybe<Scalars['Boolean']['input']>;
  /** MailingCity of the new Physician Practice Staff */
  MailingCity?: InputMaybe<Scalars['String']['input']>;
  /** MailingPostalCode of the new Physician Practice Staff */
  MailingPostalCode?: InputMaybe<Scalars['String']['input']>;
  /** MailingState of the new Physician Practice Staff */
  MailingState?: InputMaybe<Scalars['String']['input']>;
  /** MailingStreet of the new Physician Practice Staff */
  MailingStreet?: InputMaybe<Scalars['String']['input']>;
  /** MobilePhone of the Physician Practice Staff */
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  /** Phone of the Physician Practice Staff */
  Phone?: InputMaybe<Scalars['String']['input']>;
  /** User preference to enable push notifications for new secure messages */
  Push_Notifications__c?: InputMaybe<Scalars['Boolean']['input']>;
  /** User preference to enable email notifications for new secure messages */
  SM_Email_Notification__c?: InputMaybe<Scalars['Boolean']['input']>;
  /** Salutation of the Physician Practice Staff */
  Salutation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePhysicianPracticeStaffByContactIdInvalidContactIdError = DomainError & {
  __typename?: 'UpdatePhysicianPracticeStaffByContactIdInvalidContactIdError';
  /** ContactId that was included in the query */
  ContactId: Scalars['String']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdatePhysicianPracticeStaffByContactIdResult = PhysicianPracticeStaff | UpdatePhysicianPracticeStaffByContactIdFailure;

export type UpdateProspectByContactIdDomainError = UpdateProspectByContactIdInvalidContactIdError;

/** A domain-specific failure to update an existing prospect by contactId. */
export type UpdateProspectByContactIdFailure = {
  __typename?: 'UpdateProspectByContactIdFailure';
  /** All errors associated while updating an existing prospect by contactId. */
  errors: Array<UpdateProspectByContactIdDomainError>;
};

export type UpdateProspectByContactIdInput = {
  /** Date_Of_Birth__c of the prospect contact */
  Date_Of_Birth__c?: InputMaybe<Scalars['String']['input']>;
  /** Email of the prospect contact */
  Email?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fax from Contact record
   * Format: (XXX) XXX-XXXX
   */
  Fax?: InputMaybe<Scalars['String']['input']>;
  /** FirstName of the prospect contact */
  FirstName?: InputMaybe<Scalars['String']['input']>;
  /** Gender__c of the prospect contact */
  Gender__c?: InputMaybe<Scalars['String']['input']>;
  /** LastName of the prospect contact */
  LastName?: InputMaybe<Scalars['String']['input']>;
  /** LeadSource of the prospect contact */
  LeadSource?: InputMaybe<Scalars['String']['input']>;
  /** MDVIPID__c of the prospect contact */
  MDVIPID__c?: InputMaybe<Scalars['String']['input']>;
  /** MailingCity of the prospect contact */
  MailingCity?: InputMaybe<Scalars['String']['input']>;
  /** MailingPostalCode of the prospect contact */
  MailingPostalCode?: InputMaybe<Scalars['String']['input']>;
  /** MailingState of the prospect contact */
  MailingState?: InputMaybe<Scalars['String']['input']>;
  /** MailingStreet of the prospect contact */
  MailingStreet?: InputMaybe<Scalars['String']['input']>;
  /**
   * MobilePhone of the prospect contact
   * Format: (XXX) XXX-XXXX
   */
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  /**
   * Phone of the prospect contact
   * Format: (XXX) XXX-XXXX
   */
  Phone?: InputMaybe<Scalars['String']['input']>;
  /** Referral_Name__c of the prospect contact */
  Referral_Name__c?: InputMaybe<Scalars['String']['input']>;
  /** Salutation of the prospect contact */
  Salutation?: InputMaybe<Scalars['String']['input']>;
  /** Unknown_Insurance_Company_Name__c of the prospect contact */
  Unknown_Insurance_Company_Name__c?: InputMaybe<Scalars['String']['input']>;
  /** UserNameNotQualified__c of the prospect contact */
  UserNameNotQualified__c?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProspectByContactIdInvalidContactIdError = DomainError & {
  __typename?: 'UpdateProspectByContactIdInvalidContactIdError';
  /** ContactId field that was in the input */
  ContactId: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateProspectByContactIdResult = Prospect | UpdateProspectByContactIdFailure;

export type UpdateProspectF2FCaseByCaseIdDomainError = UpdateProspectF2FCaseByCaseIdInvalidDataError;

export type UpdateProspectF2FCaseByCaseIdFailure = {
  __typename?: 'UpdateProspectF2FCaseByCaseIdFailure';
  /** All errors associated while creating a prospect f2f case. */
  errors: Array<UpdateProspectF2FCaseByCaseIdDomainError>;
};

export type UpdateProspectF2FCaseByCaseIdInput = {
  /** Date of face to face appointment - Should follow 'MM/DD/YYYY' format */
  DateofFaceofFace?: InputMaybe<Scalars['String']['input']>;
  /** Meeting time of face to face appointment */
  MeetingTime?: InputMaybe<Scalars['String']['input']>;
  /** ProfileName of face to face appointment */
  ProfileName: Scalars['String']['input'];
  /** Result of face to face appointment */
  Result?: InputMaybe<Scalars['String']['input']>;
  /** Status of face to face appointment */
  Status: ProspectF2FCaseStatus;
  /** UserName of face to face appointment */
  UserName: Scalars['String']['input'];
};

export type UpdateProspectF2FCaseByCaseIdInvalidDataError = DomainError & {
  __typename?: 'UpdateProspectF2FCaseByCaseIdInvalidDataError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateProspectF2FCaseByCaseIdResult = UpdateProspectF2FCaseByCaseIdFailure | UpdateProspectF2FCaseByCaseIdSuccess;

export type UpdateProspectF2FCaseByCaseIdSuccess = {
  __typename?: 'UpdateProspectF2FCaseByCaseIdSuccess';
  /** Success status of creating a new prospect f2f case */
  status?: Maybe<UpdateProspectF2FCaseByCaseIdSuccessStatus>;
};

export enum UpdateProspectF2FCaseByCaseIdSuccessStatus {
  /** Means the Prospect F2F case successfully updated */
  Success = 'SUCCESS'
}

export type UpdateThreadInput = {
  allowReply?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  broadcast?: InputMaybe<Scalars['Boolean']['input']>;
  hasAttachment?: InputMaybe<Scalars['Boolean']['input']>;
  hasDraft?: InputMaybe<Scalars['Boolean']['input']>;
  hasUnread?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mailBoxId?: InputMaybe<Scalars['String']['input']>;
  messageCount?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  participants?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateUpcomingPaymentByIdDomainError = UpdateUpcomingPaymentByIdNotFoundDomainError;

export type UpdateUpcomingPaymentByIdFailure = {
  __typename?: 'UpdateUpcomingPaymentByIdFailure';
  errors: Array<UpdateUpcomingPaymentByIdDomainError>;
};

export type UpdateUpcomingPaymentByIdInput = {
  Amount__c?: InputMaybe<Scalars['Float']['input']>;
  Payment_Profile__c?: InputMaybe<Scalars['String']['input']>;
  Schedule_Date__c?: InputMaybe<Scalars['String']['input']>;
  Status__c?: InputMaybe<UpcomingPaymentStatusForUpdate>;
  Type__c?: InputMaybe<PaymentType>;
};

export type UpdateUpcomingPaymentByIdNotFoundDomainError = {
  __typename?: 'UpdateUpcomingPaymentByIdNotFoundDomainError';
  Id: Scalars['ID']['output'];
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type UpdateUpcomingPaymentByIdResult = UpcomingPayment | UpdateUpcomingPaymentByIdFailure;

export type User = {
  __typename?: 'User';
  Avatar: UserAvatar;
  Email: Scalars['String']['output'];
  Id: Scalars['ID']['output'];
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  Phone?: Maybe<Scalars['String']['output']>;
  Role: Scalars['String']['output'];
};

export type UserAvatar = {
  __typename?: 'UserAvatar';
  Large: Scalars['String']['output'];
  Medium: Scalars['String']['output'];
  Thumbnail: Scalars['String']['output'];
};

export enum UserType {
  Employee = 'EMPLOYEE',
  ExpressPay = 'EXPRESS_PAY',
  Member = 'MEMBER',
  Physician = 'PHYSICIAN',
  PhysicianPracticeStaff = 'PHYSICIAN_PRACTICE_STAFF'
}

export type VerifyAchInformationDomainError = DomainError & {
  __typename?: 'VerifyACHInformationDomainError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type VerifyAchInformationFailure = {
  __typename?: 'VerifyACHInformationFailure';
  errors: Array<VerifyAchInformationDomainError>;
};

export type VerifyAchInformationInput = {
  AccountNumber: Scalars['String']['input'];
  RoutingNumber: Scalars['String']['input'];
};

export type VerifyAchInformationResult = VerifyAchInformationFailure | VerifyAchInformationSuccess;

export type VerifyAchInformationSuccess = {
  __typename?: 'VerifyACHInformationSuccess';
  authTransactionId: Scalars['String']['output'];
  gatewayTokenId: Scalars['String']['output'];
  paymentType: PaymentProfilePaymentType;
};

export type VerifyCreditCardInformationDomainError = DomainError & {
  __typename?: 'VerifyCreditCardInformationDomainError';
  /** A human-friendly error message. */
  message: Scalars['String']['output'];
};

export type VerifyCreditCardInformationFailure = {
  __typename?: 'VerifyCreditCardInformationFailure';
  errors: Array<VerifyCreditCardInformationDomainError>;
};

export type VerifyCreditCardInformationInput = {
  CVV: Scalars['String']['input'];
  CardNumber: Scalars['String']['input'];
  ExpirationMonth: Scalars['Int']['input'];
  ExpirationYear: Scalars['Int']['input'];
};

export type VerifyCreditCardInformationResult = VerifyCreditCardInformationFailure | VerifyCreditCardInformationSuccess;

export type VerifyCreditCardInformationSuccess = {
  __typename?: 'VerifyCreditCardInformationSuccess';
  authTransactionId: Scalars['String']['output'];
  gatewayTokenId: Scalars['String']['output'];
  paymentType: PaymentProfilePaymentType;
};

export enum WellnessExamStatus {
  Arrived = 'Arrived',
  Booked = 'Booked',
  Canceled = 'Canceled',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Noshow = 'Noshow'
}

export enum WellnessExamUploadStatus {
  Approved = 'Approved',
  Deleted = 'Deleted',
  Error = 'Error',
  NotApproved = 'Not_Approved',
  Pending = 'Pending',
  Rejected = 'Rejected',
  UploadedNeedsQa = 'Uploaded_needs_QA'
}

export type CreateMessageAttachmentInput = {
  createdTimestamp?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum Join__Graph {
  Account = 'ACCOUNT',
  Awp = 'AWP',
  Case = 'CASE',
  Contact = 'CONTACT',
  Featureflag = 'FEATUREFLAG',
  Main = 'MAIN',
  Notification = 'NOTIFICATION',
  Patientreport = 'PATIENTREPORT',
  Payment = 'PAYMENT',
  Paymentgateway = 'PAYMENTGATEWAY',
  Paymentprofile = 'PAYMENTPROFILE',
  Pdm = 'PDM',
  Practicereport = 'PRACTICEREPORT',
  Referral = 'REFERRAL',
  Securemessaging = 'SECUREMESSAGING',
  User = 'USER'
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}

export type MessagingContactsForPhysicianInput = {
  AccountId: Scalars['String']['input'];
};

export type CreateNewPaymentProfileMutationVariables = Exact<{
  input: CreateNewPaymentProfileInput;
}>;


export type CreateNewPaymentProfileMutation = { __typename?: 'Mutation', createNewPaymentProfile: { __typename?: 'CreateNewPaymentProfileFailure', errors: Array<{ __typename?: 'CreateNewPaymentProfileFailedDomainError', message: string } | { __typename?: 'CreateNewPaymentProfileInvalidInputDomainError', message: string }> } | { __typename?: 'CreateNewPaymentProfileSuccess', Id: string } };

export type CreateOneTimePaymentMutationVariables = Exact<{
  input: CreateOneTimePaymentInput;
}>;


export type CreateOneTimePaymentMutation = { __typename?: 'Mutation', createOneTimePayment: { __typename?: 'CreateOneTimePaymentFailure', errors: Array<{ __typename?: 'CreateOneTimePaymentFailedDomainError', message: string }> } | { __typename?: 'CreateOneTimePaymentSuccess', Id: string, Name: string } };

export type VerifyAchInformationMutationVariables = Exact<{
  input: VerifyAchInformationInput;
}>;


export type VerifyAchInformationMutation = { __typename?: 'Mutation', verifyACHInformation: { __typename?: 'VerifyACHInformationFailure', errors: Array<{ __typename?: 'VerifyACHInformationDomainError', message: string }> } | { __typename?: 'VerifyACHInformationSuccess', gatewayTokenId: string, authTransactionId: string, paymentType: PaymentProfilePaymentType } };

export type VerifyCreditCardInformationMutationVariables = Exact<{
  input: VerifyCreditCardInformationInput;
}>;


export type VerifyCreditCardInformationMutation = { __typename?: 'Mutation', verifyCreditCardInformation: { __typename?: 'VerifyCreditCardInformationFailure', errors: Array<{ __typename?: 'VerifyCreditCardInformationDomainError', message: string }> } | { __typename?: 'VerifyCreditCardInformationSuccess', gatewayTokenId: string, authTransactionId: string, paymentType: PaymentProfilePaymentType } };

export type PatientReceiptByDocumentIdQueryQueryVariables = Exact<{
  contactId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
}>;


export type PatientReceiptByDocumentIdQueryQuery = { __typename?: 'Query', patientReceiptByDocumentId: { __typename: 'PatientReceiptByDocumentIdFailure', errors: Array<{ __typename?: 'PatientReceiptByDocumentIdFailureError', message: string }> } | { __typename: 'PatientReceiptByDocumentIdQueryResult', document?: string | null } };

export type PaymentSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentSettingsQuery = { __typename?: 'Query', paymentSettings?: { __typename?: 'PaymentSettingsType', MaximumPaymentAmount?: { __typename: 'MaximumPaymentAmountType', Label?: string | null, Description__c?: string | null, Amount__c?: number | null } | null } | null };


export const CreateNewPaymentProfileDocument = gql`
    mutation createNewPaymentProfile($input: CreateNewPaymentProfileInput!) {
  createNewPaymentProfile(input: $input) {
    ... on CreateNewPaymentProfileSuccess {
      Id
    }
    ... on CreateNewPaymentProfileFailure {
      errors {
        ... on CreateNewPaymentProfileInvalidInputDomainError {
          message
        }
        ... on CreateNewPaymentProfileFailedDomainError {
          message
        }
      }
    }
  }
}
    `;
export type CreateNewPaymentProfileMutationFn = Apollo.MutationFunction<CreateNewPaymentProfileMutation, CreateNewPaymentProfileMutationVariables>;

/**
 * __useCreateNewPaymentProfileMutation__
 *
 * To run a mutation, you first call `useCreateNewPaymentProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewPaymentProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewPaymentProfileMutation, { data, loading, error }] = useCreateNewPaymentProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewPaymentProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewPaymentProfileMutation, CreateNewPaymentProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewPaymentProfileMutation, CreateNewPaymentProfileMutationVariables>(CreateNewPaymentProfileDocument, options);
      }
export type CreateNewPaymentProfileMutationHookResult = ReturnType<typeof useCreateNewPaymentProfileMutation>;
export type CreateNewPaymentProfileMutationResult = Apollo.MutationResult<CreateNewPaymentProfileMutation>;
export type CreateNewPaymentProfileMutationOptions = Apollo.BaseMutationOptions<CreateNewPaymentProfileMutation, CreateNewPaymentProfileMutationVariables>;
export const CreateOneTimePaymentDocument = gql`
    mutation createOneTimePayment($input: CreateOneTimePaymentInput!) {
  createOneTimePayment(input: $input) {
    ... on CreateOneTimePaymentSuccess {
      Id
      Name
    }
    ... on CreateOneTimePaymentFailure {
      errors {
        ... on CreateOneTimePaymentFailedDomainError {
          message
        }
      }
    }
  }
}
    `;
export type CreateOneTimePaymentMutationFn = Apollo.MutationFunction<CreateOneTimePaymentMutation, CreateOneTimePaymentMutationVariables>;

/**
 * __useCreateOneTimePaymentMutation__
 *
 * To run a mutation, you first call `useCreateOneTimePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTimePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneTimePaymentMutation, { data, loading, error }] = useCreateOneTimePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneTimePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneTimePaymentMutation, CreateOneTimePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneTimePaymentMutation, CreateOneTimePaymentMutationVariables>(CreateOneTimePaymentDocument, options);
      }
export type CreateOneTimePaymentMutationHookResult = ReturnType<typeof useCreateOneTimePaymentMutation>;
export type CreateOneTimePaymentMutationResult = Apollo.MutationResult<CreateOneTimePaymentMutation>;
export type CreateOneTimePaymentMutationOptions = Apollo.BaseMutationOptions<CreateOneTimePaymentMutation, CreateOneTimePaymentMutationVariables>;
export const VerifyAchInformationDocument = gql`
    mutation verifyACHInformation($input: VerifyACHInformationInput!) {
  verifyACHInformation(input: $input) {
    ... on VerifyACHInformationSuccess {
      gatewayTokenId
      authTransactionId
      paymentType
    }
    ... on VerifyACHInformationFailure {
      errors {
        ... on VerifyACHInformationDomainError {
          message
        }
      }
    }
  }
}
    `;
export type VerifyAchInformationMutationFn = Apollo.MutationFunction<VerifyAchInformationMutation, VerifyAchInformationMutationVariables>;

/**
 * __useVerifyAchInformationMutation__
 *
 * To run a mutation, you first call `useVerifyAchInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAchInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAchInformationMutation, { data, loading, error }] = useVerifyAchInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyAchInformationMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAchInformationMutation, VerifyAchInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyAchInformationMutation, VerifyAchInformationMutationVariables>(VerifyAchInformationDocument, options);
      }
export type VerifyAchInformationMutationHookResult = ReturnType<typeof useVerifyAchInformationMutation>;
export type VerifyAchInformationMutationResult = Apollo.MutationResult<VerifyAchInformationMutation>;
export type VerifyAchInformationMutationOptions = Apollo.BaseMutationOptions<VerifyAchInformationMutation, VerifyAchInformationMutationVariables>;
export const VerifyCreditCardInformationDocument = gql`
    mutation verifyCreditCardInformation($input: VerifyCreditCardInformationInput!) {
  verifyCreditCardInformation(input: $input) {
    ... on VerifyCreditCardInformationSuccess {
      gatewayTokenId
      authTransactionId
      paymentType
    }
    ... on VerifyCreditCardInformationFailure {
      errors {
        ... on VerifyCreditCardInformationDomainError {
          message
        }
      }
    }
  }
}
    `;
export type VerifyCreditCardInformationMutationFn = Apollo.MutationFunction<VerifyCreditCardInformationMutation, VerifyCreditCardInformationMutationVariables>;

/**
 * __useVerifyCreditCardInformationMutation__
 *
 * To run a mutation, you first call `useVerifyCreditCardInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCreditCardInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCreditCardInformationMutation, { data, loading, error }] = useVerifyCreditCardInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyCreditCardInformationMutation(baseOptions?: Apollo.MutationHookOptions<VerifyCreditCardInformationMutation, VerifyCreditCardInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyCreditCardInformationMutation, VerifyCreditCardInformationMutationVariables>(VerifyCreditCardInformationDocument, options);
      }
export type VerifyCreditCardInformationMutationHookResult = ReturnType<typeof useVerifyCreditCardInformationMutation>;
export type VerifyCreditCardInformationMutationResult = Apollo.MutationResult<VerifyCreditCardInformationMutation>;
export type VerifyCreditCardInformationMutationOptions = Apollo.BaseMutationOptions<VerifyCreditCardInformationMutation, VerifyCreditCardInformationMutationVariables>;
export const PatientReceiptByDocumentIdQueryDocument = gql`
    query patientReceiptByDocumentIdQuery($contactId: String!, $documentId: String!) {
  patientReceiptByDocumentId(
    input: {contactId: $contactId, documentId: $documentId}
  ) {
    __typename
    ... on PatientReceiptByDocumentIdQueryResult {
      document
    }
    ... on PatientReceiptByDocumentIdFailure {
      errors {
        message
      }
    }
  }
}
    `;

/**
 * __usePatientReceiptByDocumentIdQueryQuery__
 *
 * To run a query within a React component, call `usePatientReceiptByDocumentIdQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientReceiptByDocumentIdQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientReceiptByDocumentIdQueryQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function usePatientReceiptByDocumentIdQueryQuery(baseOptions: Apollo.QueryHookOptions<PatientReceiptByDocumentIdQueryQuery, PatientReceiptByDocumentIdQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientReceiptByDocumentIdQueryQuery, PatientReceiptByDocumentIdQueryQueryVariables>(PatientReceiptByDocumentIdQueryDocument, options);
      }
export function usePatientReceiptByDocumentIdQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientReceiptByDocumentIdQueryQuery, PatientReceiptByDocumentIdQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientReceiptByDocumentIdQueryQuery, PatientReceiptByDocumentIdQueryQueryVariables>(PatientReceiptByDocumentIdQueryDocument, options);
        }
export type PatientReceiptByDocumentIdQueryQueryHookResult = ReturnType<typeof usePatientReceiptByDocumentIdQueryQuery>;
export type PatientReceiptByDocumentIdQueryLazyQueryHookResult = ReturnType<typeof usePatientReceiptByDocumentIdQueryLazyQuery>;
export type PatientReceiptByDocumentIdQueryQueryResult = Apollo.QueryResult<PatientReceiptByDocumentIdQueryQuery, PatientReceiptByDocumentIdQueryQueryVariables>;
export const PaymentSettingsDocument = gql`
    query paymentSettings {
  paymentSettings(input: {type: MaximumPaymentAmount}) {
    MaximumPaymentAmount {
      Label
      Description__c
      Amount__c
      __typename
    }
  }
}
    `;

/**
 * __usePaymentSettingsQuery__
 *
 * To run a query within a React component, call `usePaymentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentSettingsQuery(baseOptions?: Apollo.QueryHookOptions<PaymentSettingsQuery, PaymentSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentSettingsQuery, PaymentSettingsQueryVariables>(PaymentSettingsDocument, options);
      }
export function usePaymentSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentSettingsQuery, PaymentSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentSettingsQuery, PaymentSettingsQueryVariables>(PaymentSettingsDocument, options);
        }
export type PaymentSettingsQueryHookResult = ReturnType<typeof usePaymentSettingsQuery>;
export type PaymentSettingsLazyQueryHookResult = ReturnType<typeof usePaymentSettingsLazyQuery>;
export type PaymentSettingsQueryResult = Apollo.QueryResult<PaymentSettingsQuery, PaymentSettingsQueryVariables>;