import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { BlobProvider, Document, Page, Text } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { pdfjs } from 'react-pdf'
import { useLocation, useNavigate } from 'react-router-dom'

import { usePatientReceiptByDocumentIdQueryQuery } from '../../generated/hooks'
import { getInvoiceId, getMemberId } from '../../utils/invoiceUtils'
import LoadingSpinner from './Spinners/LoadingSpinner'

// Custom styles for the modal window.
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
}

// Data interface for the invoice object.
interface InvoiceDataInterface {
  id: string
  document: string
}

// Props for the InvoiceModal component. Default value for `isOpen` is `false`.
interface InvoiceModalProps {
  isOpen?: boolean
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({ isOpen = false }) => {
  // Setup for app navigation based on user actions.
  const navigate = useNavigate()
  const location = useLocation()

  // Initialization for invoice and modal state.
  let subtitle: HTMLHeadingElement | null
  const [modalIsOpen, setIsOpen] = React.useState(isOpen)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [invoice, setInvoice] = useState<InvoiceDataInterface | null>(null)

  // Functionality for when the modal is to be opened.
  function openModal() {
    setIsOpen(true)
  }

  // Functionality for after the modal is opened.
  function afterOpenModal() {
    if (subtitle) {
      subtitle.style.color = '#f00'
    }
  }

  // Functionality for when the modal is to be closed.
  function closeModal() {
    setIsOpen(false)
  }

  // Navigates to the payment route.
  function goToPay() {
    closeModal()
    navigate('/payment', { state: location.state })
  }

  // Query for getting the invoice/document data, requires contactId and documentId.
  const { data, loading } = usePatientReceiptByDocumentIdQueryQuery({
    variables: {
      contactId: getMemberId() || '',
      documentId: getInvoiceId() || '',
    },
  })

  // Sets the invoice state once we have data. Using useEffect to do it once.
  useEffect(() => {
    if (
      data &&
      data.patientReceiptByDocumentId.__typename === 'PatientReceiptByDocumentIdQueryResult' &&
      data.patientReceiptByDocumentId.document !== null
    ) {
      setInvoice({
        id: getInvoiceId() || '',
        document: data.patientReceiptByDocumentId.document || '',
      })
    }
  }, [data])

  React.useEffect(() => {
    if (
      data?.patientReceiptByDocumentId &&
      'errors' in data.patientReceiptByDocumentId &&
      Array.isArray(data.patientReceiptByDocumentId.errors) &&
      data.patientReceiptByDocumentId.errors.length > 0 &&
      data.patientReceiptByDocumentId.errors[0]?.message
    ) {
      window.sessionStorage.setItem('mdvip_ep_error_message', data.patientReceiptByDocumentId.errors[0].message)
      setErrorMessage(data.patientReceiptByDocumentId.errors[0].message)
    } else {
      window.sessionStorage.removeItem('mdvip_ep_error_message')
    }
  }, [data])

  // Show loading or error message if applicable.
  if (loading) {
    return <LoadingSpinner loading={loading} />
  }

  // Build the pdf document from the base64 string.
  const pdfDocument = (src: string) => {
    return 'data:application/pdf;base64,' + src
  }

  // Needed in order for the react-pdf library to work.
  pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()

  const PDFDocumentLayout = (
    <Document
      pageLayout="oneColumn"
      pageMode="useNone"
      subject="Member Statement Renewal Weekly Report"
      title="Member Statement Renewal Weekly Report"
    >
      <Page size="A1">
        <Text>Invoice Content Here</Text>
      </Page>
    </Document>
  )

  // The actual modal component.
  return (
    <div className="invoice-modal">
      <a onClick={openModal} className="view-invoice">
        <PictureAsPdfIcon />
        View Invoice
      </a>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Invoice Modal"
        ariaHideApp={false}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal__header d-flex justify-content-between align-items-center">
              <button
                className="modal__button btn-close top-right"
                onClick={closeModal}
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {errorMessage ? (
              <div className="error text-center">{errorMessage}</div>
            ) : invoice ? (
              <BlobProvider document={PDFDocumentLayout}>
                {({ loading }) => {
                  if (loading) {
                    return <LoadingSpinner loading={true} />
                  }
                  // Use the blob or URL for other purposes, e.g., displaying in an iframe
                  return (
                    <object data={pdfDocument(invoice?.document)} type="application/pdf" width="100%" height="100%">
                      Error 400: Bad request, this browser does not support PDF's, please download the file to view.
                      <a
                        href={pdfDocument(invoice?.document)}
                        download="invoice.pdf"
                        rel="noreferrer"
                        className="pdf-download"
                      >
                        Download PDF
                      </a>
                    </object>
                  )
                }}
              </BlobProvider>
            ) : (
              <LoadingSpinner loading={true} />
            )}
            <div className="modal-footer">
              {invoice && (
                <>
                  <a
                    href={pdfDocument(invoice?.document)}
                    download="invoice.pdf"
                    rel="noreferrer"
                    className="pdf-download"
                  >
                    Download PDF
                  </a>
                  <button onClick={goToPay} className="btn btn-primary">
                    Pay Now
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InvoiceModal
